import { useNuxtApp } from "#app";
import { defineStore } from "pinia";
import { iocContainer } from "~/inversify.config";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import { LayoutPresetDto } from "~/api/layout-presets/dto/LayoutPresetDto";
import { LayoutPresetsDataGatewayInterface } from "~/api/layout-presets/gateways/LayoutPresetsDataGatewayInterface";
import { toaster } from "~/service/toaster";
import { UserID, useUserStore } from "~/entities/user";
import { plainToInstance } from "class-transformer";
import { useFieldsStore } from "../entities/field/model/store";
import { logger } from "~/service/logger/logger";

const STORE_ID = "presetsStore";

interface State {
  presets: LayoutPresetDto[];
}

export const usePresetsStore = defineStore(STORE_ID, {
  state(): State {
    return {
      presets: [],
    };
  },

  getters: {
    getAllByCollection(state) {
      return (collectionId: string | number): LayoutPresetDto[] =>
        state.presets.filter((preset) => preset.collection === collectionId);
    },

    getCollectionPrimaryPresetForUser() {
      return (collectionId: string, userId: string, roleId: string): LayoutPresetDto => {
        const collectionPresets = this.getAllByCollection(collectionId);

        const rolePreset = collectionPresets.find((preset) => preset.roleId === roleId);
        if (roleId && rolePreset) return rolePreset;

        const globalPreset = collectionPresets.find(
          (preset) => !preset.roleId && !preset.userId,
        );
        if (globalPreset) return globalPreset;

        const userPreset = collectionPresets.find((preset) => preset.userId === userId);
        if (userPreset) return userPreset;

        return getDefaultTabularPreset(collectionId, userId, roleId);
      };
    },
  },

  actions: {
    async initialize() {
      const { $i18n } = useNuxtApp();
      const { t } = $i18n;

      const presetsDataGateway = iocContainer.get<LayoutPresetsDataGatewayInterface>(
        INJECT_SYMBOLS.LayoutPresetsDataGatewayInterface,
      );
      const userStore = useUserStore();

      try {
        const userId = userStore.me?.id as UserID | undefined;
        const roleId = userStore.me?.role.id as string | undefined;

        if (!userId || !roleId)
          throw new Error(
            "missed userID or roleID. Please, call user store 'initialize' before presets store 'initialize'",
          );

        const presets = await presetsDataGateway.getAllForUser(userId, roleId);
        this.$patch({
          presets,
        });
      } catch (err) {
        logger().error(
          {
            err,
          },
          `unable to initialize presets`,
        );
        toaster().error(`${t("error_fetch_system_data")} 'layout_presets'`);
      }
    },

    async deinitialize() {
      this.$reset();
    },
  },
});

export function getDefaultTabularPreset(
  collectionId: string | null,
  userId?: string,
  roleId?: string,
): LayoutPresetDto {
  const fieldsStore = useFieldsStore();

  const collectionPrimaryField = !!collectionId
    ? fieldsStore.getPrimaryField(collectionId)
    : undefined;

  const queryFields = !!collectionPrimaryField ? [collectionPrimaryField.name] : [];

  return plainToInstance(LayoutPresetDto, {
    id: "default",
    collection: collectionId,
    roleId: roleId || null,
    userId: userId || null,
    type: "tabular",
    query: {
      tabular: {
        fields: queryFields,
        page: 1,
        limit: 25,
      },
    },
  });
}

