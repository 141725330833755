import { useNuxtApp, useRuntimeConfig } from "#app";
import Dropzone, { DropzoneFile } from "dropzone";
import Item from "~/api/items/entities/Item";
import ItemInterface, { ItemMetadataInterface } from "~/api/items/entities/ItemInterface";
import { FILES_COLLECTION_NAME } from "../constants";
import {
  FieldInfoInterface,
  FieldInterface,
  Field,
  useFieldsStore,
} from "~/entities/field";

import { ItemFileMetadataKeys } from "../enum";
import axios from "axios";

const isRemoteFile = (source: string): boolean => {
  return source.startsWith("http");
};

const convertBase64DataToBlob = (base64url: string): Blob | undefined => {
  const b64Data = base64url.split(",")[1];
  const contentType = base64url.split(";")[0].split(":")[1];
  const byteCharacters = atob(b64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: contentType });
};

const convertRemoteFileToBlob = async (url: string): Promise<Blob | undefined> => {
  try {
    const response = await axios.get(url, { responseType: "blob" });
    return response.data;
  } catch (err) {
    return undefined;
  }
};

export const castItemFileToDropzoneFile = async (
  item: ItemInterface,
  token: string,
): Promise<DropzoneFile | undefined> => {
  const runtimeConfig = useRuntimeConfig();

  const uuid = item.getFieldDataByExpression("id") as string;
  const fileName = item.getFieldDataByExpression("title") as string;
  const contentType = item.getFieldDataByExpression("type");

  const dataURL =
    item.metadata[ItemFileMetadataKeys.DATA_URL] ||
    `${runtimeConfig.public.dataStudioApiUrl}/assets/${uuid}?access_token=${token}`;

  const fileBlob = isRemoteFile(dataURL)
    ? await convertRemoteFileToBlob(dataURL)
    : convertBase64DataToBlob(dataURL);

  if (fileBlob === undefined) return undefined;

  const dropzoneFile = new File([fileBlob], fileName, {
    type: contentType,
  }) as DropzoneFile;

  dropzoneFile.upload = {};

  if ("upload" in item.metadata) {
    dropzoneFile.upload = {
      ...item.metadata.upload,
    };
  }

  dropzoneFile.upload.uuid = uuid;
  dropzoneFile.status = item.metadata.status || Dropzone.SUCCESS;

  return dropzoneFile;
};

export const castDropzoneQueuedFileToItemFile = (
  dropzoneFile: DropzoneFile,
): ItemInterface => {
  const { $i18n } = useNuxtApp();
  const fieldsStore = useFieldsStore();

  const fields: FieldInterface[] = [];

  const fieldId = new Field(
    fieldsStore.getField(FILES_COLLECTION_NAME, "id") as FieldInfoInterface,
    dropzoneFile.upload?.uuid,
    $i18n.locale.value,
  );
  fields.push(fieldId);

  const fieldStorage = new Field(
    fieldsStore.getField(FILES_COLLECTION_NAME, "storage") as FieldInfoInterface,
    null,
    $i18n.locale.value,
  );
  fields.push(fieldStorage);

  const fieldFilenameDisk = new Field(
    fieldsStore.getField(FILES_COLLECTION_NAME, "filename_disk") as FieldInfoInterface,
    null,
    $i18n.locale.value,
  );
  fields.push(fieldFilenameDisk);

  const fieldFilenameDownload = new Field(
    fieldsStore.getField(
      FILES_COLLECTION_NAME,
      "filename_download",
    ) as FieldInfoInterface,
    null,
    $i18n.locale.value,
  );
  fields.push(fieldFilenameDownload);

  const fieldTitle = new Field(
    fieldsStore.getField(FILES_COLLECTION_NAME, "title") as FieldInfoInterface,
    dropzoneFile.name,
    $i18n.locale.value,
  );
  fields.push(fieldTitle);

  const fieldType = new Field(
    fieldsStore.getField(FILES_COLLECTION_NAME, "type") as FieldInfoInterface,
    dropzoneFile.type,
    $i18n.locale.value,
  );
  fields.push(fieldType);

  /**
   * @todo receive folder from collection info
   */
  const fieldFolder = new Field(
    fieldsStore.getField(FILES_COLLECTION_NAME, "folder") as FieldInfoInterface,
    null,
    $i18n.locale.value,
  );
  fields.push(fieldFolder);

  const fieldUploadedBy = new Field(
    fieldsStore.getField(FILES_COLLECTION_NAME, "uploaded_by") as FieldInfoInterface,
    null,
    $i18n.locale.value,
  );
  fields.push(fieldUploadedBy);

  const fieldUploadedOn = new Field(
    fieldsStore.getField(FILES_COLLECTION_NAME, "uploaded_on") as FieldInfoInterface,
    null,
    $i18n.locale.value,
  );
  fields.push(fieldUploadedOn);

  const fieldModifiedBy = new Field(
    fieldsStore.getField(FILES_COLLECTION_NAME, "modified_by") as FieldInfoInterface,
    null,
    $i18n.locale.value,
  );
  fields.push(fieldModifiedBy);

  const fieldModifiedOn = new Field(
    fieldsStore.getField(FILES_COLLECTION_NAME, "modified_on") as FieldInfoInterface,
    null,
    $i18n.locale.value,
  );
  fields.push(fieldModifiedOn);

  const fieldFilesize = new Field(
    fieldsStore.getField(FILES_COLLECTION_NAME, "filesize") as FieldInfoInterface,
    dropzoneFile.size,
    $i18n.locale.value,
  );
  fields.push(fieldFilesize);

  const metadata: ItemMetadataInterface = {
    [ItemFileMetadataKeys.DATA_URL]: dropzoneFile.dataURL,
    [ItemFileMetadataKeys.IS_UPLOADED]: false,
    status: dropzoneFile.status || Dropzone.SUCCESS,
    upload: {
      ...dropzoneFile.upload,
    },
  };

  const item = new Item(fields, metadata);

  return item;
};

