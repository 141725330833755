import type { RouterConfig } from "@nuxt/schema";
import { PermissionAction } from "~/api/permissions/entity/PermissionInterface";
import { ProfileRouteActionsName } from "./router.constants";

export enum RoutePathNames {
  HOME = "home",
  LOGIN = "login",
  COLLECTION_SUFFIX = "Collection",
  ITEM_PAGE = "itemPage",
  ITEM_CREATE = "itemCreate",
  COLLECTION_PAGE = "collectionPage",
  PROFILE = "profile",
  RESET_USER_PASSWORD = "reset-password",
}

export enum RouteParameterNames {
  COLLECTION_NAME = "collectionName",
  COLLECTION_ITEM_ID = "itemId",
}

export enum RouteMetaFields {
  PERMISSION_ACTION = "permissionAction",
  PROFILE_ACTION = "profileAction",
  IS_AUTH_REQUIRED = "isAuthRequired",
}

// https://router.vuejs.org/api/interfaces/routeroptions.html
export default <RouterConfig>{
  routes: (_routes) => [
    {
      name: RoutePathNames.HOME,
      path: "/",
      component: () => import("~/pages/home/TheHomePage.vue"),
    },
    {
      name: RoutePathNames.LOGIN,
      path: "/login",
      component: () => import("~/pages/login").then((r) => r.TheLoginPage),
    },
    {
      name: RoutePathNames.COLLECTION_PAGE,
      path: `/c/:${RouteParameterNames.COLLECTION_NAME}(\\w+)`,
      component: () => import("~/pages/collection/TheCollectionBasePage.vue"),
      meta: {
        [RouteMetaFields.PERMISSION_ACTION]: PermissionAction.READ,
      },
    },
    {
      name: RoutePathNames.ITEM_CREATE,
      path: `/c/:${RouteParameterNames.COLLECTION_NAME}(\\w+)/create`,
      component: () => import("~/pages/items/TheItemCreatePage.vue"),
      meta: {
        [RouteMetaFields.PERMISSION_ACTION]: PermissionAction.CREATE,
      },
    },
    {
      name: `${RoutePathNames.ITEM_PAGE}`,
      path: `/c/:${RouteParameterNames.COLLECTION_NAME}(\\w+)/:${RouteParameterNames.COLLECTION_ITEM_ID}(.+)`,
      component: () => import("~/pages/items/TheItemBasePage.vue"),
      meta: {
        [RouteMetaFields.PERMISSION_ACTION]: PermissionAction.READ,
      },
    },
    // start:users
    {
      path: `/c/directus_users/:${RouteParameterNames.COLLECTION_ITEM_ID}(.+)`,
      redirect: {
        name: RoutePathNames.PROFILE,
      },
    },
    {
      path: `/c/directus_users`,
      redirect: {
        path: "/users",
      },
    },
    {
      path: `/c/directus_users/create`,
      redirect: {
        path: "/users/create",
      },
    },
    {
      path: `/users/:${RouteParameterNames.COLLECTION_ITEM_ID}(.+)`,
      name: RoutePathNames.PROFILE,
      component: () =>
        import("~/pages/profile").then((result) => {
          return result.ProfilePage;
        }),
      children: [
        {
          path: `personal`,
          name: ProfileRouteActionsName.CHANGE_USER_PERSONAL_DATA,
          component: () =>
            import("~/features/change-user-personal-data").then(
              (r) => r.ChangeUserPersonalData,
            ),
          meta: {
            [RouteMetaFields.PROFILE_ACTION]:
              ProfileRouteActionsName.CHANGE_USER_PERSONAL_DATA,
          },
        },
        {
          path: `change-password`,
          name: ProfileRouteActionsName.CHANGE_USER_PASSWORD,
          component: () =>
            import("~/features/request-reset-user-password").then(
              (r) => r.RequestResetUserPassword,
            ),
          meta: {
            [RouteMetaFields.PROFILE_ACTION]:
              ProfileRouteActionsName.CHANGE_USER_PASSWORD,
          },
        },
        {
          path: `permissions`,
          name: ProfileRouteActionsName.CHANGE_PERMISSIONS,
          component: () =>
            import("~/features/change-user-permissions").then(
              (r) => r.ChangeUserPermissions,
            ),
          meta: {
            [RouteMetaFields.PROFILE_ACTION]: ProfileRouteActionsName.CHANGE_PERMISSIONS,
          },
        },
        {
          path: `sessions`,
          name: ProfileRouteActionsName.SHOW_ACTIVE_SESSIONS,
          component: () =>
            import("~/features/show-active-user-sessions").then(
              (r) => r.ShowActiveUserSessions,
            ),
          meta: {
            [RouteMetaFields.PROFILE_ACTION]:
              ProfileRouteActionsName.SHOW_ACTIVE_SESSIONS,
          },
        },
      ],
    },
    {
      path: "/users",
      component: () => import("~/pages/collection/TheCollectionBasePage.vue"),
      meta: {
        [RouteMetaFields.PERMISSION_ACTION]: PermissionAction.READ,
        [RouteParameterNames.COLLECTION_NAME]: "directus_users",
      },
    },
    {
      path: "/users/create",
      component: () => import("~/pages/items/TheItemCreatePage.vue"),
      meta: {
        [RouteMetaFields.PERMISSION_ACTION]: PermissionAction.CREATE,
        [RouteParameterNames.COLLECTION_NAME]: "directus_users",
      },
    },
    // end:users
    {
      name: "reset-password",
      path: "/reset-password",
      component: () =>
        import("~/pages/reset-user-password-page").then((r) => r.ResetUserPasswordPage),
      meta: {
        [RouteMetaFields.IS_AUTH_REQUIRED]: false,
      },
    },
  ],
};
