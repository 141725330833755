import { inject, injectable } from "inversify";
import { logger } from "~/service/logger/logger";
import { AppEventBus, defineAppEvent, AppEvents } from "~/shared/lib/app-event-bus";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import ItemInterface from "../entities/ItemInterface";
import ItemCommitError from "../exceptions/ItemCommitError";
import ItemsWriteGatewayInterface from "../gateway/ItemsWriteGatewayInterface";
import ItemCommitWorkInterface from "./ItemCommitWorkInterface";

@injectable()
export default class ItemCommitWork implements ItemCommitWorkInterface {
  private _dirtyItems: { [key: string]: ItemInterface[] } = {};

  private _newItems: { [key: string]: ItemInterface[] } = {};

  constructor(
    @inject(INJECT_SYMBOLS.ItemsWriteGatewayInterface)
    private itemsWriteGateway: ItemsWriteGatewayInterface,
    @inject(INJECT_SYMBOLS.AppEventBus)
    private readonly _appEventBus: AppEventBus,
  ) {}

  get dirtyItems() {
    return this._dirtyItems;
  }

  get newItems() {
    return this._newItems;
  }

  registerDirty(collectionName: string, item: ItemInterface): boolean {
    if (!(collectionName in this._dirtyItems)) {
      this._dirtyItems[collectionName] = [];
    }

    const dirtyItems = this._dirtyItems[collectionName];
    const alreadyExists =
      dirtyItems.findIndex((registeredItem) => registeredItem.id === item.id) !== -1;

    if (alreadyExists) {
      return false;
    }

    dirtyItems.push(item);

    logger().debug(
      {
        collectionName,
        itemID: item.id,
      },
      `registered item dirty`,
    );

    return true;
  }

  registerNew(collectionName: string, item: ItemInterface): boolean {
    if (!(collectionName in this._newItems)) {
      this._newItems[collectionName] = [];
    }

    const isExists =
      this._newItems[collectionName].findIndex(
        (existsItem) => existsItem.id === item.id,
      ) !== -1;
    if (isExists) {
      return false;
    }

    this._newItems[collectionName].push(item);

    logger().debug(
      {
        collectionName,
        itemID: item.id,
      },
      `registered item new`,
    );

    return true;
  }

  isRegisteredAsDirty(collectionName: string, itemId: string | number): boolean {
    if (collectionName in this._dirtyItems) {
      return (
        this._dirtyItems[collectionName].findIndex(
          (registeredItem) => registeredItem.id === itemId,
        ) !== -1
      );
    }

    return false;
  }

  isRegisteredAsNew(collectionName: string, itemId: string | number): boolean {
    if (collectionName in this._newItems) {
      return (
        this._newItems[collectionName].findIndex(
          (registeredItem) => registeredItem.id === itemId,
        ) !== -1
      );
    }

    return false;
  }

  /**
   *
   * @returns {Promise<void>}
   * @throws {ItemCommitError}
   */
  async commit(): Promise<{ createResults: unknown; updateResults: unknown }> {
    const result = await Promise.allSettled([this._insertNew(), this._updateDirty()]);

    const errors = result.filter((promise) => promise.status === "rejected");

    if (!!errors.length) {
      throw new ItemCommitError({
        message: "error_commit_items",
        data: {
          errors,
        },
      });
    }

    return {
      createResults: result[0].value,
      updateResults: result[1].value,
    };
  }

  /**
   *
   * @returns
   * @throws {ItemCommitError}
   */
  private async _updateDirty(): Promise<{ [key: string | number]: unknown }> {
    if (!Object.keys(this._dirtyItems).length) {
      return {};
    }

    const results: { [key: string | number]: unknown } = {};
    const errors: Error[] = [];

    logger().info(`starting update dirty items`);

    logger().warn(
      `update request will perform for each item. Not implemented updateMany method.`,
    );

    for (const collectionKey in this._dirtyItems) {
      for await (const dirtyItem of this._dirtyItems[collectionKey]) {
        try {
          const updateResult = await this.itemsWriteGateway.updateOne(
            collectionKey,
            dirtyItem,
          );

          results[dirtyItem.id] = updateResult;

          dirtyItem.setClean();
          this._cleanDirty(collectionKey, dirtyItem.id);

          this._appEventBus.dispatch(
            defineAppEvent({
              event: AppEvents.ITEM_UPDATED,
              payload: {
                collectionName: collectionKey,
                itemId: dirtyItem.id,
                updatedData: updateResult,
              },
            }),
          );
        } catch (err) {
          errors.push(err as Error);
        }
      }
    }
    logger().debug(`finished update dirty items`);

    if (errors.length) {
      throw new ItemCommitError({
        message: "error_item_update",
        data: errors,
      });
    }

    logger().info("items was updated");
    return results;
  }

  private async _insertNew(): Promise<{ [key: string | number]: unknown }> {
    if (!Object.keys(this._newItems).length) {
      return {};
    }

    const results: { [key: string | number]: unknown } = {};
    const errors: Error[] = [];

    logger().info(`starting insert new items`);

    logger().warn(
      `insert request will perform for each item. Not implemented insertMany method.`,
    );

    for (const collectionName in this._newItems) {
      for await (const newItem of this._newItems[collectionName]) {
        const generatedId = newItem.id;

        try {
          const insertResult = await this.itemsWriteGateway.insertOne(
            collectionName,
            newItem,
          );

          results[generatedId] = insertResult;

          newItem.setClean();
          this._removeNew(collectionName, generatedId);
        } catch (error) {
          errors.push(error as Error);
        }
      }
    }

    if (!!errors.length) {
      throw new ItemCommitError({
        message: "error_insert_new_items",
        data: { errors, results },
      });
    }

    logger().info("items was inserted");
    return results;
  }

  private _cleanDirty(collectionName: string, itemId: string | number): void {
    if (!(collectionName in this._dirtyItems)) {
      return;
    }

    const dirtyItemIndex = this._dirtyItems[collectionName].findIndex(
      (dirtyItem) => dirtyItem.id === itemId,
    );

    if (dirtyItemIndex === -1) return;

    this._dirtyItems[collectionName].splice(dirtyItemIndex, 1);
    logger().debug({ itemId }, "item cleaned from commit work");
  }

  _removeNew(collectionName: string, itemId: string | number): void {
    if (!(collectionName in this._newItems)) {
      return;
    }

    const itemIndex = this._newItems[collectionName].findIndex(
      (item) => item.id === itemId,
    );

    if (itemIndex === -1) {
      return;
    }

    this._newItems[collectionName].splice(itemIndex, 1);
    logger().debug({ itemId }, `item removed from new in items commit work`);
  }
}

