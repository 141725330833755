import { defineStore } from "pinia";
import ItemsCommitApplicationController from "~/api/items/app-controllers/ItemsCommitApplicationController";
import { deinitialize } from "~/service/app/initialization";
import { logger } from "~/service/logger/logger";

const APP_CONTOLLERS_STORE_ID = "appControllersStore";

interface State {
  controllers: { [key: string]: ItemsCommitApplicationController };
}

export const useAppControllersStore = defineStore(APP_CONTOLLERS_STORE_ID, {
  state(): State {
    return {
      controllers: {},
    };
  },

  getters: {
    getController: (state) => {
      return (name: string) => {
        if (name in state.controllers) {
          return state.controllers[name];
        }

        logger().warn({ name }, `application controller not registered`);
        return undefined;
      };
    },
  },

  actions: {
    registerController(name: string, controller: ItemsCommitApplicationController): void {
      if (name in this.controllers) {
        return;
      }

      this.$patch({
        controllers: {
          ...this.controllers,
          [name]: controller,
        },
      });
    },

    async initialize(): Promise<void> {
      this.registerController(
        ItemsCommitApplicationController.name,
        new ItemsCommitApplicationController(),
      );
      this.controllers[ItemsCommitApplicationController.name].load();
    },

    async deinitialize(): Promise<void> {
      this.$reset();
    },
  },
});

