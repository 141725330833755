import { useRuntimeConfig } from "#app";
import { computed } from "vue";
import { useAuthStore } from "~/stores/auth";

export const getDatastudioAssetUrl = (assetUUID: string): string => {
  const runtimeConfig = useRuntimeConfig();
  const authStore = useAuthStore();

  const accessToken = computed(() => authStore.accessToken);

  return `${runtimeConfig.public.dataStudioApiUrl}/assets/${assetUUID}?access_token=${accessToken.value}`;
};

