<template>
  <div>
    <slot name="customPreviewsContainer"></slot>

    <div
      ref="refDropzone"
      :class="classDropzoneContainer"
      class="dropzone"
      :style="styleDropzoneContainer"
    >
      <div class="dz-default dz-message dz-message-btn">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import {
    defineComponent,
    onMounted,
    PropType,
    reactive,
    ref,
    watch,
    watchEffect,
  } from "vue";
  import Dropzone, { DropzoneFile } from "dropzone";
  import { dropzoneDefaultPreviewHtml } from "./partials/dropzoneDefaultPreviewHtml";
  import { EmitDropzoneRemoved } from "./types/EmitDropzoneRemoved";
  import { EmitDropzoneSending } from "./types/EmitDropzoneSending";
  import { EmitDropzoneSuccess } from "./types/EmitDropzoneSuccess";
  import { EmitDropzoneAddedFile } from "./types/EmitDropzoneAddedFile";
  import { DropzoneExistingMockedFile } from "./types/DropzoneExistingFile";
  import { EmitDropzoneMaxFilesExceeded } from "./types/EmitDropzoneMaxFilesExceeded";
  import { EmitDropzoneError } from "./types/DropzoneEmits";

  export type QueueController = {
    start: Function | null;
    queuedFiles: DropzoneFile[];
  };

  export type FileController = {
    open: Function | null;
    hideSingleProgress: (file: DropzoneFile) => void;
    showSingleProgress: (file: DropzoneFile) => void;
    _hiddenInput: HTMLInputElement | null;
  };

  export default defineComponent({
    props: {
      acceptedFiles: {
        type: Array as PropType<string[]>,
        required: true,
        default: [".png", ".jpg", ".jpeg"],
        validator: (value: string[]) => {
          const isValid = value.every((fileExt) => fileExt[0] === ".");
          if (!isValid)
            console.warn(
              `One or more file extensions passed incorrect. Every extension should starts with "." symbol.`,
            );
          return isValid;
        },
      },
      urlUpload: {
        type: String,
        required: true,
        default: "",
      },
      existingFile: {
        type: [Object, Array] as PropType<DropzoneFile | DropzoneFile[] | null>,
        required: false,
        default: [],
      },
      uploadParamName: {
        type: String,
        required: false,
        default: "file",
      },
      renameFileCb: {
        type: Function as PropType<(file: File) => string>,
        required: false,
        default: (file: File) => file.name,
      },
      maxFiles: {
        type: Number,
        required: false,
        default: 1,
      },
      isDisabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      previewsContainer: {
        type: Object as PropType<HTMLElement | null>,
        required: false,
        default: null,
      },
      previewTemplate: {
        type: String,
        required: false,
        default: null,
      },
      customOptions: {
        type: Object,
        required: false,
        default: {},
      },
      isHideProgressBeforeUpload: {
        type: Boolean,
        required: false,
        default: false,
      },
      thumbnailClickCb: {
        type: Function as PropType<
          (evt: MouseEvent, previewMockFile: DropzoneExistingMockedFile) => void
        >,
        required: false,
        default: (
          evt: MouseEvent,
          previewMockFile: DropzoneExistingMockedFile,
        ): void => {},
      },
      classDropzoneContainer: {
        type: [Object, Array],
        required: false,
        default: [],
      },
      styleDropzoneContainer: {
        type: Object,
        required: false,
        default: {},
      },
      responseParser: {
        type: Function as PropType<(response: unknown) => Object>,
        required: false,
        default: (response: unknown): Object => {
          if (typeof response === "string") {
            return JSON.parse(response);
          }

          return response;
        },
      },
    },
    emits: {
      "dropzone:removed": (payload: EmitDropzoneRemoved) => {
        return true;
      },
      "dropzone:sending": (payload: EmitDropzoneSending) => {
        return true;
      },
      "dropzone:success": (payload: EmitDropzoneSuccess) => {
        return true;
      },
      "dropzone:addedFile": (payload: EmitDropzoneAddedFile) => {
        return true;
      },
      "dropzone:maxfilesexceeded": (payload: EmitDropzoneMaxFilesExceeded) => {
        return true;
      },
      "dropzone:error": (payload: EmitDropzoneError) => {
        return true;
      },
      "dropzone:queueComplete": () => {
        return true;
      },
      "dropzone:complete": (payload: { file: DropzoneFile }) => {
        return true;
      },
    },
    setup(props, { emit }) {
      const refDropzone = ref<HTMLElement | null>(null);

      const dropzoneInstance = ref<Dropzone | null>(null);

      /**
       * Controller functions sets after dropzone instance creates
       */
      const queueController: QueueController = {
        start: null,

        queuedFiles: [],
      };

      const fileController: FileController = reactive({
        open: () => void 0,

        hideSingleProgress: (file: DropzoneFile) => {
          const progressElement =
            file.previewElement.querySelector<HTMLElement>(`.dz-progress`);
          if (!progressElement) return;

          progressElement.style.visibility = "hidden";
        },

        showSingleProgress: (file: DropzoneFile) => {
          const progressElement =
            file.previewElement.querySelector<HTMLElement>(`.dz-progress`);
          if (!progressElement) return;

          progressElement.style.visibility = "visible";
        },
        _hiddenInput: null,
      });

      const dropzoneInitialConfig = {
        url: props.urlUpload,
        paramName: props.uploadParamName,
        addRemoveLinks: true,
        uploadMultiple: false,
        clickable: !props.isDisabled,
        autoProcessQueue: false,
        maxFiles: props.maxFiles,
        acceptedFiles: props.acceptedFiles.join(","),
        renameFile: props.renameFileCb,
        parallelUploads: 1,
      };

      const makeFilesReadonly = () => {
        dropzoneInstance.value?.files.forEach((file) => {
          file.previewElement.querySelector(`[data-dz-remove]`)?.remove();
        });
      };

      watchEffect(() => {
        if (!!props.isDisabled) {
          makeFilesReadonly();
        }
      });

      /**
       * Render existing file to previews
       */
      const renderExistingFile = (dropzone: Dropzone, existingFile: DropzoneFile) => {
        console.debug("[DROPZONE] rendered existing file");

        existingFile.isMocked = true;

        dropzone.files.push(existingFile);
        dropzone.emit("addedfile", existingFile);

        dropzone.createThumbnail(
          existingFile,
          dropzone.options.thumbnailWidth,
          dropzone.options.thumbnailHeight,
          dropzone.options.thumbnailMethod,
          true,
          (thumbnail: unknown) => {
            dropzone?.emit("thumbnail", existingFile, thumbnail);
          },
        );
      };

      const isFileAlreadyExisting = (file: DropzoneFile): boolean => {
        return (
          dropzoneInstance.value?.files.findIndex(
            (existingFile) => existingFile.upload?.uuid === file.upload?.uuid,
          ) !== -1
        );
      };

      const setRemoveLockFilesMutation = (files: DropzoneFile[]) => {
        for (const dropzoneFile of files) {
          dropzoneFile.hasRemoveLock = true;
        }
      };

      const unsetRemoveLockFilesMutation = (files: DropzoneFile[]) => {
        for (const dropzoneFile of files) {
          dropzoneFile.hasRemoveLock = false;
        }
      };

      let resetSyncController: any = null;

      const resetFiles = () => {
        const promise = new Promise<boolean>((resolve, reject) => {
          if (!dropzoneInstance.value?.files.length) {
            resolve(true);
            return;
          }

          setRemoveLockFilesMutation(dropzoneInstance.value.files);

          resetSyncController = { resolve, reject };

          dropzoneInstance.value?.removeAllFiles();
        }).finally(() => {
          if (dropzoneInstance.value === null) return;

          unsetRemoveLockFilesMutation(dropzoneInstance.value.files);
        });

        return promise;
      };

      watch(
        () => props.existingFile,
        async (newExistingFile) => {
          if (dropzoneInstance.value === null) return;

          await resetFiles();

          if (newExistingFile instanceof Array) {
            const filesForRendering = newExistingFile.filter((file) => !!file);

            for (const file of filesForRendering) {
              if (isFileAlreadyExisting(file)) continue;

              renderExistingFile(dropzoneInstance.value, file);
            }

            return;
          }

          if (newExistingFile === null || newExistingFile === undefined) return;

          if (isFileAlreadyExisting(newExistingFile)) {
            return;
          }

          renderExistingFile(dropzoneInstance.value, newExistingFile as DropzoneFile);
        },
        {
          immediate: true,
          deep: false,
        },
      );

      const onDropzoneError = function (file: DropzoneFile, message: string | Object) {
        emit("dropzone:error", { file, message });
      };

      const onDropzoneAddedFile = function (this: Dropzone, file: DropzoneFile) {
        if (this.files.length > props.maxFiles) {
          this.emit("error", file, { error: "DROPZONE_ERROR_MAX_FILES_REACHED" });
          return;
        }

        if (props.isHideProgressBeforeUpload) {
          fileController.hideSingleProgress(file);
        }

        /**
         * Incorrect queue filling fix
         */
        setTimeout(() => {
          queueController.queuedFiles = this.getQueuedFiles();

          emit("dropzone:addedFile", {
            addedFile: file,
            queuedFiles: this.getQueuedFiles(),
            rejectedFiles: this.getRejectedFiles(),
            acceptedFiles: this.getAcceptedFiles(),
            uploadingFiles: this.getUploadingFiles(),
          });
        }, 500);
      };

      const onDropzoneComplete = function (file: DropzoneFile) {
        emit("dropzone:complete", { file });
      };

      const onDropzoneProcessing = function (file: DropzoneFile) {
        if (props.isHideProgressBeforeUpload) fileController.showSingleProgress(file);
      };

      const onDropzoneRemovedFile = function (this: Dropzone, file: DropzoneFile) {
        if (file.hasRemoveLock) {
          return;
        }

        emit("dropzone:removed", { file });
      };

      const onDropzoneSending = function (
        file: DropzoneFile,
        xhr: XMLHttpRequest,
        formData: any,
      ) {
        emit("dropzone:sending", { file, xhr, formData });
      };

      const onDropzoneSuccess = function (
        this: Dropzone,
        file: DropzoneFile,
        response: unknown,
      ) {
        const parser = props.responseParser;

        try {
          const parsedResponse = parser(response);

          emit("dropzone:success", { file, serverResponse: parsedResponse });
        } catch (ex) {
          this.emit("error", file, {
            error: "DROPZONE_ERROR_LOADING_FILE",
            event: "onsuccess",
            message: ex.message,
          });
        }
      };

      const onDropzoneCanceled = function (file: DropzoneFile) {};

      const onDropzoneMaxFilesExceeded = function (file: DropzoneFile) {
        emit("dropzone:maxfilesexceeded", { addedFile: file });
      };

      const onDropzoneQueueComplete = function () {
        emit("dropzone:queueComplete");
      };

      /**
       * Container preview slot availability fix
       */
      const fixPreviewsContainerNullable = async (): Promise<void> => {
        return new Promise((res, rej) => {
          setTimeout(() => {
            return res();
          }, 500);
        });
      };

      const onThumbnailAddedRegisterCustomClickListener = (
        mockFile: DropzoneExistingMockedFile,
        thumbnail: unknown,
      ) => {
        if (!props.thumbnailClickCb) return;

        mockFile.previewElement.addEventListener("click", (evt: MouseEvent) => {
          evt.preventDefault();
          props.thumbnailClickCb(evt, mockFile);
        });
      };

      const onReset = () => {
        if (resetSyncController === null) return;

        resetSyncController.resolve(true);
      };

      onMounted(async () => {
        if (props.previewTemplate) await fixPreviewsContainerNullable();

        dropzoneInstance.value = new Dropzone(refDropzone.value as HTMLElement, {
          ...dropzoneInitialConfig,
          previewsContainer: props.previewsContainer,
          previewTemplate: props.previewTemplate
            ? props.previewTemplate
            : dropzoneDefaultPreviewHtml,
          ...props.customOptions,
        });

        queueController.start = dropzoneInstance.value.processQueue.bind(
          dropzoneInstance.value,
        );

        fileController.open = () => refDropzone.value?.click();

        dropzoneInstance.value.on("queuecomplete", onDropzoneQueueComplete);
        dropzoneInstance.value.on("error", onDropzoneError);
        dropzoneInstance.value.on("addedfile", onDropzoneAddedFile);
        dropzoneInstance.value.on("complete", onDropzoneComplete);
        dropzoneInstance.value.on("processing", onDropzoneProcessing);
        dropzoneInstance.value.on("removedfile", onDropzoneRemovedFile);
        dropzoneInstance.value.on("sending", onDropzoneSending);
        dropzoneInstance.value.on("success", onDropzoneSuccess);
        dropzoneInstance.value.on("canceled", onDropzoneCanceled);
        dropzoneInstance.value.on("maxfilesexceeded", onDropzoneMaxFilesExceeded);
        dropzoneInstance.value.on(
          "thumbnail",
          onThumbnailAddedRegisterCustomClickListener,
        );
        dropzoneInstance.value.on("reset", onReset);
      });

      return {
        refDropzone,
        queueController,
        fileController,
        dropzoneInstance,
        resetFiles,
      };
    },
  });
</script>

<style scoped>
  .dz-message-btn {
    margin: 0;
  }
</style>

