import type { App } from "vue";
import { logger } from "~/service/logger/logger";
import type { ColumnInterface } from "../lib/interfaces";

export const getInternalColumnInterfaces = (): ColumnInterface[] => {
  const columnInterfaces = import.meta.glob<ColumnInterface>("./*/index.ts", {
    import: "default",
    eager: true,
  });

  logger().debug(
    { columnInterfaces },
    `[datatable feature]: loaded internal column interfaces`,
  );

  return Object.values(columnInterfaces);
};

export const registerColumnInterfaces = (
  columnInterfaces: ColumnInterface[],
  app: App,
): void => {
  for (const columnInterface of columnInterfaces) {
    app.component(`datatable-column-${columnInterface.id}`, columnInterface.component);
  }
};

