import { useNuxtApp } from "#app";
import { defineStore } from "pinia";
import { deinitialize } from "~/service/app/initialization";
import { logger } from "~/service/logger/logger";
import { toaster } from "~/service/toaster";
import { ToastSeverities } from "~/service/toaster/configs/toasts";
import { defineConnectionState } from "~/shared/websocket/defines";
import SocketConnectionInterface from "~/shared/websocket/entity/SocketConnectionInterface";
import { ConnectionState, ConnectionStatus } from "~/shared/websocket/types";

const STORE_ID = "socketConnectionsStore";

interface State {
  connections: { [key: string]: SocketConnectionInterface };
}

export const useSocketConnectionsStore = defineStore(STORE_ID, {
  state(): State {
    return {
      connections: {},
    };
  },

  getters: {
    getConnection:
      (state) =>
      (id: string): SocketConnectionInterface | undefined =>
        id in state.connections ? state.connections[id] : undefined,

    getConnectionStatuses: (state) => (): ConnectionState[] => {
      const connectionStatuses: ConnectionState[] = [];

      for (const id in state.connections) {
        const status: ConnectionStatus = state.connections[id].socket.connected
          ? ConnectionStatus.AVAILABLE
          : ConnectionStatus.UNAVAILABLE;

        connectionStatuses.push(
          defineConnectionState({
            id,
            status,
          }),
        );
      }

      return connectionStatuses;
    },
    getConnectionState(state) {
      return (id: string): ConnectionState | undefined =>
        this.getConnectionStatuses().find((status) => status.id === id) ?? undefined;
    },
  },

  actions: {
    addConnection(id: string, socket: SocketConnectionInterface) {
      if (id in this.connections) return;

      this.$patch({
        connections: {
          ...this.connections,
          [id]: socket,
        },
      });
    },

    async initialize(): Promise<void> {
      const { $i18n } = useNuxtApp();

      for (const id in this.connections) {
        const connection = this.connections[id];

        connection.socket.on("disconnect", () => {
          toaster().open({
            type: ToastSeverities.WARNING,
            message: `${$i18n.t("error_service_disconnect")}: ${id}`,
            duration: 0,
            dismissible: true,
          });
        });

        connection.socket.on("connect_error", (err) => {
          logger().error({ err }, `${connection} is unable to establishing connection`);
        });

        connection.connect();
      }
    },

    async deinitialize(): Promise<void> {
      for (const id in this.connections) {
        this.connections[id].disconnect();
      }

      this.$reset();
    },
  },
});

