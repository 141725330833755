import { useNuxtApp } from "#app";
import { DirectusClient, RestClient, aggregate } from "@directus/sdk";
import { inject, injectable } from "inversify";
import { ItemsQueryResult, QueryMany } from "~/api/data-queries/types";
import { HttpTransportRequestOptions } from "~/service/http/types";
import { logger } from "~/service/logger/logger";
import { toaster } from "~/service/toaster";
import { FieldInfoInterface } from "~/entities/field";
import { castItemFromDataStudioApiToEntity } from "~/api/items/casters/castItemsFromDataStudioApi";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import { readItemsWithCoreCollections } from "~/service/data-studio/utils/readItemsWithCoreCollections";

@injectable()
export class RelationsGateway {
  constructor(
    @inject(INJECT_SYMBOLS.DatastudioRestClient)
    private readonly _dataStudioClient: DirectusClient<any> & RestClient<any>,
  ) {}

  async getManyByQuery(
    relationCollectionName: string,
    query: QueryMany<unknown>,
    collectionFieldsName: FieldInfoInterface[],
    transportOptions: HttpTransportRequestOptions,
  ): Promise<ItemsQueryResult> {
    const { $i18n } = useNuxtApp();

    try {
      const response = await this._dataStudioClient.request(
        readItemsWithCoreCollections(relationCollectionName, query),
      );

      const transformedResponse =
        response?.map((item) =>
          castItemFromDataStudioApiToEntity(item, collectionFieldsName),
        ) || [];

      const itemsCount = await this._dataStudioClient.request(
        aggregate(relationCollectionName, {
          aggregate: {
            count: "*",
          },
          query,
        }),
      );

      return {
        data: transformedResponse,
        meta: {
          filterCount: itemsCount,
        },
      };
    } catch (err) {
      logger().error(
        { err },
        `unable to fetch junction related items: ${(err as Error).message}`,
      );
      toaster().error($i18n.t("server_error_fetch_items"));

      return {
        data: [],
      };
    }
  }
}

