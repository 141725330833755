import { Item } from "@directus/sdk";
import ItemInterface from "../entities/ItemInterface";
import { useTransformersDatastudioStore } from "~/stores/transformersDatastudio";

export const castItemToDataStudioFormat = (item: ItemInterface): Item => {
  const transformersStore = useTransformersDatastudioStore();

  let transformedItem: Item = {};

  const fieldsForTransform = item.fields.filter((field) => !field.info.meta.isHidden);

  for (const itemField of fieldsForTransform) {
    const fieldTransformer = transformersStore.getItemFieldTransformer(
      itemField.info.meta.interface,
    );
    const transformedItemField = fieldTransformer?.to(itemField);

    transformedItem = {
      ...transformedItem,
      ...transformedItemField,
    };
  }

  return transformedItem;
};

