import get from "lodash/get";
import set from "lodash/set";
import { IItemMeta, IItem } from "~/entities/item";
import { useRolesStore, RoleInterface } from "~/entities/role";
import { UserInterface, UserData, UserID } from "../lib/types";

export default class User implements UserInterface {
  constructor(
    private _id: UserID,
    private _data: UserData,
  ) {}

  get id(): UserID {
    return this._id;
  }

  get data(): UserData {
    return this._data;
  }

  get meta(): IItemMeta {
    return null;
  }

  get role(): RoleInterface {
    const rolesStore = useRolesStore();
    return rolesStore.getFirstById(this._data.role)!;
  }

  setDataProperty(propertyOrPath: string, data: unknown): IItem<Record<string, any>> {
    set(this._data, propertyOrPath, data);
    return this;
  }

  getDataProperty(propertyOrPath: string): unknown {
    return get(this._data, propertyOrPath);
  }

  setMetaProperty(propertyOrPath: string, data: unknown): IItem<Record<string, any>> {
    return this;
  }

  getMetaProperty(propertyOrPath: string): unknown {
    return null;
  }
}

