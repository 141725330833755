import { useNuxtApp } from "#app";
import { DirectusClient, RestClient, readItems } from "@directus/sdk";
import { plainToInstance } from "class-transformer";
import { injectable, inject } from "inversify";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import { logger } from "~/service/logger/logger";
import { toaster } from "~/service/toaster";
import { MenuDto } from "../dto/Menu.dto";
import { MenuGatewayInterface } from "./MenuGatewayInterface";

@injectable()
export class PortalMenuGateway implements MenuGatewayInterface {
  constructor(
    @inject(INJECT_SYMBOLS.DatastudioRestClient)
    private readonly _datastudioApi: DirectusClient<any> & RestClient<any>,
  ) {}

  async getAllItems(): Promise<MenuDto[]> {
    const { $i18n } = useNuxtApp();

    try {
      const response = await this._datastudioApi.request(
        readItems("portal_sidebar_menu", { fields: ["*"] }),
      );

      return response.map((menuItem) => plainToInstance(MenuDto, menuItem)) ?? [];
    } catch (err) {
      logger().error({ err }, `unable to receive portal menu items`);
      toaster().error($i18n.t("error_fetch_portal_menu_items"));

      return [];
    }
  }
}

