import { IAuthByPhoneResponseDto, IAuthBySmsCodeResponseDto } from "../lib/types";

export const successAuthByPhoneResponseDto: IAuthByPhoneResponseDto = {
  success: true,
  code_challenge: "2222",
  stub_code: 1111,
};

export const failureAuthByPhoneResponseDto: IAuthByPhoneResponseDto = {
  success: false,
  error: "error auth_by_phone",
  error_message: "Something wrong with auth_by_phone",
};

export const successAuthBySmsResponseDto: IAuthBySmsCodeResponseDto = {
  success: true,
  access_token: "asdasdasdsad",
  refresh_token: "asdasdasdasdsad",
  expires: 123123213,
  expires_at: 12312312321321,
};

export const failureAuthBySmsResponseDto: IAuthBySmsCodeResponseDto = {
  success: false,
  error: "error auth_by_sms_code",
  error_message: "Something wrong with auth_by_sms_code",
};

