import { FieldFormInterface, FieldManyData } from "../lib/types";

export const defineFieldManyData = (data: FieldManyData): FieldManyData => {
  return data;
};

export const defineFieldFormInterface = (
  fieldFormInterface: FieldFormInterface,
): FieldFormInterface => {
  return fieldFormInterface;
};

