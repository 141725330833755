<template>
  <div
    :class="[
      'max-w-full md:max-w-none rounded-[30px] md:rounded-none px-4 md:px-[22px] min-w-0 min-h-screen bg-slate-100 flex-1 md:pt-20 pb-10 mt-5 md:mt-1 relative dark:bg-darkmode-700',
      'before:content-[\'\'] before:w-full before:h-px before:block',
    ]"
  >
    <slot></slot>
  </div>
</template>

<script setup lang="ts"></script>
