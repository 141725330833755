import { AppEvents } from "~/shared/lib/app-event-bus";
import { applyConditions } from "~/entities/field";
import { SystemReaction } from "~/entities/system-reactions";

export default new SystemReaction({
  id: "apply-conditions",

  priority: 0,

  scopes: [
    AppEvents.BEFORE_RENDER_ITEM_CREATE_VIEW,
    AppEvents.BEFORE_RENDER_ITEM_EDIT_VIEW,
    AppEvents.ITEM_DATA_CHANGED,
  ],

  matcher: (event, context) => {
    return true;
  },

  action: async (event, context) => {
    for (const field of context.data.fields) {
      // todo: после рефакторинга ItemInterface и FieldInterface
      //  требуется вызывать данную функциональность в компоненте форм.
      applyConditions(context.data.item, field);
    }
  },
});

