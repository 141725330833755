import "reflect-metadata";
import { Logger } from "pino";
import { inject, injectable } from "inversify";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import {
  IAuthByPhoneDto,
  IAuthByPhoneResponseDto,
  IAuthBySmsCodeDto,
  IAuthBySmsCodeResponseDto,
} from "../lib";
import {
  failureAuthByPhoneResponseDto,
  failureAuthBySmsResponseDto,
  successAuthByPhoneResponseDto,
  successAuthBySmsResponseDto,
} from "./mock-data";
import { AuthCredentials } from "~/service/auth/types/AuthCredentials";
import { useAuthStore } from "~/stores/auth";
import { AuthGatewayInterface } from "./types";

@injectable()
export default class FakeAuthGateway implements AuthGatewayInterface {
  constructor(
    @inject(INJECT_SYMBOLS.Logger)
    private readonly _logger: Logger,
  ) {}

  async authByEmail(AuthCredentials: AuthCredentials): Promise<boolean> {
    try {
      const authStore = useAuthStore();
      return await authStore.authenticate(AuthCredentials);
    } catch (err) {
      this._logger.error({ err }, `unable to auth by  phone`);
      throw err;
    }
  }

  async authByPhone(data: IAuthByPhoneDto): Promise<IAuthByPhoneResponseDto> {
    try {
      if (!data.phone) {
        return failureAuthByPhoneResponseDto;
      }

      return successAuthByPhoneResponseDto;
    } catch (err) {
      this._logger.error({ err }, `unable to auth by  phone`);
      throw err;
    }
  }

  async authBySms(data: IAuthBySmsCodeDto): Promise<IAuthBySmsCodeResponseDto> {
    try {
      if (
        data.code_challenge === successAuthByPhoneResponseDto.code_challenge &&
        data.code === successAuthByPhoneResponseDto.stub_code
      ) {
        return successAuthBySmsResponseDto;
      }
      return failureAuthBySmsResponseDto;
    } catch (err) {
      this._logger.error({ err }, `unable to auth by sms_code`);
      throw err;
    }
  }
}

