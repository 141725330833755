import merge from "lodash/merge";
import isPlainObject from "lodash/isPlainObject";
import ItemInterface from "~/api/items/entities/ItemInterface";
import type { Accountability, Filter } from "@directus/types";
import { ItemID } from "~/api/items/types/types";
import { useUserStore } from "~/entities/user";
import { parseFilter as parseFilterShared, validateFilter } from "~/entities/filter";
import { IItem } from "~/entities/item";
import { FieldInfoInterface, FieldInfoMetadataInterface } from "../lib";
import { useFieldsStore } from "./store";

/**
 *
 * @todo После рефакторинга ItemInterface и FieldInterface,
 *  оставить только реализацию applyConditionsRefactored
 */
export function applyConditions(
  item: ItemInterface,
  field: FieldInfoInterface,
): FieldInfoInterface {
  if (!field.meta || !Array.isArray(field.meta?.conditions)) return field;

  const itemData = getItemData(item);
  const itemDataWithUnwrappedRelational: Record<string, any> = {};
  for (const fieldKey in itemData) {
    const fieldData = itemData[fieldKey];

    if (isRelationalData(fieldData)) {
      itemDataWithUnwrappedRelational[fieldKey] =
        getRelationalDataForValidation(fieldData);
      continue;
    }

    itemDataWithUnwrappedRelational[fieldKey] = fieldData;
  }

  const conditions = field.meta.conditions!;

  const matchingCondition = conditions.find((condition) => {
    const rule = parseFilter(condition.rule);
    const errors = validateFilter(itemDataWithUnwrappedRelational, rule, {
      requireAll: true,
    });
    return !errors.length;
  });

  if (matchingCondition) {
    const newMeta = Object.assign({}, field.meta || {}, {
      isReadonly: matchingCondition.readonly || false,
      options: Object.assign(
        {},
        field.meta?.options || {},
        matchingCondition.options || {},
      ),
      isHidden: matchingCondition.hidden || false,
      isRequired: matchingCondition.required || false,
    });

    // @ts-expect-error
    field.meta = newMeta;
  } else {
    const sourceMeta = useFieldsStore().getField(field.collectionName, field.name)?.meta;
    if (!!sourceMeta) {
      // @ts-expect-error
      field.meta = sourceMeta;
    }
  }

  return field;
}

/**
 *
 */
export function applyConditionsRefactored(
  item: IItem,
  field: FieldInfoInterface,
): FieldInfoInterface {
  if (!field.meta || !Array.isArray(field.meta?.conditions)) return field;

  const dataWithUnwrappedRelational: Record<string, any> = {};
  for (const fieldKey in item.data) {
    const fieldData = item.data[fieldKey];

    if (isRelationalData(fieldData)) {
      dataWithUnwrappedRelational[fieldKey] = getRelationalDataForValidation(fieldData);
      continue;
    }

    dataWithUnwrappedRelational[fieldKey] = fieldData;
  }

  const conditions = field.meta.conditions!;

  const matchingCondition = conditions.find((condition) => {
    const rule = parseFilter(condition.rule);
    const errors = validateFilter(dataWithUnwrappedRelational, rule, {
      requireAll: true,
    });
    return !errors.length;
  });

  if (matchingCondition) {
    const newMeta: Partial<FieldInfoMetadataInterface> = merge({}, field.meta || {}, {
      isReadonly: matchingCondition.readonly || false,
      options: matchingCondition.options || {},
      isHidden: matchingCondition.hidden || false,
      isRequired: matchingCondition.required || false,
    });

    return {
      ...field,
      // @ts-expect-error
      meta: newMeta,
    };
  }

  return field;
}

/**
 *
 * @param filter
 */
function parseFilter(filter: Filter | null): Filter {
  const userStore = useUserStore();

  if (!userStore.me) return filter ?? {};
  if (!userStore.me.id) return filter ?? {};

  const accountability: Accountability = {
    role: userStore.me.role.id,
    // @ts-expect-error
    user: userStore.me.id,
  };

  return parseFilterShared(filter, accountability) ?? {};
}

/**
 *
 * @param item
 */
function getItemData(item: ItemInterface): Record<string, any> {
  const itemData: Record<string, any> = {};

  for (const field of item.fields) {
    itemData[field.info.name] = field.data;
  }

  return itemData;
}

/**
 *
 * @param data
 */
function isRelationalData(data: any): boolean {
  const isRelationalData =
    isPlainObject(data) &&
    Object.keys(data).every((key) =>
      [
        "create",
        "currentJunctionItemIds",
        "newRelatedItemIds",
        "removeJunctionItemIds",
      ].includes(key),
    );

  return isRelationalData;
}

/**
 *
 * @param data
 */
function getRelationalDataForValidation(data: {
  create: ItemID[];
  currentJunctionsItemIds: ItemID[];
  newRelatedItemIds: ItemID[];
  removeJunctionItemIds: ItemID[];
}): ItemID[] | null {
  return !!data.currentJunctionsItemIds?.length ? data.currentJunctionsItemIds : null;
}

