import { defineNuxtPlugin } from "#app";
import { Field, Item } from "@directus/types";
import { FieldManyData, FieldInterface } from "~/entities/field";
import { useRelationsStore } from "~/stores/relations";
import {
  defineItemFieldTransformer,
  useTransformersDatastudioStore,
} from "~/stores/transformersDatastudio";

export default defineNuxtPlugin((nuxtApp) => {
  const transformersStore = useTransformersDatastudioStore();

  transformersStore.registerForFieldInterface(
    "files",
    defineItemFieldTransformer({
      to: (field: FieldInterface): Item => {
        const fieldData: FieldManyData = field.data;

        const transformedFieldData: {
          create: Item[];
          update: (number | string)[];
          delete: (string | number)[];
        } = {
          create: [],
          update: [],
          delete: [],
        };

        for (const newItem of fieldData.create) {
          const itemForCreate: Item = {
            directus_files_id: newItem.getFieldDataByExpression("id"),
          };

          transformedFieldData.create.push(itemForCreate);
        }

        for (const removeJunctionId of fieldData.removeJunctionItemIds) {
          transformedFieldData.delete.push(removeJunctionId);
        }

        return {
          [field.info.name]: transformedFieldData,
        };
      },

      from: (fieldInfo: Field, data: any): FieldInterface => {
        return transformersStore.defaultItemFieldTransformer.from(fieldInfo, data);
      },
    }),
  );

  transformersStore.registerForFieldInterface(
    "list-m2m",
    defineItemFieldTransformer({
      to: (field: FieldInterface): Item => {
        const fieldData: FieldManyData = field.data;
        if (!fieldData)
          return {
            [field.info.name]: null,
          };

        const transformedFieldData: {
          create: Item[];
          update: (number | string)[];
          delete: (string | number)[];
        } = {
          create: [],
          update: [],
          delete: [],
        };

        const relationsStore = useRelationsStore();

        const relations = relationsStore.getRelationsByField(
          field.info.collectionName,
          field.info.name,
        );

        const junctionRelation = relations.find(
          (relation) =>
            relation.relatedCollection === field.info.collectionName &&
            relation.meta?.oneField === field.info.name &&
            relation.meta.junctionField,
        );

        const fieldKey = junctionRelation?.meta?.junctionField;
        if (!!fieldKey) {
          for (const relatedItemId of fieldData.newRelatedItemIds) {
            const itemForCreate: Item = {
              [fieldKey]: relatedItemId,
            };

            transformedFieldData.create.push(itemForCreate);
          }
        }

        for (const removeJunctionId of fieldData.removeJunctionItemIds) {
          transformedFieldData.delete.push(removeJunctionId);
        }

        return {
          [field.info.name]: transformedFieldData,
        };
      },

      from: (fieldInfo: Field, data: any): FieldInterface => {
        return transformersStore.defaultItemFieldTransformer.from(fieldInfo, data);
      },
    }),
  );

  transformersStore.registerForFieldInterface(
    "list-o2m",
    defineItemFieldTransformer({
      to(field) {
        const fieldData: FieldManyData = field.data;
        if (!fieldData)
          return {
            [field.info.name]: null,
          };

        const transformedFieldData: (string | number)[] = [
          ...fieldData.currentJunctionItemIds,
        ];

        return {
          [field.info.name]: transformedFieldData,
        };
      },

      from(fieldInfo: Field, data: any): FieldInterface {
        return transformersStore.defaultItemFieldTransformer.from(fieldInfo, data);
      },
    }),
  );
});

