<template>
  <div
    :class="[
      'h-[70px] md:h-[65px] z-[51] border-b border-white/[0.08] mt-12 md:mt-0 -mx-3 sm:-mx-8 md:-mx-0 px-3 md:border-b-0 relative md:fixed md:inset-x-0 md:top-0 sm:px-8 md:px-10 md:pt-10 md:bg-gradient-to-b md:from-slate-100 md:to-transparent dark:md:from-darkmode-700',
      props.layout == 'top-menu' && 'dark:md:from-darkmode-800',
      'before:content-[\'\'] before:absolute before:h-[65px] before:inset-0 before:top-0 before:mx-7 before:bg-primary/30 before:mt-3 before:rounded-xl before:hidden before:md:block before:dark:bg-darkmode-600/30',
      'after:content-[\'\'] after:absolute after:inset-0 after:h-[65px] after:mx-3 after:bg-primary after:mt-5 after:rounded-xl after:shadow-md after:hidden after:md:block after:dark:bg-darkmode-600',
    ]"
  >
    <div class="flex items-center h-full justify-between">
      <!-- BEGIN: Logo -->
      <router-link
        :to="{ name: RoutePathNames.HOME }"
        :class="[
          '-intro-x hidden md:flex',
          props.layout == 'side-menu' && 'xl:w-[180px]',
          props.layout == 'simple-menu' && 'xl:w-auto',
          props.layout == 'top-menu' && 'w-auto',
        ]"
      >
        <!-- <img alt="" class="w-6" :src="''" /> -->

        <span
          :class="[
            'ml-3 text-lg text-white',
            props.layout == 'side-menu' && 'hidden xl:block',
            props.layout == 'simple-menu' && 'hidden',
          ]"
        >
          {{ projectName }}
        </span>
      </router-link>
      <!-- END: Logo -->

      <!-- BEGIN: Breadcrumb -->
      <Breadcrumb
        light
        :class="[
          'h-[45px] md:ml-10 md:border-l border-white/[0.08] dark:border-white/[0.08] mr-auto -intro-x',
          props.layout != 'top-menu' && 'md:pl-6',
          props.layout == 'top-menu' && 'md:pl-10',
        ]"
      >
        <Breadcrumb.Link to="/">Application</Breadcrumb.Link>

        <Breadcrumb.Link to="/" :active="true"> Dashboard </Breadcrumb.Link>
      </Breadcrumb>
      <!-- END: Breadcrumb -->

      <Popover class="mr-4 intro-x sm:mr-6">
        <Popover.Button class="relative text-white/70 outline-none block">
          <i
            :class="[
              'fa-solid fa-signal w-5 h-5 dark:text-slate-500',
              {
                'text-green-500': socketServicesAvailability === ServiceAvailability.FULL,
                'text-red-500':
                  socketServicesAvailability === ServiceAvailability.ALL_UNAVAILABLE,
                'text-orange-500':
                  socketServicesAvailability === ServiceAvailability.PARTIAL,
              },
            ]"
          ></i>
        </Popover.Button>

        <Popover.Panel class="w-[280px] sm:w-[350px] p-5 mt-2">
          <div class="mb-5">
            <span class="block mb-2 font-medium capitalize">
              {{ $t("sytem_services_connection_status") }}
            </span>

            <div
              v-for="(connectionState, idx) in socketConnectionStates"
              :key="connectionState.id"
              :class="['relative flex items-center justify-between', { 'mt-5': !!idx }]"
            >
              <p>{{ connectionState.id }}</p>

              <FormSwitch>
                <FormSwitch.Input
                  :id="connectionState.id"
                  type="checkbox"
                  :checked="connectionState.status === ConnectionStatus.AVAILABLE"
                  disabled
                />
              </FormSwitch>
            </div>
          </div>
        </Popover.Panel>
      </Popover>

      <!-- BEGIN: Account Menu -->
      <Menu>
        <Menu.Button
          class="block w-8 h-8 overflow-hidden rounded-full shadow-lg image-fit zoom-in intro-x"
        >
          <img
            v-if="!!me"
            :alt="me.getDataProperty('avatar') as string"
            :src="
              !!me.getDataProperty('avatar')
                ? getDatastudioAssetUrl(me.getDataProperty('avatar') as string)
                : ''
            "
          />
        </Menu.Button>

        <Menu.Items
          class="grid gap-2 w-56 mt-px bg-primary/80 before:block before:absolute before:bg-black before:inset-0 before:rounded-md before:z-[-1] text-white capitalize"
        >
          <Menu.Header class="font-normal">
            <div class="font-medium">
              {{
                `${me?.getDataProperty("first_name")} ${me?.getDataProperty("last_name")}`
              }}
            </div>

            <div class="text-xs text-white/70 mt-0.5 dark:text-slate-500">
              {{ me?.role.name }}
            </div>
          </Menu.Header>

          <Menu.Divider class="bg-white/[0.08]" />

          <Menu.Item
            class="bg-success hover:bg-success"
            @click.prevent="navigateTo(`/users/${me?.id}`)"
          >
            {{ $t("profile") }}
          </Menu.Item>

          <Menu.Item class="bg-danger hover:bg-danger" @click.prevent="$emit('logout')">
            {{ $t("logout") }}
          </Menu.Item>
        </Menu.Items>
      </Menu>
      <!-- END: Account Menu -->
    </div>
  </div>
</template>

<script setup lang="ts">
  import { navigateTo } from "#app";
  import { computed } from "vue";
  import Breadcrumb from "~/components/Breadcrumb";
  import Menu from "~/shared/ui/Menu";
  import { UserInterface, useUserStore } from "~/entities/user";
  import { useSettingsStore } from "~/stores/settings";
  import { RoutePathNames } from "~/app/router.options";
  import Popover from "~/shared/ui/Popover";
  import { useSocketConnectionsStore } from "~/stores/socket-connections";
  import { ConnectionState, ConnectionStatus } from "~/shared/websocket/types";
  import { FormSwitch } from "~/shared/ui/Form";
  import { getDatastudioAssetUrl } from "~/api/files/utils/url";

  const props = defineProps<{
    layout?: "side-menu" | "simple-menu" | "top-menu";
  }>();

  defineEmits({
    logout: null,
  });

  const userStore = useUserStore();
  const settingsStore = useSettingsStore();
  const socketConnectionsStore = useSocketConnectionsStore();

  const me = computed<UserInterface | undefined>(() => userStore.me);

  const projectName = computed<string>(
    () => settingsStore.projectSettings?.projectName ?? "",
  );

  const socketConnectionStates = computed<ConnectionState[]>(() =>
    socketConnectionsStore.getConnectionStatuses(),
  );

  enum ServiceAvailability {
    FULL,
    PARTIAL,
    ALL_UNAVAILABLE,
  }

  const socketServicesAvailability = computed<ServiceAvailability>(() => {
    const countUnavailable = socketConnectionStates.value.filter(
      (state) => state.status === ConnectionStatus.UNAVAILABLE,
    ).length;

    if (countUnavailable === socketConnectionStates.value.length)
      return ServiceAvailability.ALL_UNAVAILABLE;

    if (!!countUnavailable && countUnavailable < socketConnectionStates.value.length)
      return ServiceAvailability.PARTIAL;

    return ServiceAvailability.FULL;
  });
</script>
