import { Item } from "@directus/types";
import type { RouteLocationNormalized } from "vue-router";

export enum AppEvents {
  ROUTE_CHANGE = "RouteChange",
  ITEM_UPDATED = "ItemUpdated",
  ITEM_DATA_CHANGED = "ItemDataChanged",
  BEFORE_RENDER_ITEM_EDIT_VIEW = "BeforeRenderItemEditView",
  BEFORE_RENDER_ITEM_CREATE_VIEW = "BeforeRenderItemCreateView",
}

export type AppEventsPayload = {
  [AppEvents.ROUTE_CHANGE]: {
    to: RouteLocationNormalized;
    from: RouteLocationNormalized;
  };

  [AppEvents.ITEM_UPDATED]: {
    collectionName: string;
    itemId: string | number;
    updatedData: Item;
  };

  [AppEvents.ITEM_DATA_CHANGED]: {
    collectionName: string;
    data: {
      // indexing by field name
      [key: string]: any;
    };
  };

  [AppEvents.BEFORE_RENDER_ITEM_EDIT_VIEW]: {
    collectionName: string;
  };

  [AppEvents.BEFORE_RENDER_ITEM_CREATE_VIEW]: {
    collectionName: string;
  };
};

export interface AppEvent<T extends AppEvents = AppEvents> {
  event: T;
  payload: AppEventsPayload[T];
}

