<template>
  <v-flat-pickr
    :modelValue="localeModelValue"
    :class="[
      'flatpickr-vue',
      'w-full',
      'form-control',
      {
        'border-danger': !isValid,
      },
    ]"
    :config="config"
    :disabled="props.field.info.meta.isReadonly"
    @update:modelValue="onUpdateModelValue"
  ></v-flat-pickr>

  <ValidationMessage v-if="!!errors.length" :errors="errors" />
</template>

<script setup lang="ts">
type _VTI_TYPE_QueryLogicalFilterAnd = {
  [QueryFilterLogicalEnum._and]: QueryFilter<T>[];
}
type _VTI_TYPE_ValidationFilter = _VTI_TYPE_QueryLogicalFilterAnd<any>
type _VTI_TYPE_FieldFilter = {
  [K in keyof QuerySingleItem]?:
    | QueryFilterOperators<QuerySingleItem[K]>
    | FieldFilter<QuerySingleItem[K]>;
}
interface _VTI_TYPE_FieldInfoMetaValidation {
  message: string | null;
  rules: _VTI_TYPE_ValidationFilter | null;
}
interface _VTI_TYPE_FieldInfoSchema {
  defaultValue: unknown | null;
  maxLength: unknown | null;
  comment: unknown | null;
  numericPrecision: unknown | null;
  numericScale: unknown | null;
  hasAutoIncrement: boolean;
}
interface _VTI_TYPE_FieldInfoMetaOptions {
  choices?: FieldInfoMetaOptionsChoices[];
  folder?: string;
  title?: string;
  filter?: _VTI_TYPE_FieldFilter;
  template?: string; // Interface display template. Have priority on common display property
  masked?: boolean;
  placeholder?: string;
}
interface _VTI_TYPE_FieldInfoMetadataInterface {
  // type of field
  interface: string;
  // may contains filter expressions, etc...
  options: _VTI_TYPE_FieldInfoMetaOptions;
  // type of display value
  display: string | null;
  displayOptions: {
    template?: string;
    choices?: FieldDisplayOptionsChoice[];
  } | null;
  isReadonly: boolean;
  isHidden: boolean;
  isRequired: boolean;
  // sort position in collection
  sortPosition: number;
  translations: FieldTranslationInterface[];
  note: string | null;
  // is that field is ID
  readonly isPrimaryKey: boolean;
  isUnique: boolean;
  isNullable: boolean;
  schema: _VTI_TYPE_FieldInfoSchema | null;
  validation: _VTI_TYPE_FieldInfoMetaValidation;
  special: any;
  conditions: Condition[] | null;
}
interface _VTI_TYPE_FieldInfoInterface {
  readonly id: number;
  readonly name: string;
  readonly type: string;
  readonly collectionName: string;
  readonly meta: _VTI_TYPE_FieldInfoMetadataInterface;
}
interface _VTI_TYPE_FieldInterface {
  readonly info: _VTI_TYPE_FieldInfoInterface;
  readonly data: any;
  readonly label: string;
  readonly isDirty: boolean;
  setData(data: any): this;
  setDirty(): this;
  setClean(): this;
}
interface FieldInterfaceEmits {
  (
    e: FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA,
    data: FieldDataUpdateEmitPayload,
  ): void;
}
interface FieldFormInterfaceProps {
  collection: _VTI_TYPE_CollectionInterface;
  item: _VTI_TYPE_ItemInterface;
  field: _VTI_TYPE_FieldInterface;
}
  import { ref, computed } from 'vue'
  import VFlatPickr from 'vue-flatpickr-component'
  import dayjs from 'dayjs'
  import { FieldInterfaceEmitId, defineEmitUpdateItemFieldDataPayload } from '~/api/field-interfaces/emits'
  import { useFieldValidation } from '~/entities/field'
  import ValidationMessage from '~/shared/ui/ValidationMessage'
  
  const props = defineProps<FieldFormInterfaceProps>();
  const emit = defineEmits<FieldInterfaceEmits>();
  const localeModelValue = computed<Date>(() => {
    return !!props.field.data ? new Date(props.field.data) : props.field.data;
  });
  const onUpdateModelValue = (newData: string) => {
    const updatedData = dayjs(newData).toISOString();
    emit(
      FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA,
      defineEmitUpdateItemFieldDataPayload({
        collectionName: props.collection.id,
        fieldName: props.field.info.name,
        updatedData,
      }),
    );
  };
  const config = ref<Partial<import("flatpickr/dist/types/options").BaseOptions>>({
    defaultHour: 0,
    defaultMinute: 0,
    enableTime: true,
    disableMobile: false,
    mode: "single", //
  });
  const { isValid, errors } = useFieldValidation(computed(() => props.field));
</script>

<style scoped></style>
