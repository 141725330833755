import { UserRoleQueryItem } from "~/entities/user";
import { RoleInterface, Role } from "../../model";

/**
 *
 * @param roleItem
 * @returns
 */
export const castRoleQueryItemToEntity = (roleItem: UserRoleQueryItem): RoleInterface => {
  return new Role(roleItem.id, roleItem.name, roleItem.admin_access, roleItem.app_access);
};

