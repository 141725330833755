import "reflect-metadata";
import type { Container } from "inversify";
import type {
  AuthenticationStorage,
  GraphqlClient as DataStudioGraphqlClient,
  RestClient as DataStudioRestClient,
} from "@directus/sdk";
import type { Logger } from "pino";
import { logger } from "~/service/logger/logger";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import { CollectionsQueryGatewayInterface } from "~/api/collections/gateway/CollectionsQueryGatewayInterface";
import { CollectionsQueryGateway } from "~/api/collections/gateway/CollectionsQueryGateway";
import ItemsWriteGatewayInterface from "~/api/items/gateway/ItemsWriteGatewayInterface";
import ItemsWriteGateway from "~/api/items/gateway/ItemsWriteGateway";
import ItemCommitWorkInterface from "~/api/items/units-of-work/ItemCommitWorkInterface";
import ItemCommitWork from "~/api/items/units-of-work/ItemCommitWork";
import ItemRevisionQueryGatewayInterface from "~/api/revisions/gateways/ItemRevisionQueryGatewayInterface";
import ItemRevisionQueryGateway from "~/api/revisions/gateways/ItemRevisionsQueryGateway";
import CommentsDataGatewayInterface from "~/api/comments/gateway/CommentsDataGatewayInterface";
import CommentsDataGateway from "~/api/comments/gateway/CommentsDataGateway";
import { UsersGatewayInterface, UserGateway } from "~/entities/user";
import { LayoutPresetsDataGatewayInterface } from "~/api/layout-presets/gateways/LayoutPresetsDataGatewayInterface";
import { LayoutPresetsDataGateway } from "~/api/layout-presets/gateways/LayoutPresetsDataGateway";
import { AppEventBus } from "~/shared/lib/app-event-bus";
import { RelationsGateway } from "~/api/relations/gateway/RelationsGateway";
import { useDataStudioClient } from "~/service/data-studio/composables/useDataStudioClient";
import { MenuGatewayInterface } from "~/api/menu/gateway/MenuGatewayInterface";
import { PortalMenuGateway } from "~/api/menu/gateway/PortalMenuGateway";
import { LayoutPresetsMapper } from "~/api/layout-presets/mappers/layout-presets.mapper";
import { PermissionsMapperInterface } from "~/api/permissions/mapper/PermissionsMapperInterface";
import { PermissionsDirectusMapper } from "~/api/permissions/mapper/PermissionsDirectusMapper";
import { PermissionsGatewayInterface } from "~/api/permissions/gateway/PermissionsGatewayInterface";
import { PermissionsDirectusGateway } from "~/api/permissions/gateway/PermissionsDirectusGateway";
import { RolesGatewayInterface, RolesGateway } from "~/entities/role";
import { ItemReactionsController } from "~/entities/system-reactions";
import { LocalStorageAuthDriver, StorageAuthDriver } from "~/shared/api/directus";
import {
  AuthGatewayInterface,
  FakeAuthGateway,
  AuthByPhoneServiceInterface,
  AuthByPhoneService,
} from "~/entities/authentication";

export function loadInversionBindings(container: Container) {
  container
    .bind<CollectionsQueryGatewayInterface>(
      INJECT_SYMBOLS.CollectionsQueryGatewayInterface,
    )
    .to(CollectionsQueryGateway);

  container
    .bind<ItemsWriteGatewayInterface>(INJECT_SYMBOLS.ItemsWriteGatewayInterface)
    .to(ItemsWriteGateway);

  container
    .bind<ItemCommitWorkInterface>(INJECT_SYMBOLS.ItemCommitWorkInterface)
    .to(ItemCommitWork);

  container
    .bind<ItemRevisionQueryGatewayInterface>(
      INJECT_SYMBOLS.ItemRevisionQueryGatewayInterface,
    )
    .to(ItemRevisionQueryGateway);

  container
    .bind<CommentsDataGatewayInterface>(INJECT_SYMBOLS.CommentsDataGatewayInterface)
    .to(CommentsDataGateway);

  container
    .bind<DataStudioGraphqlClient<any>>(INJECT_SYMBOLS.DatastudioGraphqlClient)
    .toDynamicValue(() => {
      const { client } = useDataStudioClient();
      return client;
    });

  container
    .bind<UsersGatewayInterface>(INJECT_SYMBOLS.UsersGatewayInterface)
    .to(UserGateway);

  container
    .bind<LayoutPresetsMapper>(INJECT_SYMBOLS.LayoutPresetsMapper)
    .to(LayoutPresetsMapper);
  container
    .bind<LayoutPresetsDataGatewayInterface>(
      INJECT_SYMBOLS.LayoutPresetsDataGatewayInterface,
    )
    .to(LayoutPresetsDataGateway);

  container
    .bind<AppEventBus>(INJECT_SYMBOLS.AppEventBus)
    .toDynamicValue(() => new AppEventBus())
    .inSingletonScope();

  container
    .bind<DataStudioRestClient<any>>(INJECT_SYMBOLS.DatastudioRestClient)
    .toDynamicValue(() => {
      const { client } = useDataStudioClient();
      return client;
    });

  container.bind<RelationsGateway>(INJECT_SYMBOLS.RelationsGateway).to(RelationsGateway);

  container
    .bind<MenuGatewayInterface>(INJECT_SYMBOLS.PortalMenuGateway)
    .to(PortalMenuGateway);

  container
    .bind<PermissionsMapperInterface>(INJECT_SYMBOLS.PermissionsMapperInterface)
    .to(PermissionsDirectusMapper);

  container
    .bind<PermissionsGatewayInterface>(INJECT_SYMBOLS.PermissionsGatewayInterface)
    .to(PermissionsDirectusGateway);

  container.bind<Logger>(INJECT_SYMBOLS.Logger).toDynamicValue(() => logger());

  container
    .bind<RolesGatewayInterface>(INJECT_SYMBOLS.RolesGatewayInterface)
    .to(RolesGateway);

  container
    .bind<ItemReactionsController>(INJECT_SYMBOLS.ItemReactionsController)
    .to(ItemReactionsController);

  container
    .bind<StorageAuthDriver>(INJECT_SYMBOLS.StorageAuthDriver)
    .to(LocalStorageAuthDriver);

  container
    .bind<AuthGatewayInterface>(INJECT_SYMBOLS.AuthGatewayInterface)
    .to(FakeAuthGateway);

  container
    .bind<AuthByPhoneServiceInterface>(INJECT_SYMBOLS.AuthByPhoneServiceInterface)
    .to(AuthByPhoneService);
}

