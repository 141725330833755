import { getRelatedCollection } from "~/api/relations/utils/get-related-collection";
import { useCollecitonsStore } from "~/stores/collections";
import {
  adjustFieldsForDisplays,
  getFieldsFromTemplate,
} from "~/api/field-displays/utils";
import { defineFieldDisplay } from "../defines";
import RelatedValues from "./related-values.vue";
import { FieldLocalType, getFieldLocalType, useFieldsStore } from "~/entities/field";

export default defineFieldDisplay({
  id: "related-values",
  component: RelatedValues,
  fields(options, context) {
    const collectionsStore = useCollecitonsStore();
    const fieldsStore = useFieldsStore();

    const field = fieldsStore.getField(context.collectionName, context.fieldName);
    const relatedData = getRelatedCollection(context.collectionName, context.fieldName);
    if (!relatedData || !field) return [];

    const { relatedCollectionName, junctionCollectionName, path } = relatedData;

    const relatedCollection = collectionsStore.getCollection(relatedCollectionName);
    if (!relatedCollection) return [];

    const junctionCollection = !!junctionCollectionName
      ? collectionsStore.getCollection(junctionCollectionName)
      : undefined;

    const renderTemplate = getRenderTemplate();

    const fields = adjustFieldsForDisplays(
      getFieldsFromTemplate(renderTemplate),
      junctionCollection || relatedCollection,
    );

    const primaryKeyField = fieldsStore.getPrimaryField(relatedCollection.id);
    if (primaryKeyField) {
      const adjustedPrimaryKeyFieldPart = path
        ? [...path, primaryKeyField.name].join(".")
        : primaryKeyField.name;

      if (!fields.includes(adjustedPrimaryKeyFieldPart)) {
        fields.push(adjustedPrimaryKeyFieldPart);
      }
    }

    return fields;

    function getRenderTemplate(): string {
      const localType = getFieldLocalType(context.collectionName, context.fieldName);

      if (localType === FieldLocalType.m2m) {
        if (!relatedCollection) return "";

        const templatePrefixFieldName = path;

        const relatedTemplate = relatedCollection.meta?.displayTemplate;
        if (relatedTemplate) {
          const templateParts = getFieldsFromTemplate(relatedTemplate);
          const transformedTemplate = templateParts
            .map((fieldName) => `{{${templatePrefixFieldName}.${fieldName}}}`)
            .join(" ");

          return `${transformedTemplate}`;
        }

        const relatedPKFieldName = relatedCollection.getPrimaryFieldInfo()!.name;
        const fallbackTemplate = `{{${templatePrefixFieldName}.${relatedPKFieldName}}}`;

        return fallbackTemplate;
      }

      const template = field?.meta.displayOptions?.template;
      return template || `{{${relatedCollection!.getPrimaryFieldInfo()!.name}}}`;
    }
  },
});

