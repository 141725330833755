<template>
  <div>
    <PMessage v-if="!relationInfo">
      {{ $t("field_relationship_not_setup") }}
    </PMessage>

    <template v-else>
      <PTreeSelect
        v-if="isRelatedToTreeView"
        :options="treeNodes"
        :modelValue="treeViewModelValue"
        display="chip"
        selectionMode="checkbox"
        :loading="isRelatedItemsLoading"
        :placeholder="isRelatedItemsLoading ? $t('items_is_loading') : ''"
        :tabindex="props.field.info.meta.sortPosition"
        :aria-label="props.field.label"
        :emptyMessage="$t('field_select_empty_items')"
        class="w-full"
        inputClass="form-select sm:mt-2 sm:mr-2"
        @update:modelValue="onUpdateModelValue"
        v-on="dropdownEventListeners"
      >
        <template #value="slotProps">
          <template v-if="isArray(slotProps.value)">
            <div
              v-for="node of slotProps.value"
              :key="node.key"
              class="p-treeselect-token"
            >
              <span class="p-treeselect-token-label">
                <div
                  v-if="!!relatedItemsHashMap[node.data] && !!node.data"
                  class="flex items-center justify-between p-1"
                >
                  <RenderTemplate
                    :collectionName="relationInfo.relatedCollection ?? ''"
                    :item="relatedItemsHashMap[node.data]"
                    :fieldInfo="props.field.info"
                    :defaultTemplate="relatedRenderTemplate ?? ''"
                  />

                  <Button
                    v-if="
                      !!relatedCollection &&
                      canNavigateToRelationalItem(relatedCollection)
                    "
                    variant="transparent"
                    size="small"
                    @click="
                      routeToItem(
                        relatedCollection!.id,
                        relatedItemsHashMap[node.data].id,
                        router,
                      )
                    "
                  >
                    <i class="fa-solid fa-arrow-up-right-from-square"></i>
                  </Button>
                </div>

                <template v-else>
                  {{ node.data }}
                </template>
              </span>
            </div>
          </template>

          <template v-else>
            {{ slotProps.value }}
          </template>
        </template>
      </PTreeSelect>

      <MultiSelect
        v-else
        optionValue="value"
        optionLabel="label"
        dataKey="value"
        :options="stableOptions"
        :virtualScrollerOptions="virtualScrollerOptions"
        :editable="false"
        :emptySelectionMessage="$t('field_select_empty_selection')"
        :emptyFilterMessage="$t('field_select_filter_empty_items')"
        :emptyMessage="$t('field_select_empty_items')"
        :tabindex="props.field.info.meta.sortPosition"
        :aria-label="props.field.label"
        :class="['w-full']"
        inputClass="form-select"
        :modelValue="stableModelValue"
        display="chip"
        :loading="isRelatedItemsLoading"
        :placeholder="isRelatedItemsLoading ? $t('items_is_loading') : ''"
        :disabled="isRelatedItemsLoading || props.field.info.meta.isReadonly"
        @update:modelValue="onUpdateModelValue"
        v-on="dropdownEventListeners"
      >
        <template #header>
          <div class="p-dropdown-header">
            <div class="p-dropdown-filter-container">
              <input
                type="text"
                autocomplete="off"
                role="search"
                :value="search"
                :class="['p-dropdown-filter', 'p-inputtext', 'p-component']"
                v-on="searchEventListeners"
              />

              <span :class="['p-dropdown-filter-icon', 'pi pi-search']" />
            </div>
          </div>
        </template>

        <template #chip="slotProps">
          <div
            v-if="!!relatedItemsHashMap[slotProps.value] && !!slotProps.value"
            class="flex items-center justify-between p-1"
          >
            <RenderTemplate
              :collectionName="relationInfo.relatedCollection ?? ''"
              :item="relatedItemsHashMap[slotProps.value]"
              :fieldInfo="props.field.info"
              :defaultTemplate="relatedRenderTemplate ?? ''"
            />
            <div class="multiselect-chip__button">
              <Button
                v-if="
                  !!relatedCollection && canNavigateToRelationalItem(relatedCollection)
                "
                size="small"
                :variant="'transparent'"
                class="border-none drop-shadow-none shadow-none"
                @click="
                  routeToItem(
                    relatedCollection!.id,
                    relatedItemsHashMap[slotProps.value].id,
                    router,
                  )
                "
              >
                <i class="fa-solid fa-arrow-up-right-from-square"></i>
              </Button>
            </div>
          </div>

          <template v-else>
            {{ slotProps.value }}
          </template>
        </template>

        <template #option="slotProps">
          <RenderTemplate
            v-if="!!relatedItemsHashMap[slotProps.option.value]"
            :collectionName="relationInfo.relatedCollection ?? ''"
            :item="relatedItemsHashMap[slotProps.option.value]"
            :fieldInfo="props.field.info"
            :defaultTemplate="relatedRenderTemplate ?? ''"
          ></RenderTemplate>

          <template v-else>
            {{ slotProps.option.label }}
          </template>
        </template>
      </MultiSelect>
    </template>
  </div>
</template>

<script setup lang="ts">
type _VTI_TYPE_QueryLogicalFilterAnd = {
  [QueryFilterLogicalEnum._and]: QueryFilter<T>[];
}
type _VTI_TYPE_ValidationFilter = _VTI_TYPE_QueryLogicalFilterAnd<any>
type _VTI_TYPE_FieldFilter = {
  [K in keyof QuerySingleItem]?:
    | QueryFilterOperators<QuerySingleItem[K]>
    | FieldFilter<QuerySingleItem[K]>;
}
interface _VTI_TYPE_FieldInfoMetaValidation {
  message: string | null;
  rules: _VTI_TYPE_ValidationFilter | null;
}
interface _VTI_TYPE_FieldInfoSchema {
  defaultValue: unknown | null;
  maxLength: unknown | null;
  comment: unknown | null;
  numericPrecision: unknown | null;
  numericScale: unknown | null;
  hasAutoIncrement: boolean;
}
interface _VTI_TYPE_FieldInfoMetaOptions {
  choices?: FieldInfoMetaOptionsChoices[];
  folder?: string;
  title?: string;
  filter?: _VTI_TYPE_FieldFilter;
  template?: string; // Interface display template. Have priority on common display property
  masked?: boolean;
  placeholder?: string;
}
interface _VTI_TYPE_FieldInfoMetadataInterface {
  // type of field
  interface: string;
  // may contains filter expressions, etc...
  options: _VTI_TYPE_FieldInfoMetaOptions;
  // type of display value
  display: string | null;
  displayOptions: {
    template?: string;
    choices?: FieldDisplayOptionsChoice[];
  } | null;
  isReadonly: boolean;
  isHidden: boolean;
  isRequired: boolean;
  // sort position in collection
  sortPosition: number;
  translations: FieldTranslationInterface[];
  note: string | null;
  // is that field is ID
  readonly isPrimaryKey: boolean;
  isUnique: boolean;
  isNullable: boolean;
  schema: _VTI_TYPE_FieldInfoSchema | null;
  validation: _VTI_TYPE_FieldInfoMetaValidation;
  special: any;
  conditions: Condition[] | null;
}
interface _VTI_TYPE_FieldInfoInterface {
  readonly id: number;
  readonly name: string;
  readonly type: string;
  readonly collectionName: string;
  readonly meta: _VTI_TYPE_FieldInfoMetadataInterface;
}
interface _VTI_TYPE_FieldInterface {
  readonly info: _VTI_TYPE_FieldInfoInterface;
  readonly data: any;
  readonly label: string;
  readonly isDirty: boolean;
  setData(data: any): this;
  setDirty(): this;
  setClean(): this;
}
interface FieldInterfaceEmits {
  (
    e: FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA,
    data: FieldDataUpdateEmitPayload,
  ): void;
}
interface FieldFormInterfaceProps {
  collection: _VTI_TYPE_CollectionInterface;
  item: _VTI_TYPE_ItemInterface;
  field: _VTI_TYPE_FieldInterface;
}
  import { computed } from 'vue'
  import { useRouter } from 'vue-router'
  import difference from 'lodash/difference'
  import isArray from 'lodash/isArray'
  import PMessage from 'primevue/message'
  import PTreeSelect from 'primevue/treeselect'
  import { TreeNode } from 'primevue/tree'
  import { useRelationsStore } from '~/stores/relations'
  import { useRelationM2M } from '~/api/relations/composables/useRelationM2M'
  import { useRelationMultiple } from '~/api/relations/composables/useRelationMultiple'
  import ItemInterface from '~/api/items/entities/ItemInterface'
  import CollectionInterface from '~/api/collections/entities/CollectionInterface'
  import { QueryMany } from '~/api/data-queries/types'
  import RelationInterface from '~/api/relations/entity/RelationInterface'
  import { FieldManyData, defineFieldManyData, addRelatedPrimaryKeyToFields } from '~/entities/field'
  import { adjustFieldsForDisplays, getFieldsFromTemplate } from '~/api/field-displays/utils'
  import { routeToItem } from '~/api/items/utils/navigators'
  import { canNavigateToRelationalItem } from '~/api/collections/utils/availability'
  import MultiSelect from '~/components/MultiSelect/MultiSelect.vue'
  import { RenderTemplate } from '~/entities/render-template'
  import { createTextFromDisplayTemplate } from '~/entities/render-template'
  import { useDropdownItemsController } from '~/service/dropdown-items/composables/useDropdownItemsController'
  import { TreeNodeCheckboxData } from '~/service/treeview/types'
  import { defineTreeNode, defineTreeNodeCheckboxData } from '~/service/treeview/defines'
  import { FieldInterfaceEmitId, defineEmitUpdateItemFieldDataPayload } from '../../emits'
  
  import Button from '~/shared/ui/Button'
  const props = defineProps<FieldFormInterfaceProps>();
  const emit = defineEmits<FieldInterfaceEmits>();
  const router = useRouter();
  type TreeModelValue = {
    [key: string | number]: { checked: boolean; partialChecked: boolean };
  };
  type LinearModelValue = (string | number)[];
  const { relationInfo, relatedCollection } = useRelationM2M(
    computed(() => props.collection.id),
    computed(() => props.field.info),
  );
  const handleUpdateManyData = (
    event: TreeModelValue | LinearModelValue,
  ): FieldManyData => {
    const newData = event;
    const currentJunctionItemIds = (props.field.data as FieldManyData)
      .currentJunctionItemIds;
    /**
     * @note start shared functional
     */
    const relatedCollectionPrimaryFieldName = relatedCollection.value?.fieldsInfo.find(
      (fieldInfo) => fieldInfo.meta.isPrimaryKey,
    )?.name;
    const relatedItemIdExpression = `${relationInfo.value?.fieldName}.${relatedCollectionPrimaryFieldName}`;
    /**
     * @note end shared functional
     */
    const newDataJunctionItems = initialJunctionItems.value.filter((junctionItem) => {
      const relatedItemId = junctionItem.getFieldDataByExpression(
        relatedItemIdExpression,
      );
      if (newData instanceof Array) {
        return relatedItemId !== undefined && newData.includes(relatedItemId);
      }
      return relatedItemId !== undefined && Object.keys(newData).includes(relatedItemId);
    });
    const newDataJunctionItemIds = newDataJunctionItems.map((item) => item.id);
    const newDataJunctionItemRelatedIds = newDataJunctionItems.map((item) =>
      item.getFieldDataByExpression(relatedItemIdExpression),
    );
    const junctionItemIdsForRemove = difference(
      currentJunctionItemIds,
      newDataJunctionItemIds,
    );
    const updatedCurrentJunctionItemIds: (string | number)[] = newDataJunctionItemIds;
    const relatedItemIdsForCreateJunctionRelation = difference(
      newData instanceof Array ? newData : Object.keys(newData),
      newDataJunctionItemRelatedIds,
    );
    return defineFieldManyData({
      currentJunctionItemIds: updatedCurrentJunctionItemIds,
      newRelatedItemIds: relatedItemIdsForCreateJunctionRelation,
      removeJunctionItemIds: junctionItemIdsForRemove,
      create: [],
    });
  };
  const onUpdateModelValue = (event: TreeModelValue | LinearModelValue) => {
    const newData = handleUpdateManyData(event);
    emit(
      FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA,
      defineEmitUpdateItemFieldDataPayload({
        collectionName: props.collection.id,
        fieldName: props.field.info.name,
        updatedData: newData,
      }),
    );
  };
  const {
    items: relatedCollectionItems,
    options: stableOptions,
    search,
    filters,
    isLoading: isRelatedItemsLoading,
    virtualScrollerOptions,
    dropdownEventListeners,
    updateItems,
    searchEventListeners,
  } = useDropdownItemsController(
    computed(() => relatedCollection.value),
    computed(() => props.field.info),
  );
  const renderTemplate = computed(() => {
    if (!relationInfo.value) return null;
    if (props.field.info.meta.options.template)
      return props.field.info.meta.options.template;
    if (relationInfo.value.junctionCollection?.meta.displayTemplate)
      return relationInfo.value.junctionCollection.meta.displayTemplate;
    let relatedDisplayTemplate = relatedCollection.value?.meta.displayTemplate;
    if (relatedDisplayTemplate) {
      const regex = /({{.*?}})/g;
      const parts = relatedDisplayTemplate.split(regex).filter((p) => p);
      for (const part of parts) {
        if (part.startsWith("{{") === false) continue;
        const key = part.replace(/{{/g, "").replace(/}}/g, "").trim();
        const newPart = `{{${relationInfo.value.fieldName}.${key}}}`;
        relatedDisplayTemplate = relatedDisplayTemplate.replace(part, newPart);
      }
      return relatedDisplayTemplate;
    }
    const relatedCollectionPrimaryFieldName = relatedCollection.value?.fieldsInfo.find(
      (fieldInfo) => fieldInfo.meta.isPrimaryKey,
    )?.name;
    return `{{${relationInfo.value.fieldName}.${relatedCollectionPrimaryFieldName}}}`;
  });
  const fields = computed(() => {
    if (!relationInfo.value) return [];
    const displayFields = adjustFieldsForDisplays(
      getFieldsFromTemplate(renderTemplate.value ?? ""),
      relationInfo.value.junctionCollection as CollectionInterface,
    );
    return addRelatedPrimaryKeyToFields(
      relationInfo.value.junctionCollection as CollectionInterface,
      displayFields,
    );
  });
  const itemsRequestQuery = computed<QueryMany<unknown>>(() => {
    const query = {
      limit: -1,
      fields: fields.value || ["id"],
    };
    if (!relationInfo.value) return query;
    return query;
  });
  const { fetchedItems: initialJunctionItems } = useRelationMultiple(
    computed(() => props.item.id),
    relationInfo,
    itemsRequestQuery,
  );
  const relatedItemsHashMap = computed<Record<ItemInterface["id"], ItemInterface>>(() =>
    relatedCollectionItems.value.reduce<Record<ItemInterface["id"], ItemInterface>>(
      (hashMap, item) => {
        if (item.id in hashMap) return hashMap;
        hashMap[item.id] = item;
        return hashMap;
      },
      {},
    ),
  );
  const relatedRenderTemplate = computed(
    () =>
      relatedCollection.value?.meta?.displayTemplate ||
      props.collection.meta.displayTemplate ||
      `{{${props.collection.fieldsInfo.find((fieldInfo) => fieldInfo.meta.isPrimaryKey)
        ?.name}}}`,
  );
  const relatedPrimaryFieldExpression = computed<string>(() => {
    const junctionFieldName = relationInfo.value?.junctionField?.name;
    const relatedCollectionPrimaryFieldKey = relatedCollection.value?.fieldsInfo.find(
      (fieldInfo) => fieldInfo.meta.isPrimaryKey,
    )?.name;
    if (!junctionFieldName || !relatedCollectionPrimaryFieldKey) return "";
    return `${junctionFieldName}.${relatedCollectionPrimaryFieldKey}`;
  });
  const stableModelValue = computed<(string | number)[]>(() => {
    const fieldData = props.field.data as FieldManyData;
    const selectedJunctionItemIds = initialJunctionItems.value
      .filter((junctionItem) =>
        fieldData.currentJunctionItemIds.includes(junctionItem.id),
      )
      .map((junctionItem) =>
        junctionItem.getFieldDataByExpression(relatedPrimaryFieldExpression.value),
      )
      .filter((relatedItemId) => relatedItemId !== undefined);
    const selectedRelatedItemIds = new Set<string | number>(selectedJunctionItemIds);
    if (!!fieldData.newRelatedItemIds.length) {
      for (const relatedItemId of fieldData.newRelatedItemIds) {
        selectedRelatedItemIds.add(relatedItemId);
      }
    }
    return Array.from(selectedRelatedItemIds);
  });
  /**
   *
   * @note TreeSelect logic
   *
   */
  const relationsStore = useRelationsStore();
  const relationWithPotentialTreeView = computed<RelationInterface | undefined>(() =>
    relationsStore.relations.find((relation) => {
      return (
        relation.collectionName === relatedCollection.value?.id &&
        relation.relatedFieldName === relationInfo.value?.relatedFieldName
      );
    }),
  );
  const isRelatedToTreeView = computed<boolean>(() => {
    if (!relatedCollection.value) return false;
    const relationToPotentialTreeView = relationWithPotentialTreeView.value;
    if (!relationToPotentialTreeView) return false;
    const potentialTreeViewField = relatedCollection.value.fieldsInfo.find(
      (fieldInfo) => fieldInfo.name === relationToPotentialTreeView.meta?.oneField,
    );
    return potentialTreeViewField?.meta.interface === "list-o2m-tree-view";
  });
  /**
   * @note Non optimized algorithm. Excessive consumption of memory
   */
  const treeNodes = computed<TreeNode[]>(() => {
    if (!relatedCollectionItems.value || !relatedCollection.value) return [];
    const childrensField = relatedCollection.value.fieldsInfo.find(
      (fieldInfo) => fieldInfo.meta.interface === "list-o2m-tree-view",
    );
    if (!childrensField) return [];
    const childrenDataExpression = `${childrensField.name}.currentJunctionItemIds`;
    const parentFieldName = relationWithPotentialTreeView.value?.fieldName ?? undefined;
    if (parentFieldName === undefined) return [];
    const parentField = relatedCollection.value.fieldsInfo.find(
      (fieldInfo) => fieldInfo.name === parentFieldName,
    );
    if (!parentField) return [];
    const items = relatedCollectionItems.value;
    const itemsAsTreeNodesMap: { [key: string]: TreeNode } = {};
    for (const item of items) {
      const label =
        createTextFromDisplayTemplate(item, relatedRenderTemplate.value) ?? item.id;
      itemsAsTreeNodesMap[item.id] = defineTreeNode({
        key: item.id,
        label,
        data: item.id,
        children: [],
        meta: {
          parentId: item.getFieldDataByExpression(parentField.name),
          childrenIds: item.getFieldDataByExpression(childrenDataExpression),
        },
      });
    }
    const itemsTreeNodes = Object.values(itemsAsTreeNodesMap);
    for (let i = 0; i < itemsTreeNodes.length; i++) {
      const currentItem = itemsTreeNodes[i];
      const parentId = currentItem.meta.parentId;
      if (parentId === undefined) continue;
      itemsAsTreeNodesMap[parentId].children?.push(currentItem);
    }
    return itemsTreeNodes.filter((treeNode) => {
      return treeNode.meta.parentId === undefined;
    });
  });
  const treeViewModelValue = computed<{ [key: string]: TreeNodeCheckboxData }>(() => {
    const data: { [key: string]: TreeNodeCheckboxData } = {};
    for (const modelValue of stableModelValue.value) {
      data[modelValue] = defineTreeNodeCheckboxData({
        checked: true,
        partialChecked: false,
      });
    }
    return data;
  });
</script>

<style scoped></style>
