import { FieldInfoInterface, FieldInfoMetadataInterface } from "../lib/types";

export default class FieldInfo implements FieldInfoInterface {
  constructor(
    readonly id: number,
    readonly name: string,
    readonly type: string,
    readonly collectionName: string,
    readonly meta: FieldInfoMetadataInterface,
  ) {}
}

