<script setup lang="ts">
interface ButtonProps {
    as?: string | object;
  }
  import _ from 'lodash'
  import { twMerge } from 'tailwind-merge'
  import { PopoverButton as HeadlessPopoverButton } from '@headlessui/vue'
  import { useAttrs, computed } from 'vue'
  
  const { as } = withDefaults(defineProps<ButtonProps>(), {
    as: "div",
  });
  const attrs = useAttrs();
  const computedClass = computed(() =>
    twMerge(["cursor-pointer", typeof attrs.class === "string" && attrs.class]),
  );
</script>

<template>
  <HeadlessPopoverButton as="template">
    <component :is="as" :class="computedClass" v-bind="_.omit(attrs, 'class')">
      <slot></slot
    ></component>
  </HeadlessPopoverButton>
</template>

