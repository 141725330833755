import {
  mixed as createMixed,
  string as createString,
  number as createNumber,
  date as createDate,
  object as createObject,
  Schema,
  NumberSchema,
  StringSchema,
  DateSchema,
} from "yup";
import escapeRegExp from "lodash/escapeRegExp";
import type { FieldFilter } from "@directus/types";
import { QueryFilterOperatorsEnum } from "~/api/data-queries/types";
import { FilterSchemaCreateException } from "./exceptions";
import { CreateValidationSchemaOptions } from "../lib/types";

/**
 *
 * @throws {FilterSchemaCreateException}
 */
export function createYupFilterSchema(
  filter: FieldFilter | null,
  options: CreateValidationSchemaOptions,
): Schema {
  const targetFilter = filter || {};

  const schema: Record<string, Schema> = {};

  const fieldKey = Object.keys(targetFilter)[0];
  if (!fieldKey)
    throw new FilterSchemaCreateException(
      "filter doesn't contains field key",
      targetFilter,
    );

  const rule = Object.values(targetFilter)[0];
  if (!rule)
    throw new FilterSchemaCreateException("filter doesn't contains rule", targetFilter);

  const operator = Object.keys(rule)[0];

  if (!operator?.startsWith("_")) {
    schema[fieldKey] = createYupFilterSchema(rule as FieldFilter, options);
  } else {
    const valueForCompare = Object.values(rule)[0];

    if (operator === QueryFilterOperatorsEnum._eq) {
      const message = `$t:validation_equals ${valueForCompare}`;

      const numericValueForCompare = getNumericValue(valueForCompare);

      if (isNaN(valueForCompare)) {
        schema[fieldKey] = getSchema().equals([valueForCompare], message);
      } else {
        schema[fieldKey] = getSchema().equals(
          [valueForCompare, numericValueForCompare],
          message,
        );
      }
    }

    if (operator === QueryFilterOperatorsEnum._neq) {
      const message = `$t:validation_not_equals ${valueForCompare}`;

      const numericValueForCompare = getNumericValue(valueForCompare);

      if (isNaN(numericValueForCompare)) {
        schema[fieldKey] = getSchema().not([valueForCompare], message);
      } else {
        schema[fieldKey] = getSchema().not(
          [valueForCompare, numericValueForCompare],
          message,
        );
      }
    }

    if (operator === QueryFilterOperatorsEnum._contains) {
      const message = `$t:validation_should_contains ${valueForCompare}`;

      if (checkIsString(valueForCompare)) {
        schema[fieldKey] = getSchema().equals([true], message);
      } else {
        schema[fieldKey] = getSchema().test({
          name: operator,
          message,
          test: (value: string) => {
            return value.includes(valueForCompare);
          },
        });
      }
    }

    if (operator === QueryFilterOperatorsEnum._ncontains) {
      const message = `$t:validation_should_not_contains ${valueForCompare}`;

      if (checkIsString(valueForCompare)) {
        schema[fieldKey] = getSchema().equals([true], message);
      } else {
        schema[fieldKey] = getSchema().test({
          name: operator,
          message,
          test: (value: string) => {
            return !value.includes(valueForCompare);
          },
        });
      }
    }

    if (operator === QueryFilterOperatorsEnum._starts_with) {
      const message = `$t:validation_should_not_contains ${valueForCompare}`;

      if (checkIsString(valueForCompare)) {
        schema[fieldKey] = getSchema().equals([true], message);
      } else {
        schema[fieldKey] = getStringSchema().matches(
          new RegExp(`^${escapeRegExp(valueForCompare)}.*`),
          {
            name: operator,
            message,
          },
        );
      }
    }

    if (operator === QueryFilterOperatorsEnum._nstarts_with) {
      const message = `$t:validation_not_starts_with ${valueForCompare}`;

      if (checkIsString(valueForCompare)) {
        schema[fieldKey] = getSchema().equals([true], message);
      } else {
        schema[fieldKey] = getStringSchema().matches(
          new RegExp(`^(?!${escapeRegExp(valueForCompare)}).*`),
          {
            name: operator,
            message,
          },
        );
      }
    }

    if (operator === QueryFilterOperatorsEnum._ends_with) {
      const message = `$t:validation_ends_with ${valueForCompare}`;

      if (checkIsString(valueForCompare)) {
        schema[fieldKey] = getSchema().equals([true], message);
      } else {
        schema[fieldKey] = getStringSchema().matches(
          new RegExp(`${escapeRegExp(valueForCompare)}$`),
          {
            name: operator,
            message,
          },
        );
      }
    }

    if (operator === QueryFilterOperatorsEnum._nends_with) {
      const message = `$t:validation_not_ends_with ${valueForCompare}`;

      if (checkIsString(valueForCompare)) {
        schema[fieldKey] = getSchema().equals([true], message);
      } else {
        schema[fieldKey] = getStringSchema().matches(
          new RegExp(`^(?!.*${escapeRegExp(valueForCompare)}$).*$`),
          {
            name: operator,
            message,
          },
        );
      }
    }

    if (operator === QueryFilterOperatorsEnum._in) {
      const message = `$t:validation_one_of ${valueForCompare.join(", ")}`;

      return getSchema().equals(valueForCompare as Array<string | number>, message);
    }

    if (operator === QueryFilterOperatorsEnum._nin) {
      const message = `$t:validation_not_one_of ${valueForCompare.join(", ")}`;

      return getSchema().not(valueForCompare as Array<string | number>, message);
    }

    if (operator === QueryFilterOperatorsEnum._gt) {
      const message = `$t:validation_greater_than ${valueForCompare}`;

      // todo: это правило для _gte
      schema[fieldKey] = checkIsDate(valueForCompare)
        ? getDateSchema().min(valueForCompare, message)
        : getNumberSchema().min(valueForCompare, message);
    }

    if (operator === QueryFilterOperatorsEnum._gte) {
      const message = `$t:validation_greater_than_or_equal ${valueForCompare}`;

      schema[fieldKey] = checkIsDate(valueForCompare)
        ? getDateSchema().min(valueForCompare, message)
        : getNumberSchema().min(valueForCompare, message);
    }

    if (operator === QueryFilterOperatorsEnum._lt) {
      const message = `$t:validation_less_than ${valueForCompare}`;

      // todo: это правило для _lte
      schema[fieldKey] = checkIsDate(valueForCompare)
        ? getDateSchema().max(valueForCompare, message)
        : getNumberSchema().max(valueForCompare, message);
    }

    if (operator === QueryFilterOperatorsEnum._lte) {
      const message = `$t:validation_less_than_or_greater ${valueForCompare}`;

      schema[fieldKey] = checkIsDate(valueForCompare)
        ? getDateSchema().max(valueForCompare, message)
        : getNumberSchema().max(valueForCompare, message);
    }

    // todo: тесты
    if (operator === QueryFilterOperatorsEnum._null) {
      const message = "$t:validation_null";
      schema[fieldKey] = getSchema().equals([null], message);
    }

    if (operator === QueryFilterOperatorsEnum._nnull) {
      const message = "$t:validation_not_null";
      schema[fieldKey] = getSchema().not([null], message);
    }

    if (operator === QueryFilterOperatorsEnum._empty) {
      const message = "$t:validation_empty";
      return getSchema().equals([""], message);
    }

    if (operator === QueryFilterOperatorsEnum._nempty) {
      const message = "$t:validation_not_empty_value";
      return getSchema().not([""], message);
    }

    if (operator === QueryFilterOperatorsEnum._between) {
      const messageMin = "$t:validation_greater_than_or_equal";
      const messageMax = "$t:validation_less_than_or_greater";

      if (checkEveryIsNumber(valueForCompare)) {
        const data = [Number(valueForCompare[0]), Number(valueForCompare[1])];

        schema[fieldKey] = getNumberSchema()
          .min(data[0], `${messageMin} ${data[0]}`)
          .max(data[1], `${messageMax} ${data[1]}`);
      } else {
        schema[fieldKey] = getDateSchema()
          .min(valueForCompare[0], `${messageMin} ${valueForCompare[0]}`)
          .max(valueForCompare[1], `${messageMax} ${valueForCompare[1]}`);
      }
    }

    if (operator === QueryFilterOperatorsEnum._nbetween) {
      const messageMin = "$t:validation_greater_than_or_equal";
      const messageMax = "$t:validation_less_than_or_greater";

      if (checkEveryIsNumber(valueForCompare)) {
        const data = [Number(valueForCompare[0]), Number(valueForCompare[1])];

        schema[fieldKey] = getNumberSchema()
          .max(data[0], `${messageMax} ${data[0]}`)
          .min(data[1], `${messageMin} ${data[1]}`);
      } else {
        schema[fieldKey] = getDateSchema()
          .max(valueForCompare[0], `${messageMax} ${valueForCompare[0]}`)
          .min(valueForCompare[1], `${messageMin} ${valueForCompare[1]}`);
      }
    }

    if (operator === "_submitted") {
      schema[fieldKey] === getSchema().required();
    }

    if (operator === QueryFilterOperatorsEnum._regex) {
      const message = `$t:validation_equal_regexp ${valueForCompare}`;

      if (valueForCompare === null || valueForCompare === undefined) {
        schema[fieldKey] = getSchema().equals([true], message);
      } else {
        const isWrapped =
          typeof valueForCompare === "string"
            ? valueForCompare.startsWith("/") && valueForCompare.endsWith("/")
            : false;

        const regexp = new RegExp(
          isWrapped ? valueForCompare.slice(1, -1) : valueForCompare,
        );

        schema[fieldKey] = getStringSchema().matches(regexp, {
          name: operator,
          message,
        });
      }
    }

    function getSchema() {
      return schema[fieldKey] ?? createMixed().nullable();
    }

    function getStringSchema(): StringSchema {
      return (schema[fieldKey] as StringSchema) ?? createString();
    }

    function getNumberSchema(): NumberSchema {
      return (schema[fieldKey] as NumberSchema) ?? createNumber();
    }

    function getDateSchema(): DateSchema {
      return (schema[fieldKey] as DateSchema) ?? createDate();
    }
  }

  schema[fieldKey] = schema[fieldKey] ?? createMixed();

  if (options.requireAll) {
    schema[fieldKey] = schema[fieldKey]!.required();
  }

  return createObject(schema).unknown();
}

/**
 *
 * @returns {number} may be NaN
 */
function getNumericValue(value: any): number {
  return value === null || value === "" || value === true || value === false
    ? NaN
    : Number(value);
}

function checkIsString(value: any): boolean {
  return value === null || value === undefined || typeof value !== "string";
}

function checkIsDate(value: any): boolean {
  return value instanceof Date || Number.isNaN(Number(value));
}

function checkEveryIsNumber(value: any): boolean {
  return value.every((value: any) => {
    const val = Number(value instanceof Date ? NaN : value);
    return !Number.isNaN(val) && Math.abs(val) <= Number.MAX_SAFE_INTEGER;
  });
}

