export interface Revision {
  id: string;
  activity: Activity;
  collection: string;
  item: string;
  delta: { [key: string]: unknown };
  data: { [key: string]: unknown };
}

export interface Activity {
  id: string;
  action: ActivityAction;
  item: string | number;
  date: Date | null;
  user: ActivityUser;
}

export interface ActivityUser {
  id: string;
  first_name: string;
  last_name: string;
  avatar: {
    id: string;
    title: string;
  } | null;
}

export enum ActivityAction {
  UPDATE = "update",
  CREATE = "create",
}

export const defineRevision = (revision: Revision): Revision => {
  return revision;
};

