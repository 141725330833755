import { inject, injectable } from "inversify";
import { Item } from "@directus/types";
import {
  DirectusClient,
  RestClient,
  updateItems,
  updateItem,
} from "@directus/sdk";
import { HttpTransportRequestOptions } from "~/service/http/types";
import CollectionInterface from "~/api/collections/entities/CollectionInterface";
import { logger } from "~/service/logger/logger";
import ItemInterface from "../entities/ItemInterface";
import { castItemToDataStudioFormat } from "../casters/toDataStudioFormat";
import ItemWriteError from "../exceptions/ItemWriteError";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import { ItemID } from "../types/types";
import ItemsWriteGatewayInterface from "./ItemsWriteGatewayInterface";
import { createItemWithCoreCollections } from "~/service/data-studio/utils/createItemWithCoreCollections";

@injectable()
export default class ItemsWriteGateway implements ItemsWriteGatewayInterface {
  constructor(
    @inject(INJECT_SYMBOLS.DatastudioRestClient)
    private readonly _datastudioClient: DirectusClient<any> & RestClient<any>,
  ) {}

  /**
   *
   * @param collectionName
   * @param item
   * @param transportOptions
   * @throws {ItemWriteError}
   * @throws {Error}
   */
  async updateOne(
    collectionName: string,
    item: ItemInterface,
    transportOptions?: HttpTransportRequestOptions,
  ): Promise<unknown> {
    const transformedItem = castItemToDataStudioFormat(item);

    const response = await this._datastudioClient.request(
      updateItem(collectionName, item.id, transformedItem),
    );

    if (!response) {
      throw new ItemWriteError({
        message: `remote server write error`,
        data: {
          itemID: item.id,
          response,
        },
      });
    }

    return response;
  }

  /**
   *
   * @param collectionName
   * @param item
   * @param transportOptions
   * @returns {Promise<boolean>}
   * @throws {ItemWriteError}
   */
  async insertOne(
    collectionName: string,
    item: ItemInterface,
    transportOptions?: HttpTransportRequestOptions | undefined,
  ): Promise<unknown> {
    const transformedItem = castItemToDataStudioFormat(item);

    const response = await this._datastudioClient.request(
      createItemWithCoreCollections(collectionName, transformedItem),
    );

    if (!response) {
      throw new ItemWriteError({
        message: `server error`,
        data: {
          action: "create",
          response,
        },
      });
    }

    return response;
  }

  /**
   *
   * @throws {ItemWriteError}
   */
  async archiveMany(
    collection: CollectionInterface,
    itemIds: ItemID[],
  ): Promise<boolean> {
    const { id: collectionId } = collection;
    const { archiveField, archiveValue } = collection.meta;
    if (!archiveField || !archiveValue) {
      throw new ItemWriteError({
        data: {
          collectionId,
          itemIds,
        },
        message:
          "missed archive_field or archive_value settings. Please, configure collection properly.",
      });
    }

    const payload: Item = {
      [archiveField]: archiveValue,
    };

    try {
      const response = await this._datastudioClient.request(
        updateItems(collectionId, itemIds, payload),
      );

      return true;
    } catch (err) {
      logger().error(
        {
          err,
          collectionId,
          itemIds,
        },
        `unable archive many items.`,
      );

      throw err;
    }
  }
}

