<template>
  <div>
    <PDropdown
      optionValue="value"
      optionLabel="label"
      dataKey="value"
      :options="options"
      :virtualScrollerOptions="virtualScrollerOptions"
      :editable="false"
      :emptySelectionMessage="$t('field_select_empty_selection')"
      :emptyFilterMessage="$t('field_select_filter_empty_items')"
      :emptyMessage="$t('field_select_empty_items')"
      :tabindex="props.field.info.meta.sortPosition"
      :aria-label="props.field.label"
      :class="['w-full']"
      inputClass="form-select"
      :loading="isItemsLoading"
      :placeholder="
        isItemsLoading ? $t('items_is_loading') : $t('field_select_empty_selection')
      "
      :disabled="isItemsLoading || props.field.info.meta.isReadonly"
      :modelValue="props.field.data"
      @update:modelValue="(event: unknown) => $emit('update:modelValue', event)"
      v-on="dropdownEventListeners"
    >
      <template #header>
        <div class="p-dropdown-header">
          <div class="p-dropdown-filter-container">
            <input
              type="text"
              autocomplete="off"
              role="search"
              :value="search"
              :class="['p-dropdown-filter', 'p-inputtext', 'p-component']"
              v-on="searchEventListeners"
            />

            <span :class="['p-dropdown-filter-icon', 'pi pi-search']" />
          </div>
        </div>
      </template>

      <template #value="slotProps">
        <div
          v-if="!!itemsHashMap[slotProps.value] && !!slotProps.value"
          class="flex items-center justify-between"
        >
          <RenderTemplate
            :collectionName="props.collection.id"
            :item="itemsHashMap[slotProps.value]"
            :fieldInfo="props.field.info"
            :defaultTemplate="defaultRenderTemplate"
          />

          <Button
            v-if="canNavigateToRelationalItem(collection)"
            :variant="'soft-secondary'"
            class="ml-2"
            @click="routeToItem(collection.id, itemsHashMap[slotProps.value].id, router)"
          >
            <i class="fa-solid fa-arrow-up-right-from-square"></i>
          </Button>
        </div>

        <template v-else>
          <span>
            {{ slotProps.placeholder }}
          </span>
        </template>
      </template>

      <template #option="slotProps">
        <RenderTemplate
          v-if="!!itemsHashMap[slotProps.option.value]"
          :collectionName="props.collection.id"
          :item="itemsHashMap[slotProps.option.value]"
          :fieldInfo="props.field.info"
          :defaultTemplate="props.renderTemplate || defaultRenderTemplate"
        ></RenderTemplate>

        <template v-else>
          {{ slotProps.option.label }}
        </template>
      </template>
    </PDropdown>
  </div>
</template>

<script setup lang="ts">
type _VTI_TYPE_QueryLogicalFilterAnd = {
  [QueryFilterLogicalEnum._and]: QueryFilter<T>[];
}
type _VTI_TYPE_ValidationFilter = _VTI_TYPE_QueryLogicalFilterAnd<any>
type _VTI_TYPE_FieldFilter = {
  [K in keyof QuerySingleItem]?:
    | QueryFilterOperators<QuerySingleItem[K]>
    | FieldFilter<QuerySingleItem[K]>;
}
interface _VTI_TYPE_FieldInfoMetaValidation {
  message: string | null;
  rules: _VTI_TYPE_ValidationFilter | null;
}
interface _VTI_TYPE_FieldInfoSchema {
  defaultValue: unknown | null;
  maxLength: unknown | null;
  comment: unknown | null;
  numericPrecision: unknown | null;
  numericScale: unknown | null;
  hasAutoIncrement: boolean;
}
interface _VTI_TYPE_FieldInfoMetaOptions {
  choices?: FieldInfoMetaOptionsChoices[];
  folder?: string;
  title?: string;
  filter?: _VTI_TYPE_FieldFilter;
  template?: string; // Interface display template. Have priority on common display property
  masked?: boolean;
  placeholder?: string;
}
interface _VTI_TYPE_FieldInfoMetadataInterface {
  // type of field
  interface: string;
  // may contains filter expressions, etc...
  options: _VTI_TYPE_FieldInfoMetaOptions;
  // type of display value
  display: string | null;
  displayOptions: {
    template?: string;
    choices?: FieldDisplayOptionsChoice[];
  } | null;
  isReadonly: boolean;
  isHidden: boolean;
  isRequired: boolean;
  // sort position in collection
  sortPosition: number;
  translations: FieldTranslationInterface[];
  note: string | null;
  // is that field is ID
  readonly isPrimaryKey: boolean;
  isUnique: boolean;
  isNullable: boolean;
  schema: _VTI_TYPE_FieldInfoSchema | null;
  validation: _VTI_TYPE_FieldInfoMetaValidation;
  special: any;
  conditions: Condition[] | null;
}
interface _VTI_TYPE_FieldInfoInterface {
  readonly id: number;
  readonly name: string;
  readonly type: string;
  readonly collectionName: string;
  readonly meta: _VTI_TYPE_FieldInfoMetadataInterface;
}
interface _VTI_TYPE_FieldInterface {
  readonly info: _VTI_TYPE_FieldInfoInterface;
  readonly data: any;
  readonly label: string;
  readonly isDirty: boolean;
  setData(data: any): this;
  setDirty(): this;
  setClean(): this;
}
interface Props {
    collection: _VTI_TYPE_CollectionInterface;
    field: _VTI_TYPE_FieldInterface;
    renderTemplate?: string;
  }
  import { computed } from 'vue'
  import { useRouter } from 'vue-router'
  import PDropdown from 'primevue/dropdown'
  
  import ItemInterface from '~/api/items/entities/ItemInterface'
  import { routeToItem } from '~/api/items/utils/navigators'
  import { canNavigateToRelationalItem } from '~/api/collections/utils/availability'
  
  import { useDropdownItemsController } from '~/service/dropdown-items/composables/useDropdownItemsController'
  import { RenderTemplate } from '~/entities/render-template'
  import Button from '~/shared/ui/Button'
  
  const props = defineProps<Props>();
  const emits = defineEmits({
    "update:modelValue": null,
  });
  const router = useRouter();
  const {
    items,
    options,
    search,
    filters,
    isLoading: isItemsLoading,
    virtualScrollerOptions,
    updateItems,
    searchEventListeners,
    dropdownEventListeners,
  } = useDropdownItemsController(
    computed(() => props.collection),
    computed(() => props.field.info),
  );
  const itemsHashMap = computed<Record<ItemInterface["id"], ItemInterface>>(() =>
    items.value.reduce<Record<ItemInterface["id"], ItemInterface>>((hashMap, item) => {
      if (item.id in hashMap) return hashMap;
      hashMap[item.id] = item;
      return hashMap;
    }, {}),
  );
  const defaultRenderTemplate = computed<string>(() => {
    const fieldTemplate = props.field.info.meta.displayOptions?.template;
    if (!!fieldTemplate) return fieldTemplate;
    const collectionTemplate = props.collection.meta?.displayTemplate;
    const primaryField = props.collection.fieldsInfo.find(
      (fieldInfo) => fieldInfo.meta.isPrimaryKey,
    );
    return collectionTemplate || `{{ ${primaryField?.name} }}`;
  });
</script>

<style scoped></style>
entities/field/lib/FieldInterface
