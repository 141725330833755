import Settings from "../entity/Settings";
import SettingsInterface from "../entity/SettingsInterface";

export const castQuerySettingsToEntity = (settings: any): SettingsInterface => {
  return new Settings(
    settings.project_name,
    settings.auth_login_attempts,
    settings.default_language
  );
};
