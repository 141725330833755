import set from "lodash/set";
import { FilterMatchMode, FilterMatchModeOptions } from "primevue/api";
import { FieldFilter, QueryFilterOperatorsEnum } from "~/api/data-queries/types";
import { TableFilter } from "~/entities/datatable";
import { ColumnFilterMeta } from "~/entities/datatable-column-interfaces";

export const TableFilterMatchModeToApiMatch: {
  [key in FilterMatchModeOptions[keyof FilterMatchModeOptions]]: QueryFilterOperatorsEnum;
} = {
  [FilterMatchMode.EQUALS]: QueryFilterOperatorsEnum._eq,
  [FilterMatchMode.BETWEEN]: QueryFilterOperatorsEnum._between,
  [FilterMatchMode.CONTAINS]: QueryFilterOperatorsEnum._contains,
  [FilterMatchMode.DATE_AFTER]: QueryFilterOperatorsEnum._gt,
  [FilterMatchMode.DATE_BEFORE]: QueryFilterOperatorsEnum._lt,
  [FilterMatchMode.DATE_IS]: QueryFilterOperatorsEnum._eq,
  [FilterMatchMode.DATE_IS_NOT]: QueryFilterOperatorsEnum._neq,
  [FilterMatchMode.ENDS_WITH]: QueryFilterOperatorsEnum._ends_with,
  [FilterMatchMode.GREATER_THAN]: QueryFilterOperatorsEnum._gt,
  [FilterMatchMode.GREATER_THAN_OR_EQUAL_TO]: QueryFilterOperatorsEnum._gte,
  [FilterMatchMode.IN]: QueryFilterOperatorsEnum._in,
  [FilterMatchMode.LESS_THAN]: QueryFilterOperatorsEnum._lt,
  [FilterMatchMode.LESS_THAN_OR_EQUAL_TO]: QueryFilterOperatorsEnum._lte,
  [FilterMatchMode.NOT_CONTAINS]: QueryFilterOperatorsEnum._ncontains,
  [FilterMatchMode.NOT_EQUALS]: QueryFilterOperatorsEnum._neq,
  [FilterMatchMode.STARTS_WITH]: QueryFilterOperatorsEnum._starts_with,
};

export const isShouldExcludeFilter = (filter: TableFilter): boolean => {
  const filterData = filter.constraints[0].value;

  if (filterData === null || filterData === undefined) return true;
  if (filterData instanceof Array) return !filterData.length;
  if (filterData instanceof Object) return !Object.keys(filterData).length;
  if (typeof filterData === "string") return !filterData.length;

  return false;
};

export const transformTableFilterToApi = (
  filterName: string,
  filter: TableFilter,
): FieldFilter<unknown> => {
  if (isShouldExcludeFilter(filter)) return {};

  const filterConstraint = filter.constraints[0];
  const matchMode = filterConstraint.matchMode || FilterMatchMode.EQUALS;
  const tableMatchMode = TableFilterMatchModeToApiMatch[matchMode];

  const result = {};
  const keyForSetValue =
    (filterConstraint.meta?.[ColumnFilterMeta.API_FILTER_NAME] as string | undefined) ||
    filterName;

  set(result, keyForSetValue, {
    [tableMatchMode]: filterConstraint.value,
  });

  return result;
};

