import {
  DefaultType,
  FieldItem,
  FieldMetaTranslationType,
  FieldSchemaType,
} from "@directus/sdk";

import {
  FieldInfoInterface,
  FieldInterface,
  FieldInfoMetaOptions,
  FieldInfoMetadataInterface,
  FieldInfoSchema,
  FieldTranslationInterface,
} from "../lib/types";

import Field from "../model/Field";
import FieldInfo from "../model/FieldInfo";

import { useNuxtApp } from "#app";

/**
 *
 * @param translation
 * @returns
 */
const castFieldTranslation = (
  translation: FieldMetaTranslationType,
): FieldTranslationInterface => ({
  language: translation.language,
  translation: translation.translation,
});

/**
 *
 * @param schema
 * @returns
 */
const castFieldSchema = (schema: FieldSchemaType): FieldInfoSchema => ({
  comment: schema.comment,
  defaultValue: schema.default_value,
  hasAutoIncrement: schema.has_auto_increment,
  maxLength: schema.max_length,
  numericPrecision: schema.numeric_precision,
  numericScale: schema.numeric_scale,
});

const castFieldOptions = (options: DefaultType): FieldInfoMetaOptions => {
  return options;
};

const transformMetaValidation = (
  field: FieldItem,
): FieldInfoMetadataInterface["validation"] => {
  const message = field.meta.validation_message;
  const rules = field.meta.validation;

  return {
    message,
    rules,
  };
};

/**
 *
 * @param fieldData
 * @returns
 */
export const castFieldInfoFromDataStudioApiToEntity = (
  fieldData: FieldItem,
): FieldInfoInterface => {
  if (fieldData.meta === null) {
    return new FieldInfo(
      fieldData.field,
      fieldData.field,
      fieldData.type,
      fieldData.collection,
      {},
    );
  }

  const fieldMeta: FieldInfoMetadataInterface = {
    display: fieldData.meta.display,
    displayOptions: fieldData.meta.display_options,
    interface: fieldData.meta.interface,
    isHidden: fieldData.meta.hidden,
    isReadonly: fieldData.meta.readonly,
    isRequired: fieldData.meta.required,
    note: fieldData.meta.note,
    options:
      fieldData.meta.options === null ? {} : castFieldOptions(fieldData.meta.options),
    sortPosition: fieldData.meta.sort ?? 0,
    translations:
      fieldData.meta.translations?.map((translation) =>
        castFieldTranslation(translation),
      ) ?? [],
    isPrimaryKey: fieldData.schema?.is_primary_key ?? false,
    isNullable: fieldData.schema?.is_nullable ?? true,
    isUnique: fieldData.schema?.is_unique ?? false,
    schema: fieldData.schema === null ? null : castFieldSchema(fieldData.schema),
    validation: transformMetaValidation(fieldData),
    special: fieldData.meta.special,
    conditions: fieldData.meta.conditions,
  };

  return new FieldInfo(
    fieldData.meta.id,
    fieldData.field,
    fieldData.type,
    fieldData.collection,
    fieldMeta,
  );
};

/**
 *
 * @param data
 * @param fieldInfo
 * @returns
 */
export const defineField = (data: any, fieldInfo: FieldInfoInterface): FieldInterface => {
  const { $i18n } = useNuxtApp();

  return new Field(fieldInfo, data, $i18n.locale.value);
};

