<template>
  <div>
    <p-inline-message v-if="notice !== undefined" :severity="notice.severity">
      {{ notice.message }}
    </p-inline-message>

    <p-dropdown
      optionValue="value"
      optionLabel="label"
      dataKey="value"
      :options="options"
      :disabled="props.field.info.meta.isReadonly"
      :showClear="props.field.info.meta.isNullable"
      :inputId="props.field.info.name"
      :modelValue="props.field.data"
      :filter="true"
      :filterMatchMode="'contains'"
      :editable="false"
      :resetFilterOnHide="false"
      :tabindex="props.field.info.meta.sortPosition"
      :aria-label="props.field.label"
      :class="[
        'w-full',
        {
          '!border-danger': !isValid,
        },
      ]"
      :inputClass="['form-select']"
      v-on="eventListeners"
    >
    </p-dropdown>

    <ValidationMessage v-if="!!errors.length" :errors="errors" />
  </div>
</template>

<script setup lang="ts">
type _VTI_TYPE_QueryLogicalFilterAnd = {
  [QueryFilterLogicalEnum._and]: QueryFilter<T>[];
}
type _VTI_TYPE_ValidationFilter = _VTI_TYPE_QueryLogicalFilterAnd<any>
type _VTI_TYPE_FieldFilter = {
  [K in keyof QuerySingleItem]?:
    | QueryFilterOperators<QuerySingleItem[K]>
    | FieldFilter<QuerySingleItem[K]>;
}
interface _VTI_TYPE_FieldInfoMetaValidation {
  message: string | null;
  rules: _VTI_TYPE_ValidationFilter | null;
}
interface _VTI_TYPE_FieldInfoSchema {
  defaultValue: unknown | null;
  maxLength: unknown | null;
  comment: unknown | null;
  numericPrecision: unknown | null;
  numericScale: unknown | null;
  hasAutoIncrement: boolean;
}
interface _VTI_TYPE_FieldInfoMetaOptions {
  choices?: FieldInfoMetaOptionsChoices[];
  folder?: string;
  title?: string;
  filter?: _VTI_TYPE_FieldFilter;
  template?: string; // Interface display template. Have priority on common display property
  masked?: boolean;
  placeholder?: string;
}
interface _VTI_TYPE_FieldInfoMetadataInterface {
  // type of field
  interface: string;
  // may contains filter expressions, etc...
  options: _VTI_TYPE_FieldInfoMetaOptions;
  // type of display value
  display: string | null;
  displayOptions: {
    template?: string;
    choices?: FieldDisplayOptionsChoice[];
  } | null;
  isReadonly: boolean;
  isHidden: boolean;
  isRequired: boolean;
  // sort position in collection
  sortPosition: number;
  translations: FieldTranslationInterface[];
  note: string | null;
  // is that field is ID
  readonly isPrimaryKey: boolean;
  isUnique: boolean;
  isNullable: boolean;
  schema: _VTI_TYPE_FieldInfoSchema | null;
  validation: _VTI_TYPE_FieldInfoMetaValidation;
  special: any;
  conditions: Condition[] | null;
}
interface _VTI_TYPE_FieldInfoInterface {
  readonly id: number;
  readonly name: string;
  readonly type: string;
  readonly collectionName: string;
  readonly meta: _VTI_TYPE_FieldInfoMetadataInterface;
}
interface _VTI_TYPE_FieldInterface {
  readonly info: _VTI_TYPE_FieldInfoInterface;
  readonly data: any;
  readonly label: string;
  readonly isDirty: boolean;
  setData(data: any): this;
  setDirty(): this;
  setClean(): this;
}
interface FieldInterfaceEmits {
  (
    e: FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA,
    data: FieldDataUpdateEmitPayload,
  ): void;
}
interface FieldFormInterfaceProps {
  collection: _VTI_TYPE_CollectionInterface;
  item: _VTI_TYPE_ItemInterface;
  field: _VTI_TYPE_FieldInterface;
}
  import { computed, shallowRef, toRef } from 'vue'
  import { useI18n } from '#i18n'
  import PDropdown, { DropdownChangeEvent } from 'primevue/dropdown'
  import PInlineMessage from 'primevue/inlinemessage'
  import { logger } from '~/service/logger/logger'
  import { NoticeMessage, NoticeSeverityEnum } from '~/service/notice/types'
  import { defineNoticeMessage } from '~/service/notice/notices'
  import { useFieldValidation } from '~/entities/field'
  import ValidationMessage from '~/shared/ui/ValidationMessage'
  import { normalizeTranslationString } from '~/shared/lib/string'
  import { FieldInterfaceEmitId } from '../../emits'
  
  const props = defineProps<FieldFormInterfaceProps>();
  const emit = defineEmits<FieldInterfaceEmits>();
  const { t } = useI18n();
  const notice = shallowRef<NoticeMessage | undefined>(undefined);
  const options = computed(() => {
    if (!("choices" in props.field.info.meta.options)) {
      const errorLogMessage: string = `Not found required choices key in options. Returns array.`;
      const errorMessage: string = t("field_select_error_incorrect_choices");
      logger().error(
        {
          fieldName: props.field.info.name,
        },
        errorLogMessage,
      );
      notice.value = defineNoticeMessage({
        severity: NoticeSeverityEnum.ERROR,
        message: errorMessage,
      });
      return [];
    }
    return (
      props.field.info.meta.options.choices?.map((choice) => ({
        value: choice.value,
        label: normalizeTranslationString(choice.text),
      })) ?? []
    );
  });
  const { isValid, errors } = useFieldValidation(computed(() => props.field));
  const eventListeners = {
    change: (event: DropdownChangeEvent) => {
      emit(FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA, {
        collectionName: props.collection.id,
        fieldName: props.field.info.name,
        updatedData: event.value,
      });
    },
  };
</script>

<style scoped></style>
