<template>
  <nav
    class="w-[105px] xl:w-[260px] px-5 pb-16 overflow-x-hidden z-50 pt-32 -mt-4 hidden md:block"
  >
    <ul>
      <!-- BEGIN: First Child -->
      <template v-for="(menu, menuKey) in formattedMenu" :key="menuKey">
        <li>
          <Menu
            v-if="!!menu.childrens?.length || (menu.url && isUrlPermitted(menu.url))"
            :menu="menu"
            :formattedMenuState="[formattedMenu, setFormattedMenu]"
            level="first"
          ></Menu>

          <!-- BEGIN: Second Child -->
          <Transition @enter="enter" @leave="leave">
            <ul
              v-if="menu.childrens && menu.activeDropdown"
              :class="[
                'bg-white/[0.04] rounded-xl relative dark:bg-transparent',
                'before:content-[\'\'] before:block before:inset-0 before:bg-white/30 before:rounded-xl before:absolute before:z-[-1] before:dark:bg-darkmode-900/30',
                { block: menu.activeDropdown },
                { hidden: !menu.activeDropdown },
              ]"
            >
              <li v-for="(subMenu, subMenuKey) in menu.childrens" :key="subMenuKey">
                <Menu
                  v-if="
                    !!subMenu.childrens?.length ||
                    (subMenu.url && isUrlPermitted(subMenu.url))
                  "
                  :menu="subMenu"
                  :formattedMenuState="[formattedMenu, setFormattedMenu]"
                  level="second"
                ></Menu>

                <!-- BEGIN: Third Child -->
                <Transition @enter="enter" @leave="leave" v-if="subMenu.childrens">
                  <ul
                    v-if="subMenu.childrens && subMenu.activeDropdown"
                    :class="[
                      'bg-white/[0.04] rounded-xl relative dark:bg-transparent',
                      'before:content-[\'\'] before:block before:inset-0 before:bg-white/30 before:rounded-xl before:absolute before:z-[-1] before:dark:bg-darkmode-900/30',
                      { block: subMenu.activeDropdown },
                      { hidden: !subMenu.activeDropdown },
                    ]"
                  >
                    <li
                      v-for="(lastSubMenu, lastSubMenuKey) in subMenu.childrens"
                      :key="lastSubMenuKey"
                    >
                      <Menu
                        v-if="lastSubMenu.url && isUrlPermitted(lastSubMenu.url)"
                        :menu="lastSubMenu"
                        :formattedMenuState="[formattedMenu, setFormattedMenu]"
                        level="third"
                      ></Menu>
                    </li>
                  </ul>
                </Transition>
                <!-- END: Third Child -->
              </li>
            </ul>
          </Transition>
          <!-- END: Second Child -->
        </li>
      </template>
      <!-- END: First Child -->
    </ul>
  </nav>
</template>

<script setup lang="ts">
interface Props {
    menu: MenuDto[];
  }
  import { ref, watch } from '@vue/runtime-core'
  import { useRoute } from 'vue-router'
  import { FormattedMenu } from '~/api/menu/use-cases/FormattedMenu'
  import { MenuDto } from '~/api/menu/dto/Menu.dto'
  import { enter, leave, nestedMenu } from '~/layouts/SideMenu/side-menu'
  import { isUrlPermitted } from '~/router/utils/isUrlPermitted'
  import Menu from '~/layouts/SideMenu/Menu.vue'
  
  const props = defineProps<Props>();
  const route = useRoute();
  let formattedMenu = ref<FormattedMenu[]>([]);
  const setFormattedMenu = (computedFormattedMenu: FormattedMenu[]) => {
    Object.assign(formattedMenu.value, computedFormattedMenu);
  };
  watch(
    () => props.menu,
    () => {
      formattedMenu.value = nestedMenu(props.menu, route);
    },
    {
      immediate: true,
    },
  );
</script>

<style scoped></style>

