import { defineNuxtPlugin } from "#app";
import { H3Error } from "h3";
import { logger } from "~/service/logger/logger";
import { toaster } from "~/service/toaster";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error, context) => {
    logger().error({ error, context }, `unhandled error`);

    if (process.server) {
      return;
    }

    if (window !== undefined) {
      let message: string = "t:unhandled error";

      if (error instanceof Error) {
        // can't catch guard rejection in other way: https://github.com/vuejs/vue-router/issues/2833
        if (error.message.toLowerCase().includes("invalid navigation guard")) {
          return;
        }

        message = error.message;
      }

      if (error instanceof H3Error) {
        message = error.message;
      }

      toaster().error({
        message,
      });
    }
  };
});

