import type { DataTableFilterMetaData } from "primevue/datatable";
import { ColumnProps } from "primevue/column";
import { ColumnInterface } from "./interfaces";

export const defineColumnInterface = (
  columnInterface: ColumnInterface,
): ColumnInterface => columnInterface;

export const defineColumnFilter = (filter: Omit<DataTableFilterMetaData, "value">) => {
  return {
    columnFilter: filter,
  };
};

export const defineColumnProps = (props: ColumnProps): { columnProps: ColumnProps } => {
  return {
    columnProps: props,
  };
};

