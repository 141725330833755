import { useNuxtApp, createError } from "#app";
import { print } from "graphql";
import { defineStore } from "pinia";
import { castQuerySettingsToEntity } from "~/api/settings/casters/api";
import SettingsInterface from "~/api/settings/entity/SettingsInterface";
import { PROJECT_SETTINGS_QUERY } from "~/api/settings/graphql/query";
import { logger } from "~/service/logger/logger";
import { toaster } from "~/service/toaster";
import { useDataStudioClient } from "~/service/data-studio/composables/useDataStudioClient";

export const SETTINGS_STORE_ID: string = "settings";

interface State {
  projectSettings: SettingsInterface | undefined;
}

export const useSettingsStore = defineStore(SETTINGS_STORE_ID, {
  state: (): State => ({
    projectSettings: undefined,
  }),

  actions: {
    async initialize(): Promise<void> {
      const { $i18n } = useNuxtApp();
      const { client } = useDataStudioClient();

      try {
        const response = await client.query<{ settings: any }>(
          print(PROJECT_SETTINGS_QUERY),
          undefined,
          "system",
        );
        const settingsResponse = response.settings;

        const settingsEntity: SettingsInterface =
          castQuerySettingsToEntity(settingsResponse);

        this.$patch({
          projectSettings: settingsEntity,
        });
      } catch (err) {
        if (err instanceof Error) {
          logger().fatal(
            {
              err,
            },
            `Can't recieve Project Settings`,
          );
        }

        const errorMessage: string = $i18n.t("server_error_fetch_project_settings");

        toaster().error(errorMessage);

        throw createError({
          statusCode: 500,
          message: errorMessage,
          fatal: true,
        });
      }
    },

    async deinitilize(): Promise<void> {
      this.$reset();
    },
  },
});
