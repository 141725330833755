import { RestCommand, Query, createUser, createItem } from "@directus/sdk";

/**
 *
 * @throws {Error}
 */
export function createItemWithCoreCollections(
  collection: string,
  item: Record<string, any>,
  query?: Query<any, any>,
): RestCommand<any, any> {
  switch (collection) {
    case "directus_users":
      return createUser(item);

    default:
      if (collection.startsWith("directus_"))
        throw new Error(
          `missed handler for create system collection items '${collection}'. Please, define handler for this collection.`,
        );

      return createItem(collection, item, query);
  }
}

