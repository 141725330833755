import { useNuxtApp } from "#app";
import { FieldInterface } from "./types";

export const getFieldPlaceholder = (field: FieldInterface) => {
  const {
    $i18n: { t },
  } = useNuxtApp();

  const placeholder = field.info.meta.options.placeholder;

  if (!placeholder) {
    return t(field.info.name);
  }

  return placeholder;
};

