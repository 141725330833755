import { ZoneContextManager } from "@opentelemetry/context-zone";
import { registerInstrumentations } from "@opentelemetry/instrumentation";
import { getWebAutoInstrumentations } from "@opentelemetry/auto-instrumentations-web";
import { WebTracerProvider } from "@opentelemetry/sdk-trace-web";
import {
  BatchSpanProcessor,
  ConsoleSpanExporter,
  SimpleSpanProcessor,
} from "@opentelemetry/sdk-trace-base";
import { defineNuxtPlugin, useAppConfig, useRuntimeConfig } from "#app";
import { Resource } from "@opentelemetry/resources";
import { SemanticResourceAttributes } from "@opentelemetry/semantic-conventions";
import { B3Propagator } from "@opentelemetry/propagator-b3";
import { OTLPTraceExporter } from "@opentelemetry/exporter-trace-otlp-http";

export default defineNuxtPlugin((nuxtApp) => {
  const { appVersion, appName } = useAppConfig();
  const runtimeConfig = useRuntimeConfig();

  const OtlpEndpointUrl = runtimeConfig.public.OtlpEndpointUrl;
  const OtlpExportingEnabled = runtimeConfig.public.OtlpExportingEnabled;

  const provider = new WebTracerProvider({
    resource: new Resource({
      [SemanticResourceAttributes.SERVICE_NAME]: appName,
      [SemanticResourceAttributes.SERVICE_VERSION]: appVersion,
    }),
  });

  if (process.dev) {
    provider.addSpanProcessor(new SimpleSpanProcessor(new ConsoleSpanExporter()));
  }

  if (OtlpExportingEnabled) {
    provider.addSpanProcessor(
      new BatchSpanProcessor(
        new OTLPTraceExporter({
          url: OtlpEndpointUrl,
        }),
        {
          maxQueueSize: 100,
          maxExportBatchSize: 10,
          scheduledDelayMillis: 500,
          exportTimeoutMillis: 10000,
        },
      ),
    );
  }

  provider.register({
    contextManager: new ZoneContextManager(),
    propagator: new B3Propagator(),
  });

  registerInstrumentations({
    instrumentations: [
      getWebAutoInstrumentations({
        "@opentelemetry/instrumentation-user-interaction": {
          enabled: false,
        },
      }),
    ],
  });
});
