import { inject, injectable } from "inversify";
import { print } from "graphql";
import { GraphqlClient } from "@directus/sdk";
import { logger } from "~/service/logger/logger";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import { Revision } from "../defines/defineRevision";
import { REVISIONS_ITEM_QUERY } from "../graphql/queries";
import { transformRevisionResponseToEntity } from "../transforms/datastudio";
import ItemRevisionQueryGatewayInterface from "./ItemRevisionQueryGatewayInterface";

@injectable()
export default class ItemRevisionQueryGateway
  implements ItemRevisionQueryGatewayInterface
{
  constructor(
    @inject(INJECT_SYMBOLS.DatastudioGraphqlClient)
    private _graphqlClient: GraphqlClient<any>,
  ) {}

  async getAllForSingle(
    collectionName: string | number,
    itemId: string | number,
    limit: number = -1,
  ): Promise<Revision[]> {
    try {
      const response = await this._graphqlClient.query<{ revisions: any[] }>(
        print(REVISIONS_ITEM_QUERY),
        {
          collection: collectionName,
          itemId: String(itemId),
          limit,
        },
        "system",
      );

      return response.revisions.map((revision: any) =>
        transformRevisionResponseToEntity(revision),
      );
    } catch (err) {
      logger().error({ err }, `unabel to receive revisions for item`);
      throw err;
    }
  }
}

