import type { Component } from "vue";
import type { FilterMatchModeOptions } from "primevue/api";
import type { ColumnProps } from "primevue/column";
import type { DataTableFilterMetaData } from "primevue/datatable";
import type { Column } from "~/entities/datatable";

export interface ColumnInterface {
  id: string;
  component: Component | Record<string, Component>;
}

export interface ColumnComponentProps {
  column: Column;
}

export interface ColumnComponentExposed {
  columnProps?: ColumnProps;
  columnFilter?: Omit<DataTableFilterMetaData, "value">;
}

export interface ColumnFilterProps {
  collectionName: string;
  column: Column;
  filter: DataTableFilterMetaData;
  matchMode?: FilterMatchModeOptions[keyof FilterMatchModeOptions];
}

export interface UpdateFilterEmitPayload {
  filterName: string;
  data: unknown;
  filterMeta?: Record<ColumnFilterMeta, unknown>;
  immediate?: boolean;
}

export interface ColumnFilterEmits {
  (e: "update:filter", payload: UpdateFilterEmitPayload): void;
}

export enum ColumnFilterMeta {
  API_FILTER_NAME = "apiFilterName",
}

