import ItemInterface from "~/api/items/entities/ItemInterface";
import { getFieldExpressionFromDisplayTemplate } from "./get-field-expression-from-display-template";

export const createTextFromDisplayTemplate = (
  item: ItemInterface,
  displayTemplate: string,
): string | null => {
  const expression = getFieldExpressionFromDisplayTemplate(displayTemplate);

  const result = item.getFieldDataByExpression(expression as string);

  return result ?? null;
};

