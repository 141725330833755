import { CollectionMetaTranslationType } from "@directus/sdk";
import { Collection as DataStudioCollection } from "@directus/types";
import CollectionInterface, {
  CollectionMetaInterface,
  CollectionTranslationInterface,
} from "../entities/CollectionInterface";
import Collection from "../entities/Collection";

/**
 *
 * @param translation
 * @returns
 */
const castCollectionTranslationToMeta = (
  translation: CollectionMetaTranslationType,
): CollectionTranslationInterface => ({
  language: translation.language,
  translation: translation.translation,
});

/**
 *
 */
export const castCollectionFromDataStudioApiToEntity = (
  collectionData: DataStudioCollection,
): CollectionInterface => {
  const collectionMeta: CollectionMetaInterface = {
    color: collectionData.meta!.color,
    isHidden: collectionData.meta!.hidden,
    isSingletone: collectionData.meta!.singleton,
    note: collectionData.meta!.note,
    sortField: collectionData.meta!.sort_field,
    sortPosition: collectionData.meta!.sort,
    translations:
      collectionData.meta!.translations?.map((translation) =>
        castCollectionTranslationToMeta(translation),
      ) ?? [],
    displayTemplate: collectionData.meta!.display_template,
    archiveField: collectionData.meta!.archive_field,
    archiveAppFilter: collectionData.meta!.archive_app_filter,
    archiveValue: collectionData.meta!.archive_value,
    unarchiveValue: collectionData.meta!.unarchive_value,
  };

  return new Collection(collectionData.collection, collectionMeta);
};

