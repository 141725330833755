import { RelationMeta, Relation as DataStudioRelation } from "@directus/types";
import RelationInterface, { RelationMetaInterface } from "../entity/RelationInterface";
import Relation from "../entity/Relation";

/**
 *
 * @param relationMeta
 * @returns
 */
const castRelationMetaFromApiResponseToEntityMeta = (
  relationMeta: RelationMeta,
): RelationMetaInterface => ({
  id: relationMeta?.id ?? 0,
  manyCollection: relationMeta?.many_collection ?? "",
  manyField: relationMeta?.many_field ?? "",
  oneCollection: relationMeta.one_collection,
  oneField: relationMeta.one_field,
  oneCollectionField: relationMeta.one_collection_field,
  oneAllowedCollections: relationMeta.one_allowed_collections,
  oneDeselectAction: relationMeta.one_deselect_action,
  junctionField: relationMeta.junction_field,
  sortField: relationMeta.sort_field,
  isSystem: relationMeta?.is_system ?? null,
});

/**
 *
 * @param relation
 * @returns
 */
export const castRelationFromApiResponseToEntity = (
  relation: DataStudioRelation,
): RelationInterface => {
  return new Relation(
    relation.collection,
    relation.field,
    relation.related_collection,
    relation.schema?.foreign_key_column,
    relation.meta !== null
      ? castRelationMetaFromApiResponseToEntityMeta(relation.meta)
      : {},
  );
};

