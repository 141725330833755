import { PermissionAction, PermissionInterface } from "./PermissionInterface";

export class Permission implements PermissionInterface {
  roleId: string;
  collectionName: string;
  action: PermissionAction;
  fields: string[] | ["*"];
  validation: Record<string, any>;
  presets: Record<string, any>;
  isSystem: boolean;
}

