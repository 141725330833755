<template>
  <div class="files-container">
    <DropzoneUploader
      :urlUpload="uploadUrl"
      :existingFile="existingFiles"
      :maxFiles="maxFiles"
      :acceptedFiles="acceptedFiles"
      :isHideProgressBeforeUpload="true"
      :previewsContainer="refCustomPreviewsContainer"
      :previewTemplate="fileItemPreviewTemplate"
      :customOptions="dropzoneCustomOptions"
      :classDropzoneContainer="[dropzoneContainerClass]"
      :styleDropzoneContainer="dropzoneContainerStyles"
      :isDisabled="$props.field.info.meta.isReadonly"
      :thumbnailClickCb="onThumbnailClicked"
      ref="refDropzoneUploader"
      v-on="dropzoneListeners"
    >
      <template #customPreviewsContainer>
        <div
          ref="refCustomPreviewsContainer"
          class="dropzone dropzone--ivo-reboot grid grid-cols-6 gap-2"
          style="border: none"
        ></div>
      </template>
    </DropzoneUploader>

    <div v-if="!$props.field.info.meta.isReadonly" class="grid grid-cols-6 gap-2">
      <div class="flex justify-center items-center">
        <GridItemAddFile @click.prevent="onClickAddFile">
          <template #icon>
            <i class="fa-solid fa-add text-3xl"></i>
          </template>
        </GridItemAddFile>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
type _VTI_TYPE_QueryLogicalFilterAnd = {
  [QueryFilterLogicalEnum._and]: QueryFilter<T>[];
}
type _VTI_TYPE_ValidationFilter = _VTI_TYPE_QueryLogicalFilterAnd<any>
type _VTI_TYPE_FieldFilter = {
  [K in keyof QuerySingleItem]?:
    | QueryFilterOperators<QuerySingleItem[K]>
    | FieldFilter<QuerySingleItem[K]>;
}
interface _VTI_TYPE_FieldInfoMetaValidation {
  message: string | null;
  rules: _VTI_TYPE_ValidationFilter | null;
}
interface _VTI_TYPE_FieldInfoSchema {
  defaultValue: unknown | null;
  maxLength: unknown | null;
  comment: unknown | null;
  numericPrecision: unknown | null;
  numericScale: unknown | null;
  hasAutoIncrement: boolean;
}
interface _VTI_TYPE_FieldInfoMetaOptions {
  choices?: FieldInfoMetaOptionsChoices[];
  folder?: string;
  title?: string;
  filter?: _VTI_TYPE_FieldFilter;
  template?: string; // Interface display template. Have priority on common display property
  masked?: boolean;
  placeholder?: string;
}
interface _VTI_TYPE_FieldInfoMetadataInterface {
  // type of field
  interface: string;
  // may contains filter expressions, etc...
  options: _VTI_TYPE_FieldInfoMetaOptions;
  // type of display value
  display: string | null;
  displayOptions: {
    template?: string;
    choices?: FieldDisplayOptionsChoice[];
  } | null;
  isReadonly: boolean;
  isHidden: boolean;
  isRequired: boolean;
  // sort position in collection
  sortPosition: number;
  translations: FieldTranslationInterface[];
  note: string | null;
  // is that field is ID
  readonly isPrimaryKey: boolean;
  isUnique: boolean;
  isNullable: boolean;
  schema: _VTI_TYPE_FieldInfoSchema | null;
  validation: _VTI_TYPE_FieldInfoMetaValidation;
  special: any;
  conditions: Condition[] | null;
}
interface _VTI_TYPE_FieldInfoInterface {
  readonly id: number;
  readonly name: string;
  readonly type: string;
  readonly collectionName: string;
  readonly meta: _VTI_TYPE_FieldInfoMetadataInterface;
}
interface _VTI_TYPE_FieldInterface {
  readonly info: _VTI_TYPE_FieldInfoInterface;
  readonly data: any;
  readonly label: string;
  readonly isDirty: boolean;
  setData(data: any): this;
  setDirty(): this;
  setClean(): this;
}
interface FieldInterfaceEmits {
  (
    e: FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA,
    data: FieldDataUpdateEmitPayload,
  ): void;
}
interface FieldFormInterfaceProps {
  collection: _VTI_TYPE_CollectionInterface;
  item: _VTI_TYPE_ItemInterface;
  field: _VTI_TYPE_FieldInterface;
}
  import { useRuntimeConfig, useNuxtApp } from '#app'
  import { Component, computed, ref, watch } from 'vue'
  import { DropzoneFile } from 'dropzone'
  import PhotoSwipe, { SlideData } from 'photoswipe'
  import { isNil } from 'lodash'
  import { useRelationM2M } from '~/api/relations/composables/useRelationM2M'
  import { useRelationMultiple } from '~/api/relations/composables/useRelationMultiple'
  import { FieldManyData, defineFieldManyData } from '~/entities/field'
  import { castDropzoneQueuedFileToItemFile } from '~/api/files/casters/cast'
  import { QueryMany } from '~/api/data-queries/types'
  import { castItemFileToDropzoneFile } from '~/api/files/casters/cast'
  import { logger } from '~/service/logger/logger'
  import { useDropdownItemsController } from '~/service/dropdown-items/composables/useDropdownItemsController'
  import DropzoneUploader from '~/shared/dropzone_uploader/DropzoneUploader.vue'
  import FileGridItem from '~/shared/dropzone_ivo_theme/FileGridItem.vue'
  import { EmitDropzoneSuccess } from '~/shared/dropzone_uploader/types/EmitDropzoneSuccess'
  import { EmitDropzoneRemoved } from '~/shared/dropzone_uploader/types/EmitDropzoneRemoved'
  import { EmitDropzoneAddedFile } from '~/shared/dropzone_uploader/types/EmitDropzoneAddedFile'
  import { EmitDropzoneSending } from '~/shared/dropzone_uploader/types/EmitDropzoneSending'
  import GridItemAddFile from '~/shared/dropzone_ivo_theme/GridItemAddFile.vue'
  import { FieldInterfaceEmitId } from '../emits'
  
  import { toaster } from '~/service/toaster'
  import { NotyfEvent } from 'notyf'
  import { ToastSeverities } from '~/service/toaster/configs/toasts'
  import { castItemFromDataStudioApiToEntity } from '~/api/items/casters/castItemsFromDataStudioApi'
  import { useCollecitonsStore } from '~/stores/collections'
  import { shallowRef } from 'vue'
  import ItemInterface from '~/api/items/entities/ItemInterface'
  import { readSlideDataFromFile } from '~/service/lightbox/readers'
  import { useAuthStore } from '~/stores/auth'
  const props = defineProps<FieldFormInterfaceProps>();
  const emit = defineEmits<FieldInterfaceEmits>();
  const {
    $i18n: { t },
  } = useNuxtApp();
  const runtimeConfig = useRuntimeConfig();
  const authStore = useAuthStore();
  const collectionStore = useCollecitonsStore();
  const authToken = computed(() => authStore.accessToken);
  const { relationInfo, relatedCollection } = useRelationM2M(
    computed(() => props.collection.id),
    computed(() => props.field.info),
  );
  /**
   * @note Start Shared functional from ListM2M
   */
  const itemsRequestQuery = computed<QueryMany<unknown>>(() => {
    const query = {
      limit: -1,
      fields: ["id"], // todo: fields.value
    };
    if (!relationInfo.value) return query;
    return query;
  });
  const { fetchedItems: initialJunctionItems } = useRelationMultiple(
    computed(() => props.item.id),
    relationInfo,
    itemsRequestQuery,
  );
  const relatedPrimaryFieldExpression = computed<string>(() => {
    const junctionFieldName = relationInfo.value?.junctionField?.name;
    const relatedCollectionPrimaryFieldKey = relatedCollection.value?.fieldsInfo.find(
      (fieldInfo) => fieldInfo.meta.isPrimaryKey,
    )?.name;
    if (!junctionFieldName || !relatedCollectionPrimaryFieldKey) return "";
    return `${junctionFieldName}.${relatedCollectionPrimaryFieldKey}`;
  });
  const junctionPKExpression = computed<string>(() => {
    return relationInfo.value?.junctionCollection?.getPrimaryFieldInfo()?.name ?? "";
  });
  /**
   * @note End Shared functional from ListM2M
   */
  const {
    items: filesAvailableForChoose,
    options,
    search,
    filters,
    isLoading: isAvailableFilesLoading,
    virtualScrollerOptions,
    dropdownEventListeners,
    updateItems,
    searchEventListeners,
  } = useDropdownItemsController(
    computed(() => relatedCollection.value),
    computed(() => props.field.info),
  );
  // dropzone logic
  const uploadUrl = `${runtimeConfig.public.dataStudioApiUrl}/files`;
  const maxFiles = 10;
  const acceptedFiles = [".png", ".jpg", ".jpeg", ".heic", ".heif"];
  let finalizePromiseController = ref<any>(null);
  const existingFiles = shallowRef<DropzoneFile[]>([]);
  watch(
    () => [props.field.data, initialJunctionItems.value, filesAvailableForChoose.value],
    async ([newFieldData, newJunctionItems, newAvailableFiles]) => {
      if (!newFieldData) return;
      const result: DropzoneFile[] = [];
      const fieldData = newFieldData as FieldManyData;
      const selectedJunctionItemIds = fieldData.currentJunctionItemIds
        .map((itemId) =>
          (newJunctionItems as ItemInterface[]).find(
            (item) =>
              item.getFieldDataByExpression(junctionPKExpression.value) === itemId,
          ),
        )
        .filter(
          (junctionItem): junctionItem is ItemInterface => junctionItem !== undefined,
        )
        .map((junctionItem) =>
          junctionItem.getFieldDataByExpression(relatedPrimaryFieldExpression.value),
        )
        .filter((relatedItemId) => relatedItemId !== undefined);
      const uploadedFiles = (newAvailableFiles as ItemInterface[]).filter((item) =>
        selectedJunctionItemIds.includes(item.id),
      );
      for (const file of uploadedFiles) {
        const transformedFile = await castItemFileToDropzoneFile(file, authToken.value);
        if (transformedFile === undefined) {
          logger().warn(`unable to transform file to DropzoneFile. Result is undefined.`);
          continue;
        }
        result.push(transformedFile);
      }
      for (const file of fieldData.create) {
        const transformedFile = await castItemFileToDropzoneFile(file, authToken.value);
        if (transformedFile === undefined) {
          logger().warn(`unable to transform file to DropzoneFile. Result is undefined.`);
          continue;
        }
        result.push(transformedFile);
      }
      const uniqueFiles = result.reduce<DropzoneFile[]>((accumulator, dropzoneFile) => {
        const fileExists =
          accumulator.find((file) => file.upload?.uuid === dropzoneFile.upload?.uuid) !==
          undefined;
        if (fileExists) {
          return accumulator;
        }
        accumulator.push(dropzoneFile);
        return accumulator;
      }, []);
      existingFiles.value = uniqueFiles;
    },
    {
      immediate: true,
    },
  );
  const refCustomPreviewsContainer = ref<HTMLElement | null>(null);
  const fileItemPreviewTemplate: string = FileGridItem.template as string;
  const lightboxDatasources = shallowRef<SlideData[]>([]);
  watch(
    () => existingFiles.value,
    async (newFiles) => {
      try {
        const slideDatasources = await Promise.all(
          newFiles.map((file) => readSlideDataFromFile(file)),
        );
        lightboxDatasources.value = slideDatasources;
      } catch (err) {
        logger().error({ err }, `unable to processing some files to thumbnails`);
      }
    },
    {
      immediate: true,
    },
  );
  const onThumbnailClicked = (event: unknown, dropzoneFile: DropzoneFile) => {
    logger().debug({ event, dropzoneFile }, `Thumbnail clicked`);
    const fileUUID = dropzoneFile.upload?.uuid;
    const lightboxItemIndex = lightboxDatasources.value.findIndex(
      (item) => item.meta.uuid === fileUUID,
    );
    if (lightboxItemIndex === -1) {
      logger().warn(
        { uuid: fileUUID },
        `unable to render thumbnail. Not found slide datasource item with received uuid.`,
      );
      return;
    }
    new PhotoSwipe({
      index: lightboxItemIndex,
      dataSource: lightboxDatasources.value as SlideData[],
    }).init();
  };
  const dropzoneCustomOptions = {
    thumbnailMethod: "contain",
    dictRemoveFile: t("file_remove"),
    parallelUploads: 3,
  };
  const dropzoneContainerClass =
    "btn btn-m btn-full rounded-xl text-uppercase font-900 shadow-s bg-blue-dark";
  const dropzoneContainerStyles = {
    minHeight: "auto",
    height: `0`,
    padding: `0!important`,
    opacity: `0`,
  };
  const refDropzoneUploader = ref<Component | null>(null);
  const openFileExplorer = ref<Function | null>(null);
  watch(
    () => refDropzoneUploader.value?.fileController?.open,
    (newOpenFE, prevOpenFE) => {
      openFileExplorer.value = newOpenFE;
    },
    {
      deep: true,
    },
  );
  const processQueue = () => {
    const filesForUpload = refDropzoneUploader.value?.queueController.queuedFiles;
    if (!filesForUpload?.length) {
      logger().debug(
        {
          fieldName: props.field.info.name,
        },
        "no files to upload",
      );
      if (!isNil(finalizePromiseController.value)) {
        finalizePromiseController.value.resolve();
      }
    }
    /**
     * @todo промис не разрешится
     */
    refDropzoneUploader.value?.queueController.start();
  };
  const onClickAddFile = () => {
    if (isNil(openFileExplorer.value)) {
      return;
    }
    openFileExplorer.value();
  };
  const isFileUploaded = (fileUUID: string): boolean => {
    const fieldData = props.field.data as FieldManyData;
    const expression = relatedPrimaryFieldExpression.value;
    const fileItemJunctionID = initialJunctionItems.value.find(
      (junctionItem) => junctionItem.getFieldDataByExpression(expression) === fileUUID,
    )?.id;
    return fileItemJunctionID === undefined
      ? false
      : fieldData.currentJunctionItemIds.includes(fileItemJunctionID);
  };
  const dropzoneListeners = {
    "dropzone:success": (event: EmitDropzoneSuccess) => {
      logger().debug({ event }, "File uploaded successfully");
      if (!event.serverResponse?.data) {
        logger().warn(
          {
            response: event.serverResponse,
          },
          `file upload response returned incorrect data. Unable to parse item`,
        );
        return;
      }
      const fileCollection = collectionStore.getCollection("directus_files");
      if (fileCollection === undefined) {
        logger().error(
          `not found files collection for parse fields info for create file item`,
        );
        return;
      }
      const parsedItem = castItemFromDataStudioApiToEntity(
        event.serverResponse.data,
        fileCollection.fieldsInfo,
      );
      logger().debug({ parsedItem }, `parsed file item from server response`);
      const fieldData: FieldManyData = props.field.data;
      const uploadedFileIndex = fieldData.create.findIndex(
        (item) => item.id === event.file.upload?.uuid,
      );
      if (uploadedFileIndex === -1) {
        logger().error(
          { fileUUID: event.file.upload?.uuid },
          `not found uploaded file index for remove from create field data property`,
        );
        return;
      }
      const fieldDataCreateProperty = [...fieldData.create];
      fieldDataCreateProperty.splice(uploadedFileIndex, 1, parsedItem);
      emit(FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA, {
        collectionName: props.collection.id,
        fieldName: props.field.info.name,
        updatedData: defineFieldManyData({
          ...fieldData,
          create: fieldDataCreateProperty,
        }),
      });
    },
    "dropzone:removed": (event: EmitDropzoneRemoved) => {
      logger().debug({ event }, "file removed");
      const fieldData = props.field.data as FieldManyData;
      const fileUUID = event.file.upload?.uuid;
      if (isFileUploaded(fileUUID)) {
        // mark file item for remove from storage
        const expression = relatedPrimaryFieldExpression.value;
        const fileItemJunctionID = initialJunctionItems.value.find(
          (junctionItem) =>
            junctionItem.getFieldDataByExpression(expression) === fileUUID,
        )?.id;
        if (fileItemJunctionID === undefined) {
          logger().error(
            {
              fileUUID,
              junctionRelatedPKExpression: expression,
            },
            `unable to mark item file for remove. Not found Junction Item. File will not be remove from storage.`,
          );
          return;
        }
        const fileForRemoveCurrentIndex = fieldData.currentJunctionItemIds.findIndex(
          (id) => id === fileItemJunctionID,
        );
        if (fileForRemoveCurrentIndex === -1) {
          logger().error(
            {
              fileForRemoveCurrentIndex,
            },
            `unable to mark item file for remove. Not found junction item id index in current data.`,
          );
          return;
        }
        const updatedDataCurrent = [...fieldData.currentJunctionItemIds];
        updatedDataCurrent.splice(fileForRemoveCurrentIndex, 1);
        const updatedFieldData = defineFieldManyData({
          ...fieldData,
          currentJunctionItemIds: updatedDataCurrent,
          removeJunctionItemIds: fieldData.removeJunctionItemIds.includes(
            fileItemJunctionID,
          )
            ? fieldData.removeJunctionItemIds
            : fieldData.removeJunctionItemIds.concat([fileItemJunctionID]),
        });
        emit(FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA, {
          collectionName: props.collection.id,
          fieldName: props.field.info.name,
          updatedData: updatedFieldData,
        });
        return;
      }
      let itemIndexForUpload = fieldData.create.findIndex(
        (item) => item.getFieldDataByExpression("id") === fileUUID,
      );
      if (itemIndexForUpload === -1) {
        logger().error(
          {
            fileUUID,
            itemIndexForUpload,
          },
          `unable to remove not uploaded file from data. Item not found in data`,
        );
        return;
      }
      const updatedDataCreate = [...fieldData.create];
      updatedDataCreate.splice(itemIndexForUpload, 1);
      const updatedData = defineFieldManyData({
        ...fieldData,
        create: updatedDataCreate,
      });
      emit(FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA, {
        collectionName: props.collection.id,
        fieldName: props.field.info.name,
        updatedData,
      });
    },
    "dropzone:sending": (event: EmitDropzoneSending) => {
      logger().debug({ event }, "prepare file to upload");
      event.xhr.setRequestHeader("Authorization", `Bearer ${authToken.value}`);
    },
    "dropzone:addedFile": (event: EmitDropzoneAddedFile) => {
      logger().debug({ event }, "file added");
      if (event.addedFile.isMocked) {
        logger().debug(
          { file: event.addedFile },
          `Skip write file to field data. Is Mocked`,
        );
        return;
      }
      emit(FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA, {
        collectionName: props.collection.id,
        fieldName: props.field.info.name,
        updatedData: defineFieldManyData({
          ...props.field.data,
          create: props.field.data.create?.concat(
            castDropzoneQueuedFileToItemFile(event.addedFile),
          ),
        }),
      });
    },
    "dropzone:complete": (event: { file: DropzoneFile }) => {
      logger().debug({ event }, "file upload completed");
    },
    "dropzone:queueComplete": (event: unknown) => {
      logger().debug({ event }, "files queue completed");
      if (!isNil(finalizePromiseController.value)) {
        finalizePromiseController.value.resolve();
      }
    },
    "dropzone:error": (event: unknown) => {
      logger().error({ event }, "received dropzone error");
      toaster()
        .error("Не удалось обновить запись. Нажмите чтобы узнать подробнее")
        .on(NotyfEvent.Click, () => {
          toaster().open({
            type: ToastSeverities.WARNING,
            message: JSON.parse(event?.message?.errors ?? []),
          });
        });
      /**
       * @todo проверить event.message.code
       */
      if (!isNil(finalizePromiseController.value)) {
        finalizePromiseController.value.reject();
      }
    },
  };
  const finalize = async () => {
    logger().debug({ fieldName: props.field.info.name }, "start component finalization");
    const promiseResult = new Promise((resolve, reject) => {
      finalizePromiseController.value = { resolve, reject };
      processQueue();
    }).finally(() => {
      logger().debug(
        { fieldName: props.field.info.name },
        "finished component finalization",
      );
    });
    return promiseResult;
  };
  defineExpose({ finalize });
</script>

<style scoped></style>

entities/field/lib/defines

