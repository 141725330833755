import {
  SystemReaction,
  setContextFieldFilter,
  changeContextFieldMetaProperty,
  findContextItemPropertyData,
  findEventPayloadItemPropertyData,
} from "~/entities/system-reactions";
import { AppEvent, AppEvents, AppEventsPayload } from "~/shared/lib/app-event-bus";
import { QueryFilterOperatorsEnum } from "~/api/data-queries/types";

export default new SystemReaction({
  id: "set-declarant-space-options",

  scopes: [
    AppEvents.ITEM_DATA_CHANGED,
    AppEvents.BEFORE_RENDER_ITEM_EDIT_VIEW,
    AppEvents.BEFORE_RENDER_ITEM_CREATE_VIEW,
  ],

  priority: 0,

  matcher: (event, context) => {
    const e = event as AppEvent<
      | AppEvents.ITEM_DATA_CHANGED
      | AppEvents.BEFORE_RENDER_ITEM_EDIT_VIEW
      | AppEvents.BEFORE_RENDER_ITEM_CREATE_VIEW
    >;

    if (e.payload.collectionName !== "requests") return false;

    const sourceFieldName = "declarant_id";

    if (e.event === AppEvents.ITEM_DATA_CHANGED) {
      return (
        sourceFieldName in
        (e.payload as AppEventsPayload[AppEvents.ITEM_DATA_CHANGED]).data
      );
    }

    if (
      event.event === AppEvents.BEFORE_RENDER_ITEM_EDIT_VIEW ||
      event.event === AppEvents.BEFORE_RENDER_ITEM_CREATE_VIEW
    )
      return true;

    return false;
  },

  action: async (event, context) => {
    const isRenderEvent: boolean =
      event.event === AppEvents.BEFORE_RENDER_ITEM_EDIT_VIEW ||
      event.event === AppEvents.BEFORE_RENDER_ITEM_CREATE_VIEW;

    const sourceData: any = isRenderEvent
      ? findContextItemPropertyData({ fieldPath: "declarant_id" }, context)
      : findEventPayloadItemPropertyData(
          { fieldPath: "declarant_id" },
          event as AppEvent<AppEvents.ITEM_DATA_CHANGED>,
        );

    if (!sourceData) {
      // changeContextFieldMetaProperty(
      //   {
      //     fieldName: "declarant_address",
      //     metaKey: "isReadonly",
      //     value: true,
      //   },
      //   context,
      // );
      return;
    }

    // changeContextFieldMetaProperty(
    //   {
    //     fieldName: "declarant_address",
    //     metaKey: "isReadonly",
    //     value: false,
    //   },
    //   context,
    // );

    setContextFieldFilter(
      {
        fieldName: "declarant_address",
        filterPath: "premises_users.directus_users_id",
        filterRule: QueryFilterOperatorsEnum._eq,
        filterValue: sourceData,
      },
      context,
    );
  },
});

