import { defineStore } from "pinia";
import { UserInterface } from "../lib/types";

interface UserState {
  me: UserInterface | undefined;
}

export const UserStoreId: string = "userStore";

export const useUserStore = defineStore(UserStoreId, {
  state: (): UserState => ({
    me: undefined,
  }),

  actions: {
    async deinitialize(): Promise<void> {
      this.$reset();
    },

    isAdmin(): boolean | undefined {
      return (this.me?.role.isAdmin as boolean | undefined) || false;
    },
  },
});
