import gql from "graphql-tag";

export const ITEM_COMMENTS_QUERY = gql`
  query ItemComments($collection: String!, $itemId: String!) {
    comments: activity(
      filter: {
        collection: { _eq: $collection }
        item: { _eq: $itemId }
        action: { _eq: "comment" }
      }
    ) {
      id
      comment
      timestamp
      item
      collection
      user {
        id
      }
    }
  }
`;

