import dayjs from "dayjs";
import { Revision, defineRevision } from "../defines/defineRevision";

export const transformRevisionResponseToEntity = (revisionsResponse: any): Revision => {
  return defineRevision({
    id: revisionsResponse.id,
    activity: {
      id: revisionsResponse.activity.id,
      action: revisionsResponse.activity.action,
      item: revisionsResponse.activity.item,
      date:
        revisionsResponse.activity.timestamp !== null
          ? dayjs(revisionsResponse.activity.timestamp).toDate()
          : null,
      user: revisionsResponse.activity.user,
    },
    collection: revisionsResponse.collection,
    item: revisionsResponse.item,
    delta: revisionsResponse.delta,
    data: revisionsResponse.data,
  });
};

