import CollectionInterface from "../../../api/collections/entities/CollectionInterface";

/**
 * Adds the primary key field for any passed relational dot-notation field to the array of fields.
 * Useful for cases where you need to fetch a single piece of nested relational data, but also need
 * access to its primary key.
 *
 * @param currentCollection - Current root collection
 * @param fields - Array of (dot-notation) fields you want to augment
 *
 * @example
 * const collection = 'articles';
 * const fields = ['title', 'user.name'];
 *
 * addRelatedPrimaryKeyToFields(collection, fields);
 * // => ['title', 'user.name', 'user.id'];
 */
export function addRelatedPrimaryKeyToFields(
  currentCollection: CollectionInterface,
  fields: string[],
): string[] {
  if (!fields?.length) return [];

  const sanitizedFields: string[] = [];

  for (const fieldName of fields) {
    sanitizedFields.push(fieldName);

    if (!fieldName.includes(".")) continue;
    const fieldParts = fieldName.split(".");

    const field = currentCollection.fieldsInfo.find(
      (fieldInfo) => fieldInfo.name === fieldName,
    );

    if (!field) continue;

    const primaryKeyField = currentCollection.fieldsInfo.find(
      (fieldInfo) => fieldInfo.meta.isPrimaryKey,
    );

    const fieldToInclude =
      primaryKeyField && fieldParts.slice(0, -1).concat(primaryKeyField.name).join(".");

    if (fieldToInclude && !sanitizedFields.includes(fieldToInclude)) {
      sanitizedFields.push(fieldToInclude);
    }
  }

  return sanitizedFields;
}

