<template>
  <div
    :class="[
      'max-w-full md:max-w-none rounded-[30px] md:rounded-[35px_35px_0_0] min-w-0 min-h-screen bg-slate-100 flex-1 relative dark:bg-darkmode-700',
      'before:content-[\'\']',
    ]"
  >
    <slot></slot>
  </div>
</template>

<script setup lang="ts"></script>
