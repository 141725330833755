export enum ConnectionStatus {
  AVAILABLE = "available",
  UNAVAILABLE = "unavailable",
}

export interface ConnectionState {
  id: string;
  status: ConnectionStatus;
}

