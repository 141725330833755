import pino from "pino";

export const logger = () => {
  if (process.server) {
    return pino({
      enabled: true,
      level: "debug",
    });
  }

  return pino({
    enabled: true,
    level: "debug",
    browser: { disabled: process.server, asObject: true },
  });
};

