import {
  abortNavigation,
  defineNuxtRouteMiddleware,
  createError,
  useNuxtApp,
} from "#app";
import { toaster } from "~/service/toaster";
import { RouteParameterNames, RouteMetaFields } from "~/app/router.options";
import { usePermissionsStore } from "~/stores/permissions";
import { PermissionAction } from "~/api/permissions/entity/PermissionInterface";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { hasCollectionPermission } = usePermissionsStore();

  const toCollection = to.params?.[RouteParameterNames.COLLECTION_NAME] as string;
  const toAction = to.meta?.[RouteMetaFields.PERMISSION_ACTION] as PermissionAction;

  if (!toCollection || !toAction) return true;

  return hasCollectionPermission(toCollection, toAction) ? true : accessDenied();

  function accessDenied() {
    const { $i18n } = useNuxtApp();

    toaster().error($i18n.t("error.not_enough_permission.to_view"));
    return abortNavigation(
      createError({
        statusCode: 422,
        statusMessage: $i18n.t("error.not_enough_permission.to_view"),
      }),
    );
  }
});

