import { defineNuxtPlugin } from "#app";
import { MutationType } from "pinia";
import { logger } from "~/service/logger/logger";
import { useCollecitonsStore } from "~/stores/collections";

export default defineNuxtPlugin((nuxtApp) => {
  const collectionsStore = useCollecitonsStore();

  collectionsStore.$subscribe((mutation, state) => {
    if (mutation.type === MutationType.patchObject && !!mutation.payload.collections) {
      logger().debug(
        {
          collectionsNames: Object.keys(mutation.payload.collections),
        },
        `updated collections cache`,
      );
    }
  });
});

