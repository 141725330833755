<template>
  <DataNull v-if="displayValue === undefined || displayValue === null" />

  <div v-else>
    <span>{{ displayValue }}</span>
  </div>
</template>

<script setup lang="ts">
type _VTI_TYPE_QueryLogicalFilterAnd = {
  [QueryFilterLogicalEnum._and]: QueryFilter<T>[];
}
type _VTI_TYPE_ValidationFilter = _VTI_TYPE_QueryLogicalFilterAnd<any>
type _VTI_TYPE_FieldFilter = {
  [K in keyof QuerySingleItem]?:
    | QueryFilterOperators<QuerySingleItem[K]>
    | FieldFilter<QuerySingleItem[K]>;
}
interface _VTI_TYPE_FieldInfoMetaValidation {
  message: string | null;
  rules: _VTI_TYPE_ValidationFilter | null;
}
interface _VTI_TYPE_FieldInfoSchema {
  defaultValue: unknown | null;
  maxLength: unknown | null;
  comment: unknown | null;
  numericPrecision: unknown | null;
  numericScale: unknown | null;
  hasAutoIncrement: boolean;
}
interface _VTI_TYPE_FieldInfoMetaOptions {
  choices?: FieldInfoMetaOptionsChoices[];
  folder?: string;
  title?: string;
  filter?: _VTI_TYPE_FieldFilter;
  template?: string; // Interface display template. Have priority on common display property
  masked?: boolean;
  placeholder?: string;
}
interface _VTI_TYPE_FieldInfoMetadataInterface {
  // type of field
  interface: string;
  // may contains filter expressions, etc...
  options: _VTI_TYPE_FieldInfoMetaOptions;
  // type of display value
  display: string | null;
  displayOptions: {
    template?: string;
    choices?: FieldDisplayOptionsChoice[];
  } | null;
  isReadonly: boolean;
  isHidden: boolean;
  isRequired: boolean;
  // sort position in collection
  sortPosition: number;
  translations: FieldTranslationInterface[];
  note: string | null;
  // is that field is ID
  readonly isPrimaryKey: boolean;
  isUnique: boolean;
  isNullable: boolean;
  schema: _VTI_TYPE_FieldInfoSchema | null;
  validation: _VTI_TYPE_FieldInfoMetaValidation;
  special: any;
  conditions: Condition[] | null;
}
interface _VTI_TYPE_FieldInfoInterface {
  readonly id: number;
  readonly name: string;
  readonly type: string;
  readonly collectionName: string;
  readonly meta: _VTI_TYPE_FieldInfoMetadataInterface;
}
interface FieldDisplayProp {
  collectionName: string;
  item: _VTI_TYPE_ItemInterface;
  fieldKey: string;
  context?: {
    data?: ItemInterface;
  };
  fieldInfo?: _VTI_TYPE_FieldInfoInterface;
}
  import { computed } from 'vue'
  import { useI18n } from '#i18n'
  import { isNil } from 'lodash'
  import dompurify from 'dompurify'
  import { decode } from 'html-entities'
  
  import DataNull from '~/components/DataNull/data-null.vue'
  const { t, n } = useI18n();
  const props = defineProps<FieldDisplayProp>();
  const text = computed<string | null>(() =>
    props.item.getFieldDataByExpression(props.fieldKey),
  );
  const displayValue = computed<string | null>(() => {
    if (isNil(text.value)) return null;
    let value = String(text.value);
    if (value.startsWith("$t:")) return t(value.slice(3));
    // Strip from html entities
    value = dompurify.sanitize(value, { ALLOWED_TAGS: [] });
    value = decode(value);
    return `${value}`;
  });
</script>

<style scoped></style>
