<template>
  <div v-if="!!relationFieldInfo">
    <Skeleton v-if="isItemLoading" height="2rem" width="5rem"></Skeleton>

    <template v-else>
      <div
        v-if="!!item && !!relatedCollection"
        class="flex items-center justify-between mb-1"
      >
        <RenderTemplate
          :collection-name="relatedCollection.id || ''"
          :item="item"
          :field-info="relationFieldInfo"
          :default-template="defaultRenderTemplate"
          :context="props.context"
        />

        <Button
          v-if="canNavigateToRelationalItem(relatedCollection)"
          :variant="'soft-secondary'"
          class="ml-2"
          @click="routeToItem(relatedCollection.id, item.id, router)"
        >
          <i class="fa-solid fa-arrow-up-right-from-square"></i>
        </Button>
      </div>

      <DataNull v-else />
    </template>
  </div>
</template>

<script setup lang="ts">
type _VTI_TYPE_QueryLogicalFilterAnd = {
  [QueryFilterLogicalEnum._and]: QueryFilter<T>[];
}
type _VTI_TYPE_ValidationFilter = _VTI_TYPE_QueryLogicalFilterAnd<any>
type _VTI_TYPE_FieldFilter = {
  [K in keyof QuerySingleItem]?:
    | QueryFilterOperators<QuerySingleItem[K]>
    | FieldFilter<QuerySingleItem[K]>;
}
interface _VTI_TYPE_FieldInfoMetaValidation {
  message: string | null;
  rules: _VTI_TYPE_ValidationFilter | null;
}
interface _VTI_TYPE_FieldInfoSchema {
  defaultValue: unknown | null;
  maxLength: unknown | null;
  comment: unknown | null;
  numericPrecision: unknown | null;
  numericScale: unknown | null;
  hasAutoIncrement: boolean;
}
interface _VTI_TYPE_FieldInfoMetaOptions {
  choices?: FieldInfoMetaOptionsChoices[];
  folder?: string;
  title?: string;
  filter?: _VTI_TYPE_FieldFilter;
  template?: string; // Interface display template. Have priority on common display property
  masked?: boolean;
  placeholder?: string;
}
interface _VTI_TYPE_FieldInfoMetadataInterface {
  // type of field
  interface: string;
  // may contains filter expressions, etc...
  options: _VTI_TYPE_FieldInfoMetaOptions;
  // type of display value
  display: string | null;
  displayOptions: {
    template?: string;
    choices?: FieldDisplayOptionsChoice[];
  } | null;
  isReadonly: boolean;
  isHidden: boolean;
  isRequired: boolean;
  // sort position in collection
  sortPosition: number;
  translations: FieldTranslationInterface[];
  note: string | null;
  // is that field is ID
  readonly isPrimaryKey: boolean;
  isUnique: boolean;
  isNullable: boolean;
  schema: _VTI_TYPE_FieldInfoSchema | null;
  validation: _VTI_TYPE_FieldInfoMetaValidation;
  special: any;
  conditions: Condition[] | null;
}
interface _VTI_TYPE_FieldInfoInterface {
  readonly id: number;
  readonly name: string;
  readonly type: string;
  readonly collectionName: string;
  readonly meta: _VTI_TYPE_FieldInfoMetadataInterface;
}
interface FieldDisplayProp {
  collectionName: string;
  item: _VTI_TYPE_ItemInterface;
  fieldKey: string;
  context?: {
    data?: ItemInterface;
  };
  fieldInfo?: _VTI_TYPE_FieldInfoInterface;
}
  import { shallowRef, watch, computed, ref } from 'vue'
  import { useRouter } from 'vue-router'
  import { watchDebounced } from '@vueuse/shared'
  import Skeleton from 'primevue/skeleton'
  import { useFieldsStore } from '~/entities/field'
  import { useRelationM2O } from '~/api/relations/composables/useRelationsM2O'
  import { useLazyCollectionItem } from '~/api/items/composables/useLazyCollectionItem'
  import CollectionInterface from '~/api/collections/entities/CollectionInterface'
  import { routeToItem } from '~/api/items/utils/navigators'
  import { canNavigateToRelationalItem } from '~/api/collections/utils/availability'
  import ItemInterface from '~/api/items/entities/ItemInterface'
  import Button from '~/shared/ui/Button'
  import DataNull from '~/components/DataNull/data-null.vue'
  import { RenderTemplate } from '~/entities/render-template'
  import { transformNestedDataToItem } from '~/api/relations/utils/transform-nested-data-to-item'
  
  const props = defineProps<FieldDisplayProp>();
  const router = useRouter();
  const fieldsStore = useFieldsStore();
  const relationFieldInfo = computed(() =>
    fieldsStore.getField(props.collectionName, props.fieldKey),
  );
  const { relatedCollection } = useRelationM2O(
    computed(() => props.collectionName),
    relationFieldInfo,
  );
  const itemIdForFetch = computed<(string | number) | null>(() => {
    const itemId = props.item.getFieldDataByExpression(props.fieldKey);
    return !!itemId ? itemId : null;
  });
  const withRemoteFetch = ref<boolean>(false);
  const {
    load: loadItem,
    collectionItem: relatedItem,
    isLoading: isItemLoading,
  } = useLazyCollectionItem();
  watchDebounced(
    () => [relatedCollection.value, itemIdForFetch.value, withRemoteFetch.value],
    async ([collection, itemId, fetchEnabled]) => {
      if (!collection || !itemId || !fetchEnabled) return;
      await loadItem(
        (collection as CollectionInterface).id,
        String(itemId),
        (collection as CollectionInterface).fieldsInfo,
      );
    },
    {
      immediate: true,
      debounce: 500,
    },
  );
  watch(
    () => relatedItem.value,
    (newItem) => {
      item.value = newItem ?? undefined;
    },
  );
  const item = shallowRef<ItemInterface | undefined>(undefined);
  watch(
    () => props.context?.data,
    (newData) => {
      withRemoteFetch.value = false;
      if (!newData) {
        withRemoteFetch.value = true;
        return;
      }
      const relationalData = newData?.getFieldDataByExpression(props.fieldKey) || {};
      item.value = !!relationalData
        ? transformNestedDataToItem(relatedCollection.value!.id, relationalData)
        : undefined;
    },
    {
      immediate: true,
    },
  );
  const defaultRenderTemplate = computed<string>(() => {
    const fieldTemplate = relationFieldInfo.value?.meta.displayOptions?.template;
    if (!!fieldTemplate) return fieldTemplate;
    if (!relatedCollection.value) return "";
    const collectionTemplate = relatedCollection.value.meta?.displayTemplate;
    const primaryField = relatedCollection.value.getPrimaryFieldInfo();
    return collectionTemplate || `{{ ${primaryField?.name} }}`;
  });
</script>

<style scoped></style>

entities/field/lib/fields entities/field/lib/store

