import { FieldInfoInterface, FieldInterface, FieldHintingInterface } from "../lib/types";
import { iocContainer } from "~/inversify.config";
import { AppEventBus, AppEvents } from "~/shared/lib/app-event-bus";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";

export default class Field implements FieldInterface, FieldHintingInterface {
  private _isDirty: boolean = false;

  constructor(
    readonly info: FieldInfoInterface,
    private _data: any = null,
    private readonly localeId: string,
  ) {}

  get data(): any {
    return this._data;
  }

  get isDirty(): boolean {
    return this._isDirty;
  }

  setData(data: any): this {
    this._data = data;

    const eventBus = iocContainer.get<AppEventBus>(INJECT_SYMBOLS.AppEventBus);
    eventBus.dispatch({
      event: AppEvents.ITEM_DATA_CHANGED,
      payload: {
        collectionName: this.info.collectionName,
        data: {
          [this.info.name]: data,
        },
      },
    });

    return this;
  }

  setDirty(): this {
    this._isDirty = true;
    return this;
  }

  setClean(): this {
    this._isDirty = false;
    return this;
  }

  get label(): string {
    if (!this.info.meta.translations.length) return this.info.name;

    const foundTranslation = this.info.meta.translations.find(
      (translation) => translation.language === this.localeId,
    );

    return foundTranslation === undefined ? this.info.name : foundTranslation.translation;
  }

  get note(): string {
    return this.info.meta?.note || "";
  }
}

