import { ComputedRef, computed, watchEffect } from "@vue/runtime-core";
import isNil from "lodash/isNil";
import { useRelationsStore } from "~/stores/relations";
import { useLazyCollectionData } from "~/api/collections/composables/useLazyCollectionData";
import { FieldInfoInterface } from "~/entities/field";

export const useRelationM2O = (
  collectionName: ComputedRef<string>,
  fieldInfo: ComputedRef<FieldInfoInterface | undefined>,
) => {
  const relationsStore = useRelationsStore();

  const { collection: relatedCollection, load: loadCollection } = useLazyCollectionData();

  const relationInfo = computed(() => {
    if (!fieldInfo.value) return undefined;

    const relations = relationsStore.getRelationsByField(
      collectionName.value,
      fieldInfo.value.name,
    );

    return !!relations.length ? relations[0] : undefined;
  });

  watchEffect(async () => {
    if (relationInfo.value === undefined) return;

    if (isNil(relationInfo.value.relatedCollection)) return;

    await loadCollection(relationInfo.value.relatedCollection);
  });

  return {
    relationInfo,
    relatedCollection: computed(() => relatedCollection.value),
  };
};

