import { Item as DataStudioItem } from "@directus/types";
import { defineField, FieldInfoInterface, FieldInterface } from "~/entities/field";
import { getPresentationInterfaces } from "~/api/field-interfaces";
import { useRelationsStore } from "~/stores/relations";
import { isISOString } from "~/shared/lib/date";
import { defineFieldManyData } from "../../../entities/field/lib/defines";
import ItemInterface from "../entities/ItemInterface";
import Item from "../entities/Item";

/**
 *
 * @param itemData
 * @param fieldsInfo
 * @returns
 * @throws {Error}
 */
export const castItemFromDataStudioApiToEntity = (
  itemData: DataStudioItem,
  fieldsInfo: FieldInfoInterface[],
): ItemInterface => {
  const presentationInterfaces = getPresentationInterfaces();
  const relationsStore = useRelationsStore();

  const fields: FieldInterface[] = fieldsInfo.map((fieldInfo) => {
    if (presentationInterfaces.includes(fieldInfo.meta.interface)) {
      return defineField(null, fieldInfo);
    }

    if (!(fieldInfo.name in itemData)) {
      return defineField(null, fieldInfo);
    }

    const fieldData = itemData[fieldInfo.name];

    const hasRelationMany = relationsStore.relations.find(
      (relation) =>
        relation.meta?.oneField === fieldInfo.name &&
        relation.meta?.oneCollection === fieldInfo.collectionName,
    );

    if (hasRelationMany) {
      const data = defineFieldManyData({
        currentJunctionItemIds: fieldData as (string | number)[],
        newRelatedItemIds: [],
        removeJunctionItemIds: [],
        create: [],
      });
      return defineField(data, fieldInfo);
    }

    if (fieldInfo.meta.interface === "datetime") {
      if (!!fieldData && isISOString(fieldData, false)) {
        const data = !fieldData.endsWith("Z") ? `${fieldData}Z` : fieldData;
        return defineField(data, fieldInfo);
      }
    }

    return defineField(fieldData, fieldInfo);
  });

  return new Item(fields);
};

