import { defineNuxtRouteMiddleware, useNuxtApp } from "#app";
import { initialize } from "~/service/app/initialization";
import { useAppStore } from "~/stores/app";
import { useUserStore } from "~/entities/user";
import { usePermissionsStore } from "~/stores/permissions";
import { useCollecitonsStore } from "~/stores/collections";
import { useFieldsStore } from "~/entities/field";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const appStore = useAppStore();
  const userStore = useUserStore();

  if (!appStore.initialized && userStore.me !== undefined) {
    await initialize();
  }

  if (!appStore.initialized && userStore.me === undefined) {
    await publicInit();
  }

  async function publicInit() {
    const fieldsStore = useFieldsStore();
    const collectionsStore = useCollecitonsStore();
    const permissionsStore = usePermissionsStore();

    await collectionsStore.initialize();
    await fieldsStore.initialize();
    await permissionsStore.initializePublic();
  }
});
