import { DirectusUser } from "@directus/sdk";
import { User, UserData, UserInterface } from "~/entities/user";

export function castDirectusRestUserToUser(user: DirectusUser<any>): UserInterface {
  const propertyNamesForExclude = ["id", "last_page", "password", "tfa_secret", "token"];

  const userData: UserData = {} as UserData;
  for (const propertyName in user) {
    if (propertyNamesForExclude.includes(propertyName)) continue;
    userData[propertyName] = user[propertyName];
  }

  return new User(user.id, userData);
}

