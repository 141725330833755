import { Expose } from "class-transformer";
import { IsOptional, IsUUID } from "class-validator";

export class LayoutPresetDto {
  @Expose()
  id: string;

  @Expose()
  @IsOptional()
  @IsUUID()
  userId: string | null;

  @Expose()
  @IsOptional()
  @IsUUID()
  roleId: string | null;

  @Expose()
  collection: string;

  @Expose()
  query: {
    tabular?: {
      fields?: string[];
      page: number;
      limit: number;
    };
  };

  type: string;

  get isTabular(): boolean {
    return this.type === "tabular";
  }

  get typedQuery() {
    // @ts-ignore
    return this.query[this.type];
  }
}

