import { FieldInfoInterface, useFieldsStore } from "~/entities/field";
import ItemInterface from "~/api/items/entities/ItemInterface";
import { castItemFromDataStudioApiToEntity } from "~/api/items/casters/castItemsFromDataStudioApi";

export function transformNestedDataToItem(
  parentCollectionName: string,
  data: Record<string, any>,
): ItemInterface {
  const fieldsStore = useFieldsStore();

  const fieldsInfo = Object.keys(data)
    .map((fieldName) => fieldsStore.getField(parentCollectionName, fieldName))
    .filter((field): field is FieldInfoInterface => !!field);

  return castItemFromDataStudioApiToEntity(data, fieldsInfo);
}

