import { defineStore } from "pinia";
import { Item, Field } from "@directus/types";
import {
  castFieldInfoFromDataStudioApiToEntity,
  defineField,
  FieldInterface,
} from "~/entities/field";

import ItemInterface from "~/api/items/entities/ItemInterface";

export const TRANSFORMER_STORE_ID = "transformersStore";

export interface DatastudioGatewayItemTransformer {
  to: (item: ItemInterface) => Item;
  from: (item: Item) => ItemInterface;
}

export interface DatastudioGatewayItemFieldTransformer {
  to: (field: FieldInterface) => Item;
  from: (fieldInfo: Field, data: any) => FieldInterface;
}

export interface State {
  itemFieldInterfaces: { [key: string]: DatastudioGatewayItemFieldTransformer };
  defaultItemFieldTransformer: DatastudioGatewayItemFieldTransformer;
}

export const defineItemFieldTransformer = (
  transformer: DatastudioGatewayItemFieldTransformer,
): DatastudioGatewayItemFieldTransformer => {
  return transformer;
};

export const useTransformersDatastudioStore = defineStore(TRANSFORMER_STORE_ID, {
  state(): State {
    return {
      itemFieldInterfaces: {},

      defaultItemFieldTransformer: defineItemFieldTransformer({
        to: (field: FieldInterface): Item => {
          return {
            [field.info.name]: field.data,
          };
        },
        from: (fieldData: Field, data: any): FieldInterface => {
          const fieldInfo = castFieldInfoFromDataStudioApiToEntity(fieldData);
          return defineField(data, fieldInfo);
        },
      }),
    };
  },

  getters: {
    getItemFieldTransformer:
      (state) =>
      (fieldInterface: string): DatastudioGatewayItemFieldTransformer | undefined => {
        if (fieldInterface in state.itemFieldInterfaces) {
          return state.itemFieldInterfaces[fieldInterface];
        }

        return state.defaultItemFieldTransformer;
      },
  },

  actions: {
    registerForFieldInterface(
      fieldInterface: string,
      transformer: DatastudioGatewayItemFieldTransformer,
    ): boolean {
      if (fieldInterface in this.itemFieldInterfaces) {
        return false;
      }

      this.itemFieldInterfaces[fieldInterface] = transformer;
      return true;
    },
  },
});

