import { injectable } from "inversify";
import type { AuthenticationData, AuthenticationStorage } from "@directus/sdk";
import type { StorageAuthDriver } from "../types";

const REFRESH_TOKEN_KEY = "datastudio_refresh_token";
const ACCESS_TOKEN_KEY = "datastudio_access_token";
const EXPIRES_AT_KEY = "datastudio_auth_expires_at";
const EXPIRES_KEY = "datastudio_auth_expires";

const AuthKeyMap: Record<keyof AuthenticationData, string> = {
  access_token: ACCESS_TOKEN_KEY,
  refresh_token: REFRESH_TOKEN_KEY,
  expires_at: EXPIRES_AT_KEY,
  expires: EXPIRES_KEY,
};

@injectable()
export class LocalStorageAuthDriver implements StorageAuthDriver {
  public storage: AuthenticationStorage;

  constructor() {
    this.storage = {
      get() {
        const refreshToken = window.localStorage.getItem(REFRESH_TOKEN_KEY);
        const accessToken = window.localStorage.getItem(ACCESS_TOKEN_KEY);
        /**
         * @note expires - указывает на время жизни access_token
         */
        const expires = window.localStorage.getItem(EXPIRES_KEY);
        const expiresAt = window.localStorage.getItem(EXPIRES_AT_KEY);

        const response = {
          refresh_token: refreshToken,
          access_token: accessToken,
          expires: expires !== null ? parseInt(expires) : null,
          expires_at: expiresAt !== null ? parseInt(expiresAt) : null,
        };

        return Object.values(response).every((value) => value === null) ? null : response;
      },

      set(data) {
        // при первой загрузке приложения set вызывается с null, но по факту
        // в хранилище могут быть авторизационные данные. Если мы будем сбрасывать
        // данные в этом случае, то никогда не проведем авторизацию
        const isReset = !data || Object.values(data).every((value) => value === null);
        if (isReset) return;

        const dataForSet: Partial<AuthenticationData> = {};
        for (const key in data) {
          // @ts-expect-error
          if (data[key] !== undefined) {
            // @ts-expect-error
            dataForSet[key] = data[key];
          }
        }

        for (const key in dataForSet) {
          // @ts-expect-error
          const value = dataForSet[key];
          // @ts-expect-error
          setOrRemoveAuthData(AuthKeyMap[key], value);
        }
      },
    };
  }

  reset() {
    const resetData: AuthenticationData = {
      access_token: null,
      expires: null,
      expires_at: null,
      refresh_token: null,
    };

    for (const key in resetData) {
      // @ts-expect-error
      setOrRemoveAuthData(AuthKeyMap[key], resetData[key]);
    }
  }
}

function setOrRemoveAuthData(storageKey: string, value: string | null): void {
  if (value === null) {
    window.localStorage.removeItem(storageKey);
  } else {
    window.localStorage.setItem(storageKey, value);
  }
}

