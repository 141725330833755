import * as yup from "yup";
import { FieldInfoInterface } from "../lib/types";
import { cloneDeep, isNil } from "lodash";
import {
  LogicalFilter,
  QueryFilter,
  QueryFilterLogicalEnum,
} from "~/api/data-queries/types";
import { QueryFilterOperatorsEnum } from "~/api/data-queries/types";
import { logger } from "~/service/logger/logger";

type NodeData = {
  isGroup: boolean;
  isOperator: boolean;
  operator?: LogicalFilter<unknown> | QueryFilterOperatorsEnum;
  field?: string;
  rules?: QueryFilter<unknown>; // concrete validation rules
};

type Node = {
  data: NodeData;
  childrens: Node[] | null;
};

const defineNode = (node: Node): Node => {
  return node;
};

export const createNodesFromRawRules = (root: QueryFilter<unknown>): Node[] => {
  const nodes: Node[] = [];

  for (const rule in root) {
    const isGroup = rule in QueryFilterLogicalEnum;
    const isOperator = rule in QueryFilterOperatorsEnum;
    const isField = !isGroup && !isOperator;

    let node: Node | null = null;

    if (isOperator) {
      node = defineNode({
        data: {
          isGroup: false,
          isOperator: true,
          operator: rule,
          rules: root,
        },
        childrens: null,
      });
    }

    if (isField) {
      const ruleNodes = createNodesFromRawRules(root[rule]);
      node = defineNode({
        data: {
          isGroup: false,
          isOperator: false,
          field: rule,
        },
        childrens: ruleNodes,
      });
    }

    if (isGroup) {
      const nestedNodes = [];
      for (const nestedRules of root[rule]) {
        /**
         * @todo риск повторного входа в узлы
         */
        const nested = createNodesFromRawRules(nestedRules);
        nestedNodes.push(...nested);

        // nodes.push(...nestedNodes);
      }

      node = defineNode({
        data: {
          isGroup: true,
          isOperator: false,
          operator: rule,
        },
        childrens: nestedNodes,
      });
    }

    if (!isNil(node)) {
      nodes.push(node);
    }
  }

  return nodes;
};

export const createTestRuleFromNode = (node: Node): yup.TestConfig | false => {
  if (!node.data.isOperator) {
    return false;
  }

  const operator = node.data.operator;
  const operatorData = node.data.rules?.[operator];

  switch (operator) {
    case QueryFilterOperatorsEnum._nempty:
      return {
        name: QueryFilterOperatorsEnum._nempty,
        message: "$t:validation_not_empty_value",
        test: (value) => {
          if (typeof value === "string" || value instanceof Array) {
            return !!value.length;
          }

          if (value instanceof Object) {
            return !!Object.keys(value).length;
          }

          return value !== null && value !== undefined;
        },
      };

    case QueryFilterOperatorsEnum._regex:
      return {
        name: QueryFilterOperatorsEnum._regex,
        message: () => {
          return `$t:validation_equal_regexp ${operatorData}`;
        },
        test: (value: unknown) => {
          const regexp = new RegExp(operatorData, "g");

          if (typeof value === "string") {
            return regexp.test(value);
          }

          return true;
        },
      };

    case QueryFilterOperatorsEnum._contains:
      return {
        name: QueryFilterOperatorsEnum._contains,
        message: () => {
          return `$t:validation_should_contains ${operatorData}`;
        },
        test: (value: unknown) => {
          if (typeof value === "string") {
            return value.includes(operatorData);
          }

          if (typeof value === "number") {
            return value.toString().includes(operatorData);
          }

          return true;
        },
      };

    case QueryFilterOperatorsEnum._ncontains:
      return {
        name: QueryFilterOperatorsEnum._ncontains,
        message: () => {
          return `$t:validation_should_not_contains ${operatorData}`;
        },
        test: (value: unknown) => {
          if (typeof value === "string") {
            return !value.includes(operatorData);
          }

          if (typeof value === "number") {
            return !value.toString().includes(operatorData);
          }

          return true;
        },
      };

    case QueryFilterOperatorsEnum._icontains:
      return {
        name: QueryFilterOperatorsEnum._icontains,
        message: () => {
          return `$t:validation_should_insensitive_contains ${operatorData}`;
        },
        test: (value: unknown) => {
          if (typeof value === "string") {
            const regex = new RegExp(`${operatorData}`, "ig");
            return regex.test(value);
          }

          if (typeof value === "number") {
            const regex = new RegExp(`${operatorData}`, "ig");
            return regex.test(value.toString());
          }

          return true;
        },
      };

    case QueryFilterOperatorsEnum._starts_with:
      return {
        name: QueryFilterOperatorsEnum._starts_with,
        message: () => {
          return `$t:validation_starts_with ${operatorData}`;
        },
        test: (value: unknown) => {
          if (typeof value === "string") {
            return value.startsWith(operatorData);
          }

          if (typeof value === "number") {
            return value.toString().startsWith(operatorData);
          }

          return true;
        },
      };

    case QueryFilterOperatorsEnum._nstarts_with:
      return {
        name: QueryFilterOperatorsEnum._nstarts_with,
        message: () => {
          return `$t:validation_not_starts_with ${operatorData}`;
        },
        test: (value: unknown) => {
          if (typeof value === "string") {
            return !value.startsWith(operatorData);
          }

          if (typeof value === "number") {
            return !value.toString().startsWith(operatorData);
          }

          return true;
        },
      };

    case QueryFilterOperatorsEnum._ends_with:
      return {
        name: QueryFilterOperatorsEnum._ends_with,
        message: () => {
          return `$t:validation_ends_with ${operatorData}`;
        },
        test: (value: unknown) => {
          if (typeof value === "string") {
            return value.endsWith(operatorData);
          }

          if (typeof value === "number") {
            return value.toString().endsWith(operatorData);
          }

          return true;
        },
      };

    case QueryFilterOperatorsEnum._nends_with:
      return {
        name: QueryFilterOperatorsEnum._nends_with,
        message: () => {
          return `$t:validation_not_ends_with ${operatorData}`;
        },
        test: (value: unknown) => {
          if (typeof value === "string") {
            return !value.endsWith(operatorData);
          }

          if (typeof value === "number") {
            return !value.toString().endsWith(operatorData);
          }

          return true;
        },
      };

    case QueryFilterOperatorsEnum._eq:
      return {
        name: QueryFilterOperatorsEnum._eq,
        message: () => {
          return `$t:validation_equals ${operatorData}`;
        },
        test: (value: unknown) => {
          if (typeof value === "string") {
            return value === operatorData;
          }

          if (typeof value === "number") {
            return value.toString() === operatorData;
          }

          return true;
        },
      };

    case QueryFilterOperatorsEnum._neq:
      return {
        name: QueryFilterOperatorsEnum._neq,
        message: () => {
          return `$t:validation_not_equals ${operatorData}`;
        },
        test: (value: unknown) => {
          if (typeof value === "string") {
            return value !== operatorData;
          }

          if (typeof value === "number") {
            return value.toString() !== operatorData;
          }

          return true;
        },
      };

    case QueryFilterOperatorsEnum._empty:
      return {
        name: QueryFilterOperatorsEnum._empty,
        message: "$t:validation_empty",
        test: (value: unknown) => {
          if (value === null || value === undefined) {
            return true;
          }

          if (typeof value === "string") {
            return !value.length;
          }

          if (value instanceof Array) {
            return !value.length;
          }

          if (value instanceof Object) {
            return !Object.keys(value).length;
          }

          return false;
        },
      };

    case QueryFilterOperatorsEnum._null:
      return {
        name: QueryFilterOperatorsEnum._null,
        message: "$t:validation_null",
        test: (value: unknown) => {
          return value === null || value === undefined;
        },
      };

    case QueryFilterOperatorsEnum._nnull:
      return {
        name: QueryFilterOperatorsEnum._nnull,
        message: "$t:validation_not_null",
        test: (value: unknown) => {
          return value !== null;
        },
      };

    case QueryFilterOperatorsEnum._in:
      return {
        name: QueryFilterOperatorsEnum._in,
        message: () => {
          return `$t:validation_one_of ${operatorData.join(", ")}`;
        },
        test: (value: unknown) => {
          const dataForCheck = operatorData as string[];

          if (typeof value === "string") {
            return dataForCheck.includes(value);
          }

          if (typeof value === "number") {
            return dataForCheck.includes(value.toString());
          }

          return false;
        },
      };

    case QueryFilterOperatorsEnum._nin:
      return {
        name: QueryFilterOperatorsEnum._nin,
        message: () => {
          return `$t:validation_not_one_of ${operatorData.join(", ")}`;
        },
        test: (value: unknown) => {
          const dataForCheck = operatorData as string[];

          if (typeof value === "string") {
            return !dataForCheck.includes(value);
          }

          if (typeof value === "number") {
            return !dataForCheck.includes(value.toString());
          }

          return true;
        },
      };

    default:
      logger().warn({ operator, node }, `Not found validate transformation for operator`);
      return false;
  }
};

export const createSchemaFromNode = (node: Node, chain: yup.Schema): yup.Schema => {
  if (node.data.isGroup && node.data.operator === QueryFilterLogicalEnum._or) {
    return chain;
  }

  if (!!node.childrens?.length) {
    for (const child of node.childrens) {
      chain = createSchemaFromNode(child, chain);
    }
  }

  const testRuleConfig = createTestRuleFromNode(node);

  if (!testRuleConfig) {
    return chain;
  }

  return chain.test(testRuleConfig);
};

/**
 *
 * @deprecated Должен быть заменен с помощью createYupFilterSchema
 */
export const transformRulesToSchema = (
  fieldInfo: FieldInfoInterface,
): yup.MixedSchema => {
  const rules = cloneDeep(fieldInfo.meta.validation?.rules ?? {});

  const validationNodes = createNodesFromRawRules(rules);
  let schema = yup.mixed().nullable();

  for (const node of validationNodes) {
    schema = createSchemaFromNode(node, schema);
  }

  if (fieldInfo.meta.isRequired) {
    schema = schema.required("$t:validation_is_required");
  } else {
    schema = schema.notRequired();
  }

  //@ts-expect-error
  return schema;
};

