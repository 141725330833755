import { plainToInstance } from "class-transformer";
import { injectable } from "inversify";
import { LayoutPresetGraphqlResponseDto } from "../dto/layout-preset-graphql-response.dto";
import { LayoutPresetDto } from "../dto/LayoutPresetDto";

@injectable()
export class LayoutPresetsMapper {
  transformGraphqlToDto(responseDto: LayoutPresetGraphqlResponseDto): LayoutPresetDto {
    return plainToInstance(LayoutPresetDto, {
      id: responseDto.id,
      userId: responseDto.user?.id ?? null,
      roleId: responseDto.role?.id ?? null,
      collection: responseDto.collection,
      type: responseDto.layout || "tabular",
      query: responseDto.layout_query,
    });
  }
}

