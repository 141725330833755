export enum FieldInterfaceEmitId {
  UPDATE_ITEM_FIELD_DATA = "update:itemFieldData",
}

export type FieldDataUpdateEmitPayload = {
  collectionName: string;
  fieldName: string;
  updatedData: unknown;
};

export const defineEmitUpdateItemFieldDataPayload = (
  emit: FieldDataUpdateEmitPayload,
): FieldDataUpdateEmitPayload => {
  return emit;
};

export interface FieldInterfaceEmits {
  (
    e: FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA,
    data: FieldDataUpdateEmitPayload,
  ): void;
}

