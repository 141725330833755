<template>
  {{ displayData }}
</template>

<script setup lang="ts">
type _VTI_TYPE_QueryLogicalFilterAnd = {
  [QueryFilterLogicalEnum._and]: QueryFilter<T>[];
}
type _VTI_TYPE_ValidationFilter = _VTI_TYPE_QueryLogicalFilterAnd<any>
type _VTI_TYPE_FieldFilter = {
  [K in keyof QuerySingleItem]?:
    | QueryFilterOperators<QuerySingleItem[K]>
    | FieldFilter<QuerySingleItem[K]>;
}
interface _VTI_TYPE_FieldInfoMetaValidation {
  message: string | null;
  rules: _VTI_TYPE_ValidationFilter | null;
}
interface _VTI_TYPE_FieldInfoSchema {
  defaultValue: unknown | null;
  maxLength: unknown | null;
  comment: unknown | null;
  numericPrecision: unknown | null;
  numericScale: unknown | null;
  hasAutoIncrement: boolean;
}
interface _VTI_TYPE_FieldInfoMetaOptions {
  choices?: FieldInfoMetaOptionsChoices[];
  folder?: string;
  title?: string;
  filter?: _VTI_TYPE_FieldFilter;
  template?: string; // Interface display template. Have priority on common display property
  masked?: boolean;
  placeholder?: string;
}
interface _VTI_TYPE_FieldInfoMetadataInterface {
  // type of field
  interface: string;
  // may contains filter expressions, etc...
  options: _VTI_TYPE_FieldInfoMetaOptions;
  // type of display value
  display: string | null;
  displayOptions: {
    template?: string;
    choices?: FieldDisplayOptionsChoice[];
  } | null;
  isReadonly: boolean;
  isHidden: boolean;
  isRequired: boolean;
  // sort position in collection
  sortPosition: number;
  translations: FieldTranslationInterface[];
  note: string | null;
  // is that field is ID
  readonly isPrimaryKey: boolean;
  isUnique: boolean;
  isNullable: boolean;
  schema: _VTI_TYPE_FieldInfoSchema | null;
  validation: _VTI_TYPE_FieldInfoMetaValidation;
  special: any;
  conditions: Condition[] | null;
}
interface _VTI_TYPE_FieldInfoInterface {
  readonly id: number;
  readonly name: string;
  readonly type: string;
  readonly collectionName: string;
  readonly meta: _VTI_TYPE_FieldInfoMetadataInterface;
}
interface FieldDisplayProp {
  collectionName: string;
  item: _VTI_TYPE_ItemInterface;
  fieldKey: string;
  context?: {
    data?: ItemInterface;
  };
  fieldInfo?: _VTI_TYPE_FieldInfoInterface;
}
  import { computed } from 'vue'
  
  const props = defineProps<FieldDisplayProp>();
  const fieldData = computed<unknown>(() =>
    props.item.getFieldDataByExpression(props.fieldKey),
  );
  const displayData = computed<string>(() => {
    const rawData = fieldData.value;
    if (Array.isArray(rawData)) return JSON.stringify(rawData);
    if (typeof rawData === "object" && !!rawData && "currentJunctionItemIds" in rawData)
      return JSON.stringify(rawData.currentJunctionItemIds);
    if (typeof rawData === "object") return JSON.stringify(rawData);
    return rawData as string;
  });
</script>

<style scoped></style>
