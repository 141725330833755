<template>
  <PMultiselect v-bind="props" v-on="emitListeners" ref="refMultiSelect">
    <template #removetokenicon="slotProps">
      <slot name="removetokenicon" v-bind="slotProps" class="">
        <div class="multiselect-chip__button multiselect-chip__button--action">
          <Button
            variant="transparent"
            text
            size="small"
            :rounded="false"
            @click="slotProps.onClick"
          >
            <i class="fa-solid fa-xmark"></i>
          </Button>
        </div>
      </slot>
    </template>

    <template #value="slotProps" v-if="!!slots.value">
      <slot name="value" v-bind="slotProps"></slot>
    </template>

    <template #chip="slotProps" v-if="!!slots.chip">
      <slot name="chip" v-bind="slotProps"></slot>
    </template>

    <template #indicator v-if="!!slots.indicator">
      <slot name="indicator"></slot>
    </template>

    <template #header="slotProps" v-if="!!slots.header">
      <slot name="header" v-bind="slotProps"></slot>
    </template>

    <template #footer="slotProps" v-if="!!slots.footer">
      <slot name="footer" v-bind="slotProps"></slot>
    </template>

    <template #option="slotProps" v-if="!!slots.option">
      <slot name="option" v-bind="slotProps"></slot>
    </template>

    <template #optiongroup="slotProps" v-if="!!slots.optiongroup">
      <slot name="optiongroup" v-bind="slotProps"></slot>
    </template>

    <template #emptyfilter v-if="!!slots.emptyfilter">
      <slot name="emptyfilter"></slot>
    </template>

    <template #empty v-if="!!slots.empty">
      <slot name="empty"></slot>
    </template>

    <template #content="slotProps" v-if="!!slots.content">
      <slot name="content" v-bind="slotProps"></slot>
    </template>

    <template #loader="slotProps" v-if="!!slots.loader">
      <slot name="loader" v-bind="slotProps"></slot>
    </template>

    <template #headercheckboxicon="slotProps" v-if="!!slots.headercheckboxicon">
      <slot name="headercheckboxicon" v-bind="slotProps"></slot>
    </template>

    <template #filtericon="slotProps" v-if="!!slots.filtericon">
      <slot name="filtericon" v-bind="slotProps"></slot>
    </template>

    <template #closeicon="slotProps" v-if="!!slots.closeicon">
      <slot name="closeicon" v-bind="slotProps"></slot>
    </template>

    <template #itemcheckboxicon="slotProps" v-if="!!slots.itemcheckboxicon">
      <slot name="itemcheckboxicon" v-bind="slotProps"></slot>
    </template>

    <template #loadingicon="slotProps" v-if="!!slots.loadingicon">
      <slot name="loadingicon" v-bind="slotProps"></slot>
    </template>

    <template #dropdownicon="slotProps" v-if="!!slots.dropdownicon">
      <slot name="dropdownicon" v-bind="slotProps"></slot>
    </template>
  </PMultiselect>
</template>

<script setup lang="ts">
interface _VTI_TYPE_VirtualScrollerPassThroughAttributes {
    [key: string]: any;
}
interface _VTI_TYPE_MultiSelectPassThroughAttributes {
    [key: string]: any;
}
type _VTI_TYPE_VirtualScrollerPassThroughOptionType = _VTI_TYPE_VirtualScrollerPassThroughAttributes | ((options: VirtualScrollerPassThroughMethodOptions) => VirtualScrollerPassThroughAttributes | string) | string | null | undefined
interface _VTI_TYPE_CSSProperties {
    /**
     * The index signature was removed to enable closed typing for style
     * using CSSType. You're able to use type assertion or module augmentation
     * to add properties or an index signature of your own.
     *
     * For examples and more information, visit:
     * https://github.com/frenic/csstype#what-should-i-do-when-i-get-type-errors
     */
    [v: `--${string}`]: string | number | undefined;
}
type _VTI_TYPE_MultiSelectPassThroughOptionType = _VTI_TYPE_MultiSelectPassThroughAttributes | ((options: MultiSelectPassThroughMethodOptions) => MultiSelectPassThroughAttributes | string) | string | null | undefined
interface _VTI_TYPE_VirtualScrollerPassThroughOptions {
    /**
     * Uses to pass attributes to the root's DOM element.
     */
    root?: _VTI_TYPE_VirtualScrollerPassThroughOptionType;
    /**
     * Uses to pass attributes to the content's DOM element.
     */
    content?: _VTI_TYPE_VirtualScrollerPassThroughOptionType;
    /**
     * Uses to pass attributes to the loader's DOM element.
     */
    loader?: _VTI_TYPE_VirtualScrollerPassThroughOptionType;
    /**
     * Uses to pass attributes to the loading icon's DOM element.
     */
    loadingIcon?: _VTI_TYPE_VirtualScrollerPassThroughOptionType;
    /**
     * Uses to pass attributes to the spacer's DOM element.
     */
    spacer?: _VTI_TYPE_VirtualScrollerPassThroughOptionType;
    /**
     * Uses to manage all lifecycle hooks
     * @see {@link BaseComponent.ComponentHooks}
     */
    hooks?: _VTI_TYPE_ComponentHooks;
}
type _VTI_TYPE_Booleanish = boolean | 'true' | 'false'
type _VTI_TYPE_Numberish = number | string
type _VTI_TYPE_StyleValue = string | _VTI_TYPE_CSSProperties | _VTI_TYPE_Array
interface _VTI_TYPE_MultiSelectPassThroughOptions {
    /**
     * Uses to pass attributes to the root's DOM element.
     */
    root?: _VTI_TYPE_MultiSelectPassThroughOptionType;
    /**
     * Uses to pass attributes to the label container's DOM element.
     */
    labelContainer?: _VTI_TYPE_MultiSelectPassThroughOptionType;
    /**
     * Uses to pass attributes to the label's DOM element.
     */
    label?: _VTI_TYPE_MultiSelectPassThroughOptionType;
    /**
     * Uses to pass attributes to the token's DOM element.
     */
    token?: _VTI_TYPE_MultiSelectPassThroughOptionType;
    /**
     * Uses to pass attributes to the token label's DOM element.
     */
    tokenLabel?: _VTI_TYPE_MultiSelectPassThroughOptionType;
    /**
     * Uses to pass attributes to the remove token icon's DOM element.
     */
    removeTokenIcon?: _VTI_TYPE_MultiSelectPassThroughOptionType;
    /**
     * Uses to pass attributes to the trigger's DOM element.
     */
    trigger?: _VTI_TYPE_MultiSelectPassThroughOptionType;
    /**
     * Uses to pass attributes to the trigger icon's DOM element.
     */
    triggerIcon?: _VTI_TYPE_MultiSelectPassThroughOptionType;
    /**
     * Uses to pass attributes to the dropdown icon's DOM element.
     */
    dropdownIcon?: _VTI_TYPE_MultiSelectPassThroughOptionType;
    /**
     * Uses to pass attributes to the panel's DOM element.
     */
    panel?: _VTI_TYPE_MultiSelectPassThroughOptionType;
    /**
     * Uses to pass attributes to the header's DOM element.
     */
    header?: _VTI_TYPE_MultiSelectPassThroughOptionType;
    /**
     * Uses to pass attributes to the header checkbox container's DOM element.
     */
    headerCheckboxContainer?: _VTI_TYPE_MultiSelectPassThroughOptionType;
    /**
     * Uses to pass attributes to the header checkbox's DOM element.
     */
    headerCheckbox?: _VTI_TYPE_MultiSelectPassThroughOptionType;
    /**
     * Uses to pass attributes to the filter container's DOM element.
     */
    filterContainer?: _VTI_TYPE_MultiSelectPassThroughOptionType;
    /**
     * Uses to pass attributes to the filter input's DOM element.
     */
    filterInput?: _VTI_TYPE_MultiSelectPassThroughOptionType;
    /**
     * Uses to pass attributes to the filter icon's DOM element.
     */
    filterIcon?: _VTI_TYPE_MultiSelectPassThroughOptionType;
    /**
     * Uses to pass attributes to the close button's DOM element.
     */
    closeButton?: _VTI_TYPE_MultiSelectPassThroughOptionType;
    /**
     * Uses to pass attributes to the close icon's DOM element.
     */
    closeIcon?: _VTI_TYPE_MultiSelectPassThroughOptionType;
    /**
     * Uses to pass attributes to the wrapper's DOM element.
     */
    wrapper?: _VTI_TYPE_MultiSelectPassThroughOptionType;
    /**
     * Uses to pass attributes to the VirtualScroller component.
     * @see {@link VirtualScrollerPassThroughOptionType}
     */
    virtualScroller?: _VTI_TYPE_VirtualScrollerPassThroughOptionType_2;
    /**
     * Uses to pass attributes to the list's DOM element.
     */
    list?: _VTI_TYPE_MultiSelectPassThroughOptionType;
    /**
     * Uses to pass attributes to the item group's DOM element.
     */
    itemGroup?: _VTI_TYPE_MultiSelectPassThroughOptionType;
    /**
     * Uses to pass attributes to the item's DOM element.
     */
    item?: _VTI_TYPE_MultiSelectPassThroughOptionType;
    /**
     * Uses to pass attributes to the checkbox container's DOM element.
     */
    checkboxContainer?: _VTI_TYPE_MultiSelectPassThroughOptionType;
    /**
     * Uses to pass attributes to the checkbox's DOM element.
     */
    checkbox?: _VTI_TYPE_MultiSelectPassThroughOptionType;
    /**
     * Uses to pass attributes to the checkbox icon's DOM element.
     */
    checkboxIcon?: _VTI_TYPE_MultiSelectPassThroughOptionType;
    /**
     * Uses to pass attributes to the option's DOM element.
     */
    option?: _VTI_TYPE_MultiSelectPassThroughOptionType;
    /**
     * Uses to pass attributes to the emptyMessage's DOM element.
     */
    emptyMessage?: _VTI_TYPE_MultiSelectPassThroughOptionType;
    /**
     * Uses to pass attributes to the hidden input wrapper's DOM element.
     */
    hiddenInputWrapper?: _VTI_TYPE_MultiSelectPassThroughOptionType;
    /**
     * Uses to pass attributes to the hidden input's DOM element.
     */
    hiddenInput?: _VTI_TYPE_MultiSelectPassThroughOptionType;
    /**
     * Uses to pass attributes to the hidden first focusable element's DOM element.
     */
    hiddenFirstFocusableEl?: _VTI_TYPE_MultiSelectPassThroughOptionType;
    /**
     * Uses to pass attributes to the hidden filter result's DOM element.
     */
    hiddenFilterResult?: _VTI_TYPE_MultiSelectPassThroughOptionType;
    /**
     * Uses to pass attributes to the hidden selected message's DOM element.
     */
    hiddenSelectedMessage?: _VTI_TYPE_MultiSelectPassThroughOptionType;
    /**
     * Uses to pass attributes to the hidden last focusable element's DOM element.
     */
    hiddenLastFocusableEl?: _VTI_TYPE_MultiSelectPassThroughOptionType;
    /**
     * Uses to manage all lifecycle hooks
     * @see {@link BaseComponent.ComponentHooks}
     */
    hooks?: _VTI_TYPE_ComponentHooks;
}
interface _VTI_TYPE_VirtualScrollerProps {
    /**
     * Unique identifier of the element.
     */
    id?: string | undefined;
    /**
     * Inline style of the component.
     */
    style?: any;
    /**
     * Style class of the component.
     */
    class?: any;
    /**
     * An array of objects to display.
     */
    items?: any[] | any[][] | undefined | null;
    /**
     * The height/width of item according to orientation.
     */
    itemSize?: number | number[] | undefined;
    /**
     * Height of the scroll viewport.
     */
    scrollHeight?: string | undefined;
    /**
     * Width of the scroll viewport.
     */
    scrollWidth?: string | undefined;
    /**
     * The orientation of scrollbar.
     * @defaultValue vertical
     */
    orientation?: 'vertical' | 'horizontal' | 'both' | undefined;
    /**
     * Determines how many additional elements to add to the DOM outside of the view.
     * According to the scrolls made up and down, extra items are added in a certain algorithm in the form of multiples of this number.
     * @defaultValue half the number of items shown in the view.
     */
    numToleratedItems?: number | undefined;
    /**
     * Delay in scroll before new data is loaded.
     * @defaultValue 0
     */
    delay?: number | undefined;
    /**
     * Delay after window's resize finishes.
     * @defaultValue 10
     */
    resizeDelay?: number | undefined;
    /**
     * Defines if data is loaded and interacted with in lazy manner.
     * @defaultValue false
     */
    lazy?: boolean | undefined;
    /**
     * If disabled, the VirtualScroller feature is eliminated and the content is displayed directly.
     * @defaultValue false
     */
    disabled?: boolean | undefined;
    /**
     * Used to implement a custom loader instead of using the loader feature in the VirtualScroller.
     * @defaultValue false
     */
    loaderDisabled?: boolean | undefined;
    /**
     * Whether to show loader.
     * @defaultValue false
     */
    showLoader?: boolean | undefined;
    /**
     * Used to implement a custom spacer instead of using the spacer feature in the VirtualScroller.
     * @defaultValue true
     */
    showSpacer?: boolean | undefined;
    /**
     * Whether to load items.
     * @defaultValue false
     */
    loading?: boolean | undefined;
    /**
     * Callback to invoke in lazy mode to load new data.
     * @param {VirtualScrollerLazyEvent} event - Custom lazy event.
     */
    onLazyLoad?(event: VirtualScrollerLazyEvent): void;
    /**
     * Index of the element in tabbing order.
     * @defaultValue 0
     */
    tabindex?: number | string | undefined;
    /**
     * When enabled, positions the content as inline.
     * @defaultValue false
     */
    inline?: boolean | undefined;
    /**
     * Used to specify how many items to load in each load method in lazy mode.
     * @defaultValue 0
     */
    step?: number | undefined;
    /**
     * Used to append each loaded item to top without removing any items from the DOM. Using very large data may cause the browser to crash.
     * @defaultValue false
     */
    appendOnly?: boolean | undefined;
    /**
     * Whether to dynamically change the height or width of scrollable container.
     * @defaultValue false
     */
    autoSize?: boolean | undefined;
    /**
     * Uses to pass attributes to DOM elements inside the component.
     * @type {VirtualScrollerPassThroughOptions}
     */
    pt?: _VTI_TYPE_VirtualScrollerPassThroughOptions;
    /**
     * When enabled, it removes component related styles in the core.
     * @defaultValue false
     */
    unstyled?: boolean;
}
interface _VTI_TYPE_ButtonHTMLAttributes {
    autofocus?: _VTI_TYPE_Booleanish;
    disabled?: _VTI_TYPE_Booleanish;
    form?: string;
    formaction?: string;
    formenctype?: string;
    formmethod?: string;
    formnovalidate?: _VTI_TYPE_Booleanish;
    formtarget?: string;
    name?: string;
    type?: 'submit' | 'reset' | 'button';
    value?: string | string[] | number;
}
interface _VTI_TYPE_HTMLAttributes {
    /** Identifies the currently active element when DOM focus is on a composite widget, textbox, group, or application. */
    'aria-activedescendant'?: string;
    /** Indicates whether assistive technologies will present all, or only parts of, the changed region based on the change notifications defined by the aria-relevant attribute. */
    'aria-atomic'?: _VTI_TYPE_Booleanish;
    /**
     * Indicates whether inputting text could trigger display of one or more predictions of the user's intended value for an input and specifies how predictions would be
     * presented if they are made.
     */
    'aria-autocomplete'?: 'none' | 'inline' | 'list' | 'both';
    /** Indicates an element is being modified and that assistive technologies MAY want to wait until the modifications are complete before exposing them to the user. */
    'aria-busy'?: _VTI_TYPE_Booleanish;
    /**
     * Indicates the current "checked" state of checkboxes, radio buttons, and other widgets.
     * @see aria-pressed @see aria-selected.
     */
    'aria-checked'?: _VTI_TYPE_Booleanish | 'mixed';
    /**
     * Defines the total number of columns in a table, grid, or treegrid.
     * @see aria-colindex.
     */
    'aria-colcount'?: _VTI_TYPE_Numberish;
    /**
     * Defines an element's column index or position with respect to the total number of columns within a table, grid, or treegrid.
     * @see aria-colcount @see aria-colspan.
     */
    'aria-colindex'?: _VTI_TYPE_Numberish;
    /**
     * Defines the number of columns spanned by a cell or gridcell within a table, grid, or treegrid.
     * @see aria-colindex @see aria-rowspan.
     */
    'aria-colspan'?: _VTI_TYPE_Numberish;
    /**
     * Identifies the element (or elements) whose contents or presence are controlled by the current element.
     * @see aria-owns.
     */
    'aria-controls'?: string;
    /** Indicates the element that represents the current item within a container or set of related elements. */
    'aria-current'?: _VTI_TYPE_Booleanish | 'page' | 'step' | 'location' | 'date' | 'time';
    /**
     * Identifies the element (or elements) that describes the object.
     * @see aria-labelledby
     */
    'aria-describedby'?: string;
    /**
     * Identifies the element that provides a detailed, extended description for the object.
     * @see aria-describedby.
     */
    'aria-details'?: string;
    /**
     * Indicates that the element is perceivable but disabled, so it is not editable or otherwise operable.
     * @see aria-hidden @see aria-readonly.
     */
    'aria-disabled'?: _VTI_TYPE_Booleanish;
    /**
     * Indicates what functions can be performed when a dragged object is released on the drop target.
     * @deprecated in ARIA 1.1
     */
    'aria-dropeffect'?: 'none' | 'copy' | 'execute' | 'link' | 'move' | 'popup';
    /**
     * Identifies the element that provides an error message for the object.
     * @see aria-invalid @see aria-describedby.
     */
    'aria-errormessage'?: string;
    /** Indicates whether the element, or another grouping element it controls, is currently expanded or collapsed. */
    'aria-expanded'?: _VTI_TYPE_Booleanish;
    /**
     * Identifies the next element (or elements) in an alternate reading order of content which, at the user's discretion,
     * allows assistive technology to override the general default of reading in document source order.
     */
    'aria-flowto'?: string;
    /**
     * Indicates an element's "grabbed" state in a drag-and-drop operation.
     * @deprecated in ARIA 1.1
     */
    'aria-grabbed'?: _VTI_TYPE_Booleanish;
    /** Indicates the availability and type of interactive popup element, such as menu or dialog, that can be triggered by an element. */
    'aria-haspopup'?: _VTI_TYPE_Booleanish | 'menu' | 'listbox' | 'tree' | 'grid' | 'dialog';
    /**
     * Indicates whether the element is exposed to an accessibility API.
     * @see aria-disabled.
     */
    'aria-hidden'?: _VTI_TYPE_Booleanish;
    /**
     * Indicates the entered value does not conform to the format expected by the application.
     * @see aria-errormessage.
     */
    'aria-invalid'?: _VTI_TYPE_Booleanish | 'grammar' | 'spelling';
    /** Indicates keyboard shortcuts that an author has implemented to activate or give focus to an element. */
    'aria-keyshortcuts'?: string;
    /**
     * Defines a string value that labels the current element.
     * @see aria-labelledby.
     */
    'aria-label'?: string;
    /**
     * Identifies the element (or elements) that labels the current element.
     * @see aria-describedby.
     */
    'aria-labelledby'?: string;
    /** Defines the hierarchical level of an element within a structure. */
    'aria-level'?: _VTI_TYPE_Numberish;
    /** Indicates that an element will be updated, and describes the types of updates the user agents, assistive technologies, and user can expect from the live region. */
    'aria-live'?: 'off' | 'assertive' | 'polite';
    /** Indicates whether an element is modal when displayed. */
    'aria-modal'?: _VTI_TYPE_Booleanish;
    /** Indicates whether a text box accepts multiple lines of input or only a single line. */
    'aria-multiline'?: _VTI_TYPE_Booleanish;
    /** Indicates that the user may select more than one item from the current selectable descendants. */
    'aria-multiselectable'?: _VTI_TYPE_Booleanish;
    /** Indicates whether the element's orientation is horizontal, vertical, or unknown/ambiguous. */
    'aria-orientation'?: 'horizontal' | 'vertical';
    /**
     * Identifies an element (or elements) in order to define a visual, functional, or contextual parent/child relationship
     * between DOM elements where the DOM hierarchy cannot be used to represent the relationship.
     * @see aria-controls.
     */
    'aria-owns'?: string;
    /**
     * Defines a short hint (a word or short phrase) intended to aid the user with data entry when the control has no value.
     * A hint could be a sample value or a brief description of the expected format.
     */
    'aria-placeholder'?: string;
    /**
     * Defines an element's number or position in the current set of listitems or treeitems. Not required if all elements in the set are present in the DOM.
     * @see aria-setsize.
     */
    'aria-posinset'?: _VTI_TYPE_Numberish;
    /**
     * Indicates the current "pressed" state of toggle buttons.
     * @see aria-checked @see aria-selected.
     */
    'aria-pressed'?: _VTI_TYPE_Booleanish | 'mixed';
    /**
     * Indicates that the element is not editable, but is otherwise operable.
     * @see aria-disabled.
     */
    'aria-readonly'?: _VTI_TYPE_Booleanish;
    /**
     * Indicates what notifications the user agent will trigger when the accessibility tree within a live region is modified.
     * @see aria-atomic.
     */
    'aria-relevant'?: 'additions' | 'additions text' | 'all' | 'removals' | 'text';
    /** Indicates that user input is required on the element before a form may be submitted. */
    'aria-required'?: _VTI_TYPE_Booleanish;
    /** Defines a human-readable, author-localized description for the role of an element. */
    'aria-roledescription'?: string;
    /**
     * Defines the total number of rows in a table, grid, or treegrid.
     * @see aria-rowindex.
     */
    'aria-rowcount'?: _VTI_TYPE_Numberish;
    /**
     * Defines an element's row index or position with respect to the total number of rows within a table, grid, or treegrid.
     * @see aria-rowcount @see aria-rowspan.
     */
    'aria-rowindex'?: _VTI_TYPE_Numberish;
    /**
     * Defines the number of rows spanned by a cell or gridcell within a table, grid, or treegrid.
     * @see aria-rowindex @see aria-colspan.
     */
    'aria-rowspan'?: _VTI_TYPE_Numberish;
    /**
     * Indicates the current "selected" state of various widgets.
     * @see aria-checked @see aria-pressed.
     */
    'aria-selected'?: _VTI_TYPE_Booleanish;
    /**
     * Defines the number of items in the current set of listitems or treeitems. Not required if all elements in the set are present in the DOM.
     * @see aria-posinset.
     */
    'aria-setsize'?: _VTI_TYPE_Numberish;
    /** Indicates if items in a table or grid are sorted in ascending or descending order. */
    'aria-sort'?: 'none' | 'ascending' | 'descending' | 'other';
    /** Defines the maximum allowed value for a range widget. */
    'aria-valuemax'?: _VTI_TYPE_Numberish;
    /** Defines the minimum allowed value for a range widget. */
    'aria-valuemin'?: _VTI_TYPE_Numberish;
    /**
     * Defines the current value for a range widget.
     * @see aria-valuetext.
     */
    'aria-valuenow'?: _VTI_TYPE_Numberish;
    /** Defines the human readable text alternative of aria-valuenow for a range widget. */
    'aria-valuetext'?: string;
    innerHTML?: string;
    class?: any;
    style?: _VTI_TYPE_StyleValue;
    accesskey?: string;
    contenteditable?: _VTI_TYPE_Booleanish | 'inherit';
    contextmenu?: string;
    dir?: string;
    draggable?: _VTI_TYPE_Booleanish;
    hidden?: _VTI_TYPE_Booleanish;
    id?: string;
    lang?: string;
    placeholder?: string;
    spellcheck?: _VTI_TYPE_Booleanish;
    tabindex?: _VTI_TYPE_Numberish;
    title?: string;
    translate?: 'yes' | 'no';
    radiogroup?: string;
    role?: string;
    about?: string;
    datatype?: string;
    inlist?: any;
    prefix?: string;
    property?: string;
    resource?: string;
    typeof?: string;
    vocab?: string;
    autocapitalize?: string;
    autocorrect?: string;
    autosave?: string;
    color?: string;
    itemprop?: string;
    itemscope?: _VTI_TYPE_Booleanish;
    itemtype?: string;
    itemid?: string;
    itemref?: string;
    results?: _VTI_TYPE_Numberish;
    security?: string;
    unselectable?: 'on' | 'off';
    /**
     * Hints at the type of data that might be entered by the user while editing the element or its contents
     * @see https://html.spec.whatwg.org/multipage/interaction.html#input-modalities:-the-inputmode-attribute
     */
    inputmode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';
    /**
     * Specify that a standard HTML element should behave like a defined custom built-in element
     * @see https://html.spec.whatwg.org/multipage/custom-elements.html#attr-is
     */
    is?: string;
}
interface _VTI_TYPE_InputHTMLAttributes {
    /** Identifies the currently active element when DOM focus is on a composite widget, textbox, group, or application. */
    'aria-activedescendant'?: string;
    /** Indicates whether assistive technologies will present all, or only parts of, the changed region based on the change notifications defined by the aria-relevant attribute. */
    'aria-atomic'?: _VTI_TYPE_Booleanish;
    /**
     * Indicates whether inputting text could trigger display of one or more predictions of the user's intended value for an input and specifies how predictions would be
     * presented if they are made.
     */
    'aria-autocomplete'?: 'none' | 'inline' | 'list' | 'both';
    /** Indicates an element is being modified and that assistive technologies MAY want to wait until the modifications are complete before exposing them to the user. */
    'aria-busy'?: _VTI_TYPE_Booleanish;
    /**
     * Indicates the current "checked" state of checkboxes, radio buttons, and other widgets.
     * @see aria-pressed @see aria-selected.
     */
    'aria-checked'?: _VTI_TYPE_Booleanish | 'mixed';
    /**
     * Defines the total number of columns in a table, grid, or treegrid.
     * @see aria-colindex.
     */
    'aria-colcount'?: _VTI_TYPE_Numberish;
    /**
     * Defines an element's column index or position with respect to the total number of columns within a table, grid, or treegrid.
     * @see aria-colcount @see aria-colspan.
     */
    'aria-colindex'?: _VTI_TYPE_Numberish;
    /**
     * Defines the number of columns spanned by a cell or gridcell within a table, grid, or treegrid.
     * @see aria-colindex @see aria-rowspan.
     */
    'aria-colspan'?: _VTI_TYPE_Numberish;
    /**
     * Identifies the element (or elements) whose contents or presence are controlled by the current element.
     * @see aria-owns.
     */
    'aria-controls'?: string;
    /** Indicates the element that represents the current item within a container or set of related elements. */
    'aria-current'?: _VTI_TYPE_Booleanish | 'page' | 'step' | 'location' | 'date' | 'time';
    /**
     * Identifies the element (or elements) that describes the object.
     * @see aria-labelledby
     */
    'aria-describedby'?: string;
    /**
     * Identifies the element that provides a detailed, extended description for the object.
     * @see aria-describedby.
     */
    'aria-details'?: string;
    /**
     * Indicates that the element is perceivable but disabled, so it is not editable or otherwise operable.
     * @see aria-hidden @see aria-readonly.
     */
    'aria-disabled'?: _VTI_TYPE_Booleanish;
    /**
     * Indicates what functions can be performed when a dragged object is released on the drop target.
     * @deprecated in ARIA 1.1
     */
    'aria-dropeffect'?: 'none' | 'copy' | 'execute' | 'link' | 'move' | 'popup';
    /**
     * Identifies the element that provides an error message for the object.
     * @see aria-invalid @see aria-describedby.
     */
    'aria-errormessage'?: string;
    /** Indicates whether the element, or another grouping element it controls, is currently expanded or collapsed. */
    'aria-expanded'?: _VTI_TYPE_Booleanish;
    /**
     * Identifies the next element (or elements) in an alternate reading order of content which, at the user's discretion,
     * allows assistive technology to override the general default of reading in document source order.
     */
    'aria-flowto'?: string;
    /**
     * Indicates an element's "grabbed" state in a drag-and-drop operation.
     * @deprecated in ARIA 1.1
     */
    'aria-grabbed'?: _VTI_TYPE_Booleanish;
    /** Indicates the availability and type of interactive popup element, such as menu or dialog, that can be triggered by an element. */
    'aria-haspopup'?: _VTI_TYPE_Booleanish | 'menu' | 'listbox' | 'tree' | 'grid' | 'dialog';
    /**
     * Indicates whether the element is exposed to an accessibility API.
     * @see aria-disabled.
     */
    'aria-hidden'?: _VTI_TYPE_Booleanish;
    /**
     * Indicates the entered value does not conform to the format expected by the application.
     * @see aria-errormessage.
     */
    'aria-invalid'?: _VTI_TYPE_Booleanish | 'grammar' | 'spelling';
    /** Indicates keyboard shortcuts that an author has implemented to activate or give focus to an element. */
    'aria-keyshortcuts'?: string;
    /**
     * Defines a string value that labels the current element.
     * @see aria-labelledby.
     */
    'aria-label'?: string;
    /**
     * Identifies the element (or elements) that labels the current element.
     * @see aria-describedby.
     */
    'aria-labelledby'?: string;
    /** Defines the hierarchical level of an element within a structure. */
    'aria-level'?: _VTI_TYPE_Numberish;
    /** Indicates that an element will be updated, and describes the types of updates the user agents, assistive technologies, and user can expect from the live region. */
    'aria-live'?: 'off' | 'assertive' | 'polite';
    /** Indicates whether an element is modal when displayed. */
    'aria-modal'?: _VTI_TYPE_Booleanish;
    /** Indicates whether a text box accepts multiple lines of input or only a single line. */
    'aria-multiline'?: _VTI_TYPE_Booleanish;
    /** Indicates that the user may select more than one item from the current selectable descendants. */
    'aria-multiselectable'?: _VTI_TYPE_Booleanish;
    /** Indicates whether the element's orientation is horizontal, vertical, or unknown/ambiguous. */
    'aria-orientation'?: 'horizontal' | 'vertical';
    /**
     * Identifies an element (or elements) in order to define a visual, functional, or contextual parent/child relationship
     * between DOM elements where the DOM hierarchy cannot be used to represent the relationship.
     * @see aria-controls.
     */
    'aria-owns'?: string;
    /**
     * Defines a short hint (a word or short phrase) intended to aid the user with data entry when the control has no value.
     * A hint could be a sample value or a brief description of the expected format.
     */
    'aria-placeholder'?: string;
    /**
     * Defines an element's number or position in the current set of listitems or treeitems. Not required if all elements in the set are present in the DOM.
     * @see aria-setsize.
     */
    'aria-posinset'?: _VTI_TYPE_Numberish;
    /**
     * Indicates the current "pressed" state of toggle buttons.
     * @see aria-checked @see aria-selected.
     */
    'aria-pressed'?: _VTI_TYPE_Booleanish | 'mixed';
    /**
     * Indicates that the element is not editable, but is otherwise operable.
     * @see aria-disabled.
     */
    'aria-readonly'?: _VTI_TYPE_Booleanish;
    /**
     * Indicates what notifications the user agent will trigger when the accessibility tree within a live region is modified.
     * @see aria-atomic.
     */
    'aria-relevant'?: 'additions' | 'additions text' | 'all' | 'removals' | 'text';
    /** Indicates that user input is required on the element before a form may be submitted. */
    'aria-required'?: _VTI_TYPE_Booleanish;
    /** Defines a human-readable, author-localized description for the role of an element. */
    'aria-roledescription'?: string;
    /**
     * Defines the total number of rows in a table, grid, or treegrid.
     * @see aria-rowindex.
     */
    'aria-rowcount'?: _VTI_TYPE_Numberish;
    /**
     * Defines an element's row index or position with respect to the total number of rows within a table, grid, or treegrid.
     * @see aria-rowcount @see aria-rowspan.
     */
    'aria-rowindex'?: _VTI_TYPE_Numberish;
    /**
     * Defines the number of rows spanned by a cell or gridcell within a table, grid, or treegrid.
     * @see aria-rowindex @see aria-colspan.
     */
    'aria-rowspan'?: _VTI_TYPE_Numberish;
    /**
     * Indicates the current "selected" state of various widgets.
     * @see aria-checked @see aria-pressed.
     */
    'aria-selected'?: _VTI_TYPE_Booleanish;
    /**
     * Defines the number of items in the current set of listitems or treeitems. Not required if all elements in the set are present in the DOM.
     * @see aria-posinset.
     */
    'aria-setsize'?: _VTI_TYPE_Numberish;
    /** Indicates if items in a table or grid are sorted in ascending or descending order. */
    'aria-sort'?: 'none' | 'ascending' | 'descending' | 'other';
    /** Defines the maximum allowed value for a range widget. */
    'aria-valuemax'?: _VTI_TYPE_Numberish;
    /** Defines the minimum allowed value for a range widget. */
    'aria-valuemin'?: _VTI_TYPE_Numberish;
    /**
     * Defines the current value for a range widget.
     * @see aria-valuetext.
     */
    'aria-valuenow'?: _VTI_TYPE_Numberish;
    /** Defines the human readable text alternative of aria-valuenow for a range widget. */
    'aria-valuetext'?: string;
    innerHTML?: string;
    class?: any;
    style?: _VTI_TYPE_StyleValue;
    accesskey?: string;
    contenteditable?: _VTI_TYPE_Booleanish | 'inherit';
    contextmenu?: string;
    dir?: string;
    draggable?: _VTI_TYPE_Booleanish;
    hidden?: _VTI_TYPE_Booleanish;
    id?: string;
    lang?: string;
    placeholder?: string;
    spellcheck?: _VTI_TYPE_Booleanish;
    tabindex?: _VTI_TYPE_Numberish;
    title?: string;
    translate?: 'yes' | 'no';
    radiogroup?: string;
    role?: string;
    about?: string;
    datatype?: string;
    inlist?: any;
    prefix?: string;
    property?: string;
    resource?: string;
    typeof?: string;
    vocab?: string;
    autocapitalize?: string;
    autocorrect?: string;
    autosave?: string;
    color?: string;
    itemprop?: string;
    itemscope?: _VTI_TYPE_Booleanish;
    itemtype?: string;
    itemid?: string;
    itemref?: string;
    results?: _VTI_TYPE_Numberish;
    security?: string;
    unselectable?: 'on' | 'off';
    /**
     * Hints at the type of data that might be entered by the user while editing the element or its contents
     * @see https://html.spec.whatwg.org/multipage/interaction.html#input-modalities:-the-inputmode-attribute
     */
    inputmode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';
    /**
     * Specify that a standard HTML element should behave like a defined custom built-in element
     * @see https://html.spec.whatwg.org/multipage/custom-elements.html#attr-is
     */
    is?: string;
    accept?: string;
    alt?: string;
    autocomplete?: string;
    autofocus?: _VTI_TYPE_Booleanish;
    capture?: boolean | 'user' | 'environment';
    checked?: _VTI_TYPE_Booleanish | any[] | _VTI_TYPE_Set;
    crossorigin?: string;
    disabled?: _VTI_TYPE_Booleanish;
    form?: string;
    formaction?: string;
    formenctype?: string;
    formmethod?: string;
    formnovalidate?: _VTI_TYPE_Booleanish;
    formtarget?: string;
    height?: _VTI_TYPE_Numberish;
    indeterminate?: boolean;
    list?: string;
    max?: _VTI_TYPE_Numberish;
    maxlength?: _VTI_TYPE_Numberish;
    min?: _VTI_TYPE_Numberish;
    minlength?: _VTI_TYPE_Numberish;
    multiple?: _VTI_TYPE_Booleanish;
    name?: string;
    pattern?: string;
    placeholder?: string;
    readonly?: _VTI_TYPE_Booleanish;
    required?: _VTI_TYPE_Booleanish;
    size?: _VTI_TYPE_Numberish;
    src?: string;
    step?: _VTI_TYPE_Numberish;
    type?: string;
    value?: any;
    width?: _VTI_TYPE_Numberish;
}
type MultiSelectEmits = {
  "update:modelValue": [value: any];
  change: [event: MultiSelectChangeEvent];
  focus: [event: Event];
  blur: [event: Event];
  "before-show": [];
  "before-hide": [];
  show: [];
  hide: [];
  filter: [event: MultiSelectFilterEvent];
  "selectall-change": [event: MultiSelectAllChangeEvent];
}
interface MultiSelectProps {
  modelValue?: any;
  options?: any[] | undefined;
  optionLabel?: string | ((data: any) => string) | undefined;
  optionValue?: string | ((data: any) => any) | undefined;
  optionDisabled?: string | ((data: any) => boolean) | undefined;
  optionGroupLabel?: string | ((data: any) => string) | undefined;
  optionGroupChildren?: string | ((data: any) => any[]) | undefined;
  scrollHeight?: string | undefined;
  placeholder?: string | undefined;
  disabled?: boolean | undefined;
  inputId?: string | undefined;
  inputProps?: _VTI_TYPE_InputHTMLAttributes | undefined;
  panelStyle?: any;
  panelClass?: any;
  panelProps?: _VTI_TYPE_HTMLAttributes | undefined;
  filterInputProps?: _VTI_TYPE_InputHTMLAttributes | undefined;
  closeButtonProps?: _VTI_TYPE_ButtonHTMLAttributes | undefined;
  dataKey?: string | undefined;
  filter?: boolean | undefined;
  filterPlaceholder?: string | undefined;
  filterLocale?: string | undefined;
  filterMatchMode?: "contains" | "startsWith" | "endsWith" | string | undefined;
  filterFields?: string[] | undefined;
  appendTo?: "body" | "self" | string | undefined | _VTI_TYPE_HTMLElement;
  display?: "comma" | "chip" | undefined;
  selectedItemsLabel?: string | undefined;
  maxSelectedLabels?: number | undefined;
  selectionLimit?: number | undefined;
  showToggleAll?: boolean | undefined;
  loading?: boolean | undefined;
  selectAll?: boolean | undefined;
  resetFilterOnHide?: boolean | undefined;
  virtualScrollerOptions?: _VTI_TYPE_VirtualScrollerProps;
  autoOptionFocus?: boolean | undefined;
  autoFilterFocus?: boolean | undefined;
  filterMessage?: string | undefined;
  selectionMessage?: string | undefined;
  emptySelectionMessage?: string | undefined;
  emptyFilterMessage?: string | undefined;
  emptyMessage?: string | undefined;
  tabindex?: number | string | undefined;
  ariaLabel?: string | undefined;
  ariaLabelledby?: string | undefined;
  pt?: _VTI_TYPE_MultiSelectPassThroughOptions;
  unstyled?: boolean;
  /**
   * @deprecated since v3.27.0. Use 'checkboxicon' slot.
   */
  checkboxIcon?: string | undefined;
  /**
   * @deprecated since v3.27.0. Use 'closeicon' slot.
   */
  closeIcon?: string | undefined;
  /**
   * @deprecated since v3.27.0. Use 'dropdownicon' slot.
   */
  dropdownIcon?: string | undefined;
  /**
   * @deprecated since v3.27.0. Use 'filtericon' slot.
   */
  filterIcon?: string | undefined;
  /**
   * @deprecated since v3.27.0. Use 'loadingicon' slot.
   */
  loadingIcon?: string | undefined;
  /**
   * @deprecated since v3.27.0. Use 'removetokenicon' slot.
   */
  removeTokenIcon?: string | undefined;
}
  import { useSlots, ref, watchEffect } from 'vue'
  import PMultiselect, { MultiSelectEmits as PMultiSelectEmits } from 'primevue/multiselect'
  
  import Button from '~/shared/ui/Button'
  const slots = useSlots();
  const props = defineProps<MultiSelectProps>();
  const emit = defineEmits<MultiSelectEmits>();
  const refMultiSelect = ref<any>(null);
  const exposedMethods: {
    show: (isFocus: boolean) => void;
    hide: (isFocus: boolean) => void;
  } = {
    show: () => {},
    hide: () => {},
  };
  watchEffect(() => {
    if (!refMultiSelect.value) {
      exposedMethods.show = () => {};
      exposedMethods.hide = () => {};
      return;
    }
    exposedMethods.show = refMultiSelect.value.show;
    exposedMethods.hide = refMultiSelect.value.hide;
  });
  defineExpose(exposedMethods);
  const emitListeners: PMultiSelectEmits = {
    "update:modelValue": (value) => {
      emit("update:modelValue", value);
    },
    change: (event) => {
      emit("change", event);
    },
    focus: (event) => {
      emit("focus", event);
    },
    blur: (event) => {
      emit("blur", event);
    },
    "before-show": () => {
      emit("before-show");
    },
    "before-hide": () => {
      emit("before-hide");
    },
    show: () => {
      emit("show");
    },
    hide: () => {
      emit("hide");
    },
    filter: (event) => {
      emit("filter", event);
    },
    "selectall-change": (event) => {
      emit("selectall-change", event);
    },
  };
</script>

