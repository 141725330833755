import { createTranslatedErrors, FieldInterface } from "~/entities/field";
import ItemInterface, { ItemMetadataInterface } from "../entities/ItemInterface";
import { generatePrimaryKey } from "../utils/generators";

export default class Item implements ItemInterface {
  constructor(
    private readonly _fields: FieldInterface[],
    private readonly _metadata: ItemMetadataInterface = {},
    public readonly isNew: boolean = false,
  ) {
    if (isNew) {
      const fieldPK = _fields.find((field) => field.info.meta.isPrimaryKey);
      if (!fieldPK) {
        throw new Error("Unable to create Item. Primary Key field required.");
      }

      fieldPK.setData(generatePrimaryKey(fieldPK.info));
    }
  }

  get id(): string {
    return this._fields.find((field) => field.info.meta.isPrimaryKey)?.data;
  }

  get fields(): FieldInterface[] {
    return this._fields;
  }

  get metadata(): ItemMetadataInterface {
    return this._metadata;
  }

  get isDirty(): boolean {
    return !!this._fields.filter((field) => field.isDirty).length;
  }

  setMetadataProperty(key: string | number, data: unknown): this {
    this._metadata[key] = data;
    return this;
  }

  /**
   * @param expression
   * @returns
   * @todo TESTS
   */
  getFieldDataByExpression(expression: string): any | undefined {
    if (!expression.includes(".")) {
      return (
        this._fields.find((field) => field.info.name === expression)?.data || undefined
      );
    }

    const [rootPath, ...paths] = expression.split(".");

    let rootPosition =
      this._fields.find((field) => field.info.name === rootPath)?.data ?? undefined;
    rootPosition = resolveDataRelational(rootPosition);

    const foundData = findDataByPaths(rootPosition, paths);
    return foundData;

    function findDataByPaths(currentData: any, restPaths: string[]): any | undefined {
      if (!restPaths.length) return currentData;

      if (typeof currentData !== "object" && !Array.isArray(currentData))
        return undefined;
      if (Array.isArray(currentData) && !currentData.length) return undefined;

      const nextPath = restPaths[0];

      if (Array.isArray(currentData)) {
        const nextData = currentData
          .filter((data) => typeof data === "object" && data.hasOwnProperty(nextPath))
          .map((data) => resolveDataRelational(data[nextPath]));

        return findDataByPaths(nextData, restPaths.slice(1));
      }

      const nextData = resolveDataRelational(currentData[nextPath]);

      return findDataByPaths(nextData, restPaths.slice(1));
    }

    function resolveDataRelational(nextData: any): any {
      return typeof nextData === "object" &&
        nextData.hasOwnProperty("currentJunctionItemIds")
        ? nextData.currentJunctionItemIds
        : nextData;
    }
  }

  setClean(): this {
    this._fields.forEach((field) => field.setClean());
    return this;
  }
}

