import SettingsInterface from "./SettingsInterface";

export default class Settings implements SettingsInterface {
  constructor(
    private _projectName: string,
    private _authLoginAttempts: number,
    private _defaultLanguage: string
  ) {}

  get projectName(): string {
    return this._projectName;
  }

  setProjectName(value: string): this {
    this._projectName = value;
    return this;
  }

  get authLoginAttempts(): number {
    return this._authLoginAttempts;
  }

  setAuthLoginAttempts(value: number): this {
    this._authLoginAttempts = value;
    return this;
  }

  get defaultLanguage(): string {
    return this._defaultLanguage;
  }

  setDefaultLanguage(value: string): this {
    this._defaultLanguage = value;
    return this;
  }
}
