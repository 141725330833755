import { useNuxtApp } from "#app";
import { ref, computed } from "vue";
import cloneDeep from "lodash/cloneDeep";
import { aggregate } from "@directus/sdk";
import { FieldInfoInterface } from "~/entities/field";
import { logger } from "~/service/logger/logger";
import { toaster } from "~/service/toaster";
import { FieldFilter, QueryMany } from "~/api/data-queries/types";
import { adjustFieldsForDisplays } from "~/api/field-displays/utils";
import { useCollecitonsStore } from "~/stores/collections";
import { useFieldsStore } from "~/entities/field";
import { useDataStudioClient } from "~/service/data-studio/composables/useDataStudioClient";
import { readItemsWithCoreCollections } from "~/service/data-studio/utils/readItemsWithCoreCollections";
import ItemInterface from "../entities/ItemInterface";
import { castItemFromDataStudioApiToEntity } from "../casters/castItemsFromDataStudioApi";
import { ItemsFetchMode } from "../types/types";

// todo: AbortController
export const useLazyCollectionItems = () => {
  const { $i18n } = useNuxtApp();
  const collectionsStore = useCollecitonsStore();
  const fieldsStore = useFieldsStore();
  const { client: dataStudioClient } = useDataStudioClient();

  const collectionItems = ref<ItemInterface[]>([]);
  const meta = ref<{ filter_count: number }>();

  const isLoading = ref<boolean>(false);

  const load = async (
    collectionName: string,
    query: QueryMany<unknown>,
    fieldsInfo: FieldInfoInterface[],
  ): Promise<void> => {
    try {
      const collection = collectionsStore.getCollection(collectionName);

      const fieldsWithRelational = !!query.fields
        ? getFieldsWithRelational(collectionName, query.fields as string[])
        : query.fields;

      const { archiveField } = collection!.meta;

      const fieldsWithArchive = !!archiveField
        ? fieldsWithRelational?.concat([archiveField])
        : fieldsWithRelational;

      const adjustedQuery: QueryMany<unknown> = mergeQueryWithPrimaryField(
        collectionName,
        {
          ...query,
          fields: fieldsWithArchive,
        },
      );

      const itemsResponse = await dataStudioClient.request(
        readItemsWithCoreCollections(collectionName, adjustedQuery),
      );
      if (!itemsResponse) throw new Error("incorrect response body");

      const itemsCount = await dataStudioClient.request(
        aggregate(collectionName, {
          aggregate: {
            count: "*",
          },
          query: {
            ...adjustedQuery,
            page: undefined,
            limit: undefined,
          },
        }),
      );

      meta.value = {
        filter_count: !!itemsCount?.[0]?.count ? Number(itemsCount[0].count) : 0,
      };

      collectionItems.value = itemsResponse.map((itemData) =>
        castItemFromDataStudioApiToEntity(itemData, fieldsInfo),
      );
    } catch (err) {
      logger().error(
        {
          err,
        },
        `unable to retrieve items`,
      );

      toaster().error($i18n.t("server_error_fetch_collection"));
    } finally {
      isLoading.value = false;
    }
  };

  function getFieldsWithRelational(
    collectionName: string,
    fieldNames: string[],
  ): string[] {
    const collection = collectionsStore.getCollection(collectionName);
    return !!collection ? adjustFieldsForDisplays(fieldNames, collection) : [];
  }

  /**
   * @pure
   */
  function mergeQueryWithPrimaryField(
    collectionName: string,
    sourceQuery: QueryMany<unknown>,
  ): QueryMany<unknown> {
    const primaryField = fieldsStore.getPrimaryField(collectionName);

    if (!primaryField) return sourceQuery;
    if (sourceQuery.fields?.includes(primaryField.name)) return sourceQuery;

    const query: QueryMany<unknown> = cloneDeep(sourceQuery);

    if (!query.fields) {
      query.fields = [];
    }

    if (Array.isArray(query.fields)) {
      query.fields.push(primaryField.name);
    }

    return query;
  }

  return {
    collectionItems,
    meta: computed(() => meta.value),
    isLoading: computed(() => isLoading.value),
    load,
  };
};

/**
 * @pure
 */
export function getFilterWithArchive(
  sourceQuery: QueryMany<unknown>,
  collectionName: string,
  fetchMode: ItemsFetchMode,
): FieldFilter<unknown> {
  const collectionsStore = useCollecitonsStore();

  const sourceFilter = sourceQuery.filter as FieldFilter<unknown>;

  const collection = collectionsStore.getCollection(collectionName);
  if (!collection) return sourceFilter || {};

  const { archiveField, archiveValue } = collection.meta;
  if (!archiveField || archiveValue === null) return sourceFilter || {};

  const queryFilter = cloneDeep(sourceFilter || {});
  if (archiveField in queryFilter) {
    delete queryFilter[archiveField];
  }

  if (fetchMode !== ItemsFetchMode.all) {
    queryFilter[archiveField] = {
      [fetchMode === ItemsFetchMode.active ? "_neq" : "_eq"]: archiveValue,
    };
  }

  return queryFilter;
}

