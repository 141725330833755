import PopoverContainer from "./ui/Popover.vue";
import Button from "./ui/Button.vue";
import Panel from "./ui/Panel.vue";

const Popover = Object.assign({}, PopoverContainer, {
  Button: Button,
  Panel: Panel,
});

export default Popover;

