import { useRuntimeConfig } from "#app";
import SocketConnection from "~/shared/websocket/entity/SocketConnection";
import { useSocketConnectionsStore } from "~/stores/socket-connections";

export const createSocketConnection = () => {
  const runtimeConfig = useRuntimeConfig();
  const socketConnectionsStore = useSocketConnectionsStore();

  const socket = new SocketConnection(
    `${runtimeConfig.public.backendWebsocketUrl}/item-comments`,
  );

  socketConnectionsStore.addConnection("item-comments", socket);
};

