<template>
  <button class="btn--cardPreview">
    <slot name="icon">
      <i class="fas fa-plus"></i>
    </slot>
  </button>
</template>

<style scoped>
  .btn--cardPreview {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    width: 120px;
    height: 120px;
    position: relative;
    display: block;
    z-index: 10;
    padding: 10px;
    background: transparent;
    color: black;
    font-size: 1rem;
    border: 1px solid gray;
    overflow: hidden;
  }
  .btn--cardPreview i {
    font-size: 1.475rem;
  }
</style>

