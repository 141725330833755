<template>
  <div v-if="relationInfo && relatedCollection && field">
    <Menu v-if="displayItems.length">
      <Menu.Button :as="Button" class="capitalize">
        {{ $t("datatable_elements_count") }}: {{ displayItems.length }}
      </Menu.Button>

      <Menu.Items>
        <Menu.Item
          v-for="item in displayItems"
          as="div"
          class="flex items-center justify-between mb-1 cursor-default"
          @click.stop.prevent="() => {}"
        >
          <RenderTemplate
            :collection-name="relatedCollection.id"
            :item="item"
            :field-info="field"
            :default-template="displayTemplate ?? ''"
            :context="props.context"
          />

          <Button
            v-if="canNavigateToRelationalItem(relatedCollection)"
            :variant="'soft-secondary'"
            class="ml-2"
            @click="routeToItem(relatedCollection.id, item.id, $router)"
          >
            <i class="fa-solid fa-arrow-up-right-from-square"></i>
          </Button>
        </Menu.Item>
      </Menu.Items>
    </Menu>
  </div>
</template>

<script setup lang="ts">
type _VTI_TYPE_QueryLogicalFilterAnd = {
  [QueryFilterLogicalEnum._and]: QueryFilter<T>[];
}
type _VTI_TYPE_ValidationFilter = _VTI_TYPE_QueryLogicalFilterAnd<any>
type _VTI_TYPE_FieldFilter = {
  [K in keyof QuerySingleItem]?:
    | QueryFilterOperators<QuerySingleItem[K]>
    | FieldFilter<QuerySingleItem[K]>;
}
interface _VTI_TYPE_FieldInfoMetaValidation {
  message: string | null;
  rules: _VTI_TYPE_ValidationFilter | null;
}
interface _VTI_TYPE_FieldInfoSchema {
  defaultValue: unknown | null;
  maxLength: unknown | null;
  comment: unknown | null;
  numericPrecision: unknown | null;
  numericScale: unknown | null;
  hasAutoIncrement: boolean;
}
interface _VTI_TYPE_FieldInfoMetaOptions {
  choices?: FieldInfoMetaOptionsChoices[];
  folder?: string;
  title?: string;
  filter?: _VTI_TYPE_FieldFilter;
  template?: string; // Interface display template. Have priority on common display property
  masked?: boolean;
  placeholder?: string;
}
interface _VTI_TYPE_FieldInfoMetadataInterface {
  // type of field
  interface: string;
  // may contains filter expressions, etc...
  options: _VTI_TYPE_FieldInfoMetaOptions;
  // type of display value
  display: string | null;
  displayOptions: {
    template?: string;
    choices?: FieldDisplayOptionsChoice[];
  } | null;
  isReadonly: boolean;
  isHidden: boolean;
  isRequired: boolean;
  // sort position in collection
  sortPosition: number;
  translations: FieldTranslationInterface[];
  note: string | null;
  // is that field is ID
  readonly isPrimaryKey: boolean;
  isUnique: boolean;
  isNullable: boolean;
  schema: _VTI_TYPE_FieldInfoSchema | null;
  validation: _VTI_TYPE_FieldInfoMetaValidation;
  special: any;
  conditions: Condition[] | null;
}
interface _VTI_TYPE_FieldInfoInterface {
  readonly id: number;
  readonly name: string;
  readonly type: string;
  readonly collectionName: string;
  readonly meta: _VTI_TYPE_FieldInfoMetadataInterface;
}
interface FieldDisplayProp {
  collectionName: string;
  item: _VTI_TYPE_ItemInterface;
  fieldKey: string;
  context?: {
    data?: ItemInterface;
  };
  fieldInfo?: _VTI_TYPE_FieldInfoInterface;
}
  import { toRef, computed } from 'vue'
  import { useFieldsStore } from '~/entities/field'
  import { useRelationO2M } from '~/api/relations/composables/useRelationO2M'
  import { canNavigateToRelationalItem } from '~/api/collections/utils/availability'
  import { routeToItem } from '~/api/items/utils/navigators'
  import { transformNestedDataToItem } from '~/api/relations/utils/transform-nested-data-to-item'
  import { RenderTemplate } from '~/entities/render-template'
  import Button from '~/shared/ui/Button'
  
  import Menu from '~/shared/ui/Menu'
  const props = defineProps<FieldDisplayProp>();
  const fieldsStore = useFieldsStore();
  const collectionName = toRef(props.collectionName);
  const fieldName = toRef(props.fieldInfo!.name);
  const field = computed(() =>
    fieldsStore.getField(props.collectionName, props.fieldKey),
  );
  const { relationInfo, relatedCollection } = useRelationO2M(collectionName, fieldName);
  const displayItems = computed(() => {
    const targetCollection = relatedCollection.value;
    if (!targetCollection) return [];
    const relatedItems: Record<string, any>[] =
      props.item.getFieldDataByExpression(props.fieldInfo!.name)
        ?.currentJunctionItemIds ?? [];
    return relatedItems.map((data) =>
      transformNestedDataToItem(targetCollection.id, data),
    );
  });
  const displayTemplate = computed(() => {
    return (
      field.value?.meta.displayOptions?.template ||
      relatedCollection.value?.meta.displayTemplate ||
      `{{ ${relatedCollection.value?.getPrimaryFieldInfo()!.id} }}`
    );
  });
</script>

entities/field/lib/fields entities/field/lib/store

