<template>
  <div v-if="!!relationInfo && fieldWithRelation">
    <Menu v-if="!!items.length">
      <Menu.Button :as="Button" class="capitalize">
        {{ $t("datatable_elements_count") }}: {{ items.length }}
      </Menu.Button>

      <Menu.Items>
        <Menu.Item
          as="div"
          v-for="item in items"
          class="flex items-center justify-between mb-1 cursor-default"
          @click.stop.prevent="() => {}"
        >
          <RenderTemplate
            :collection-name="relationInfo.relatedCollection"
            :item="item"
            :field-info="fieldWithRelation"
            :default-template="relatedRenderTemplate ?? ''"
            :context="props.context"
          />

          <Button
            v-if="!!relatedCollection && canNavigateToRelationalItem(relatedCollection)"
            :variant="'soft-secondary'"
            class="ml-2"
            @click="() => onRoutingToItem(relatedCollection!.id, item.id)"
          >
            <i class="fa-solid fa-arrow-up-right-from-square"></i>
          </Button>
        </Menu.Item>
      </Menu.Items>
    </Menu>

    <DataNull v-else />
  </div>
</template>

<script setup lang="ts">
type _VTI_TYPE_QueryLogicalFilterAnd = {
  [QueryFilterLogicalEnum._and]: QueryFilter<T>[];
}
type _VTI_TYPE_ValidationFilter = _VTI_TYPE_QueryLogicalFilterAnd<any>
type _VTI_TYPE_FieldFilter = {
  [K in keyof QuerySingleItem]?:
    | QueryFilterOperators<QuerySingleItem[K]>
    | FieldFilter<QuerySingleItem[K]>;
}
interface _VTI_TYPE_FieldInfoMetaValidation {
  message: string | null;
  rules: _VTI_TYPE_ValidationFilter | null;
}
interface _VTI_TYPE_FieldInfoSchema {
  defaultValue: unknown | null;
  maxLength: unknown | null;
  comment: unknown | null;
  numericPrecision: unknown | null;
  numericScale: unknown | null;
  hasAutoIncrement: boolean;
}
interface _VTI_TYPE_FieldInfoMetaOptions {
  choices?: FieldInfoMetaOptionsChoices[];
  folder?: string;
  title?: string;
  filter?: _VTI_TYPE_FieldFilter;
  template?: string; // Interface display template. Have priority on common display property
  masked?: boolean;
  placeholder?: string;
}
interface _VTI_TYPE_FieldInfoMetadataInterface {
  // type of field
  interface: string;
  // may contains filter expressions, etc...
  options: _VTI_TYPE_FieldInfoMetaOptions;
  // type of display value
  display: string | null;
  displayOptions: {
    template?: string;
    choices?: FieldDisplayOptionsChoice[];
  } | null;
  isReadonly: boolean;
  isHidden: boolean;
  isRequired: boolean;
  // sort position in collection
  sortPosition: number;
  translations: FieldTranslationInterface[];
  note: string | null;
  // is that field is ID
  readonly isPrimaryKey: boolean;
  isUnique: boolean;
  isNullable: boolean;
  schema: _VTI_TYPE_FieldInfoSchema | null;
  validation: _VTI_TYPE_FieldInfoMetaValidation;
  special: any;
  conditions: Condition[] | null;
}
interface _VTI_TYPE_FieldInfoInterface {
  readonly id: number;
  readonly name: string;
  readonly type: string;
  readonly collectionName: string;
  readonly meta: _VTI_TYPE_FieldInfoMetadataInterface;
}
interface FieldDisplayProp {
  collectionName: string;
  item: _VTI_TYPE_ItemInterface;
  fieldKey: string;
  context?: {
    data?: ItemInterface;
  };
  fieldInfo?: _VTI_TYPE_FieldInfoInterface;
}
  import { useRouter } from 'vue-router'
  import { computed, shallowRef, watch } from 'vue'
  import isEqual from 'lodash/isEqual'
  import { useRelationM2M } from '~/api/relations/composables/useRelationM2M'
  import { FieldInfoInterface, useFieldsStore } from '~/entities/field'
  import { getItemRoute } from '~/api/items/utils/endpoints'
  import ItemInterface from '~/api/items/entities/ItemInterface'
  import { RenderTemplate } from '~/entities/render-template'
  import DataNull from '~/components/DataNull/data-null.vue'
  import Button from '~/shared/ui/Button'
  import Menu from '~/shared/ui/Menu'
  import { transformNestedDataToItem } from '~/api/relations/utils/transform-nested-data-to-item'
  
  import { getFieldsFromTemplate } from '../utils'
  import { canNavigateToRelationalItem } from '~/api/collections/utils/availability'
  const props = defineProps<FieldDisplayProp>();
  const fieldsStore = useFieldsStore();
  const router = useRouter();
  const fieldWithRelation = computed<FieldInfoInterface | undefined>(() => {
    const templateFieldNames = getFieldsFromTemplate(`{{${props.fieldKey}}}`);
    if (!templateFieldNames.length) return undefined;
    return fieldsStore.getField(props.collectionName, templateFieldNames[0]);
  });
  const { relationInfo, relatedCollection } = useRelationM2M(
    computed(() => String(props.collectionName)),
    fieldWithRelation,
  );
  const relatedRenderTemplate = computed<string | undefined>(() => {
    // todo: взять данные из индексного файла display?
    if (!relationInfo.value || !relatedCollection.value) return undefined;
    const templatePrefixFieldName = relationInfo.value.fieldName;
    const relatedTemplate = relatedCollection.value.meta?.displayTemplate;
    if (relatedTemplate) {
      const templateParts = getFieldsFromTemplate(relatedTemplate);
      const transformedTemplate = templateParts
        .map((fieldName) => `{{${templatePrefixFieldName}.${fieldName}}}`)
        .join(" ");
      return `${transformedTemplate}`;
    }
    const relatedPKFieldName = relatedCollection.value.getPrimaryFieldInfo()!.name;
    const fallbackTemplate = `{{${templatePrefixFieldName}.${relatedPKFieldName}}}`;
    return fallbackTemplate;
  });
  const items = shallowRef<ItemInterface[]>([]);
  watch(
    () => props.context?.data,
    async (newData, prevData) => {
      if (!relationInfo.value) return;
      if (isEqual(newData, prevData)) return;
      const relationalData = newData?.getFieldDataByExpression(props.fieldKey);
      const parentCollectionName = relationInfo.value.junctionCollection!.id;
      items.value = relationalData.currentJunctionItemIds.map(
        (itemData: Record<string, any>) =>
          transformNestedDataToItem(parentCollectionName, itemData),
      );
    },
    {
      immediate: true,
    },
  );
  const onRoutingToItem = async (collectionName: string, itemId: string | number) => {
    const routeTo = getItemRoute(collectionName, itemId);
    const routePath = router.resolve(routeTo).href;
    window.open(routePath, "_blank");
  };
</script>

<style scoped></style>

entities/field/lib/FieldInfoInterface entities/field/lib/fields entities/field/lib/store

