import { RouteLocationRaw } from "vue-router";
import { RouteParameterNames, RoutePathNames } from "~/app/router.options";
import { SYSTEM_COLLECTION_PREFIX } from "~/api/collections/utils/type-detect";

export const getItemsEndpoint = (collectionName: string): string => {
  if (collectionName.startsWith(SYSTEM_COLLECTION_PREFIX))
    return `/${collectionName.substring(SYSTEM_COLLECTION_PREFIX.length)}`;

  return `/items/${collectionName}`;
};

export const getItemRoute = (
  collectionName: string,
  itemId: string | number,
): RouteLocationRaw => {
  return {
    name: RoutePathNames.ITEM_PAGE,
    params: {
      [RouteParameterNames.COLLECTION_NAME]: collectionName,
      [RouteParameterNames.COLLECTION_ITEM_ID]: itemId,
    },
  };
};

