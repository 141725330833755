import { useRelationsStore } from "~/stores/relations";
import { useFieldsStore } from "../model";

export enum FieldLocalType {
  standard = "standard",
  file = "file",
  files = "files",
  m2o = "m2o",
  o2m = "o2m",
  m2m = "m2m",
  m2a = "m2a",
  presentation = "presentation",
  translations = "translations",
  group = "group",
}

export function getFieldLocalType(
  collectionName: string,
  fieldName: string,
): FieldLocalType | undefined {
  const fieldsStore = useFieldsStore();
  const relationsStore = useRelationsStore();

  const fieldInfo = fieldsStore.getField(collectionName, fieldName);
  if (!fieldInfo) return undefined;

  const relations = relationsStore.getRelationsByField(collectionName, fieldName);

  if (!relations.length) {
    if (fieldInfo.type === "alias") {
      if (fieldInfo.meta?.special?.includes("group")) {
        return FieldLocalType.group;
      }
      return FieldLocalType.presentation;
    }

    return FieldLocalType.standard;
  }

  if (relations.length === 1) {
    const relation = relations[0];

    if (
      relation.relatedCollection === "directus_files" &&
      relation.relatedCollection !== collectionName
    )
      return FieldLocalType.file;

    if (relation.collectionName === collectionName && relation.fieldName === fieldName)
      return FieldLocalType.m2o;

    return FieldLocalType.o2m;
  }

  if (relations.length === 2) {
    if ((fieldInfo.meta?.special || []).includes("translations"))
      return FieldLocalType.translations;
    if ((fieldInfo.meta?.special || []).includes("m2a")) return FieldLocalType.m2a;

    const relation = relations.find(
      (relation) =>
        (relation.collectionName === collectionName &&
          relation.fieldName === fieldName) ||
        (relation.relatedCollection === collectionName &&
          relation.meta?.oneField === fieldName),
    );

    if (relation?.collectionName === collectionName && relation.fieldName === fieldName)
      return FieldLocalType.m2o;

    if (
      relations[0].relatedCollection === "directus_files" ||
      relations[1].relatedCollection === "directus_files"
    ) {
      return FieldLocalType.files;
    } else {
      return FieldLocalType.m2m;
    }
  }

  return FieldLocalType.standard;
}

