import { useNuxtApp } from "#app";
import { ref } from "vue";
import { Query, readItem } from "@directus/sdk";
import { FieldInfoInterface } from "~/entities/field";
import { useDataStudioClient } from "~/service/data-studio/composables/useDataStudioClient";
import { logger } from "~/service/logger/logger";
import { toaster } from "~/service/toaster";
import ItemInterface from "../entities/ItemInterface";
import { castItemFromDataStudioApiToEntity } from "../casters/castItemsFromDataStudioApi";

export const useLazyCollectionItem = () => {
  const { $i18n } = useNuxtApp();
  const { client: dataStudioClient } = useDataStudioClient();

  const collectionItem = ref<ItemInterface | null>(null);
  const isLoading = ref<boolean>(false);

  const load = async (
    collectionName: string,
    itemId: string,
    fieldsInfo: FieldInfoInterface[],
    query: Query<any, any> = {},
  ): Promise<void> => {
    try {
      isLoading.value = true;

      const itemResponse = await dataStudioClient.request(
        readItem(collectionName, itemId, query),
      );
      if (!itemResponse) throw new Error("empty response");

      collectionItem.value = castItemFromDataStudioApiToEntity(itemResponse, fieldsInfo);
    } catch (err) {
      logger().error({ err }, "unable to fetch collection item");
      toaster().error($i18n.t("server_error_fetch_items"));
    } finally {
      isLoading.value = false;
    }
  };

  return {
    collectionItem,
    load,
    isLoading,
  };
};

