import "reflect-metadata";
import { useRelationsStore } from "~/stores/relations";
import { useAppStore } from "~/stores/app";
import { useSettingsStore } from "~/stores/settings";
import { useAuthStore } from "~/stores/auth";
import { useUserStore } from "~/entities/user";
import { useFieldsStore } from "~/entities/field";
import { useAppControllersStore } from "~/stores/appControllers";
import { useLocaleStore } from "~/stores/locale";
import { useSocketConnectionsStore } from "~/stores/socket-connections";
import { usePresetsStore } from "~/stores/presets";
import { useCollecitonsStore } from "~/stores/collections";
import { usePermissionsStore } from "~/stores/permissions";
import { usePortalMenuStore } from "~/api/menu/stores/portalMenuStore";
import { useRolesStore } from "~/entities/role";
import { logger } from "../logger/logger";

export const initialize = async () => {
  const appStore = useAppStore();

  if (appStore.initialized) return;

  const authStore = useAuthStore();
  const relationsStore = useRelationsStore();
  const settingsStore = useSettingsStore();
  const fieldsStore = useFieldsStore();
  const appControllersStore = useAppControllersStore();
  const localeStore = useLocaleStore();
  const socketConnectionsStore = useSocketConnectionsStore();
  const portalMenuStore = usePortalMenuStore();
  const presetsStore = usePresetsStore();
  const collectionsStore = useCollecitonsStore();
  const permissionsStore = usePermissionsStore();
  const rolesStore = useRolesStore();

  logger().debug(`Starting app initialization`);

  await rolesStore.initialize();
  await authStore.initialize();
  await collectionsStore.initialize();
  await fieldsStore.initialize();
  await relationsStore.initialize();
  await settingsStore.initialize();
  await appControllersStore.initialize();
  await localeStore.initialize();
  await socketConnectionsStore.initialize();
  await portalMenuStore.initialize();
  await presetsStore.initialize();
  await permissionsStore.initialize();

  appStore.$patch({
    initialized: true,
  });

  logger().debug(`Finished app initialization`);
};

export const deinitialize = async () => {
  const appStore = useAppStore();
  const relationsStore = useRelationsStore();
  const authStore = useAuthStore();
  const userStore = useUserStore();
  const fieldsStore = useFieldsStore();
  const appControllersStore = useAppControllersStore();
  const localeStore = useLocaleStore();
  const socketConnectionsStore = useSocketConnectionsStore();
  const portalMenuStore = usePortalMenuStore();
  const presetsStore = usePresetsStore();
  const collectionsStore = useCollecitonsStore();
  const permissionsStore = usePermissionsStore();
  const rolesStore = useRolesStore();

  logger().debug(`Starting app deinitialization.`);

  await Promise.allSettled([
    fieldsStore.deinitialize(),
    relationsStore.deinitilize(),
    authStore.deinitialize(),
    userStore.deinitialize(),
    appStore.deinitialize(),
    appControllersStore.deinitialize(),
    localeStore.deinitialize(),
    socketConnectionsStore.deinitialize(),
    portalMenuStore.deinitialize(),
    presetsStore.deinitialize(),
    collectionsStore.deinitialize(),
    permissionsStore.deinitialize(),
    rolesStore.deinitialize(),
  ]);

  logger().debug(`Finished app deinitialization`);
};

