<template>
  <div>
    <PChip
      v-for="choice in displayChoices"
      :label="choice.text"
      :style="{
        color: choice.foreground,
        background: choice.background,
      }"
    />
  </div>
</template>

<script setup lang="ts">
type _VTI_TYPE_QueryLogicalFilterAnd = {
  [QueryFilterLogicalEnum._and]: QueryFilter<T>[];
}
type _VTI_TYPE_ValidationFilter = _VTI_TYPE_QueryLogicalFilterAnd<any>
type _VTI_TYPE_FieldFilter = {
  [K in keyof QuerySingleItem]?:
    | QueryFilterOperators<QuerySingleItem[K]>
    | FieldFilter<QuerySingleItem[K]>;
}
interface _VTI_TYPE_FieldInfoMetaValidation {
  message: string | null;
  rules: _VTI_TYPE_ValidationFilter | null;
}
interface _VTI_TYPE_FieldInfoSchema {
  defaultValue: unknown | null;
  maxLength: unknown | null;
  comment: unknown | null;
  numericPrecision: unknown | null;
  numericScale: unknown | null;
  hasAutoIncrement: boolean;
}
interface _VTI_TYPE_FieldInfoMetaOptions {
  choices?: FieldInfoMetaOptionsChoices[];
  folder?: string;
  title?: string;
  filter?: _VTI_TYPE_FieldFilter;
  template?: string; // Interface display template. Have priority on common display property
  masked?: boolean;
  placeholder?: string;
}
interface _VTI_TYPE_FieldInfoMetadataInterface {
  // type of field
  interface: string;
  // may contains filter expressions, etc...
  options: _VTI_TYPE_FieldInfoMetaOptions;
  // type of display value
  display: string | null;
  displayOptions: {
    template?: string;
    choices?: FieldDisplayOptionsChoice[];
  } | null;
  isReadonly: boolean;
  isHidden: boolean;
  isRequired: boolean;
  // sort position in collection
  sortPosition: number;
  translations: FieldTranslationInterface[];
  note: string | null;
  // is that field is ID
  readonly isPrimaryKey: boolean;
  isUnique: boolean;
  isNullable: boolean;
  schema: _VTI_TYPE_FieldInfoSchema | null;
  validation: _VTI_TYPE_FieldInfoMetaValidation;
  special: any;
  conditions: Condition[] | null;
}
interface _VTI_TYPE_FieldInfoInterface {
  readonly id: number;
  readonly name: string;
  readonly type: string;
  readonly collectionName: string;
  readonly meta: _VTI_TYPE_FieldInfoMetadataInterface;
}
interface FieldDisplayProp {
  collectionName: string;
  item: _VTI_TYPE_ItemInterface;
  fieldKey: string;
  context?: {
    data?: ItemInterface;
  };
  fieldInfo?: _VTI_TYPE_FieldInfoInterface;
}
  import { computed } from 'vue'
  import isEmpty from 'lodash/isEmpty'
  import isNil from 'lodash/isNil'
  import PChip from 'primevue/chip'
  import { FieldInfoInterface, FieldDisplayOptionsChoice, useFieldsStore } from '~/entities/field'
  
  const props = defineProps<FieldDisplayProp>();
  const fieldsStore = useFieldsStore();
  const defaultChoiceForeground: string = "var(--gray-900)";
  const defaultChoiceBackground: string = "var(--surface-200)";
  const fieldInfo = computed<FieldInfoInterface | undefined>(() =>
    fieldsStore.getField(props.collectionName, props.fieldKey),
  );
  const fieldData = computed<any | any[]>(() =>
    props.item.getFieldDataByExpression(props.fieldKey),
  );
  const choices = computed<FieldDisplayOptionsChoice[]>(
    () => fieldInfo.value?.meta.displayOptions?.choices ?? [],
  );
  const displayChoices = computed<FieldDisplayOptionsChoice[]>(() => {
    let values: string[];
    if (isEmpty(fieldData.value) || isNil(fieldData.value)) {
      values = [];
    } else if (fieldInfo.value?.type === "string" && !Array.isArray(fieldData.value)) {
      values = [fieldData.value as string];
    } else {
      values = fieldData.value as string[];
    }
    return values.map((value) => {
      const choice = choices.value.find((choice) => choice.value === value);
      const defaultLabel: string =
        typeof value === "object" ? JSON.stringify(value) : value;
      if (choice === undefined) {
        return {
          value,
          text: defaultLabel,
          foreground: defaultChoiceForeground,
          background: defaultChoiceBackground,
        };
      }
      return {
        value,
        text: choice.text || defaultLabel,
        foreground: choice.foreground || defaultChoiceForeground,
        background: choice.background || defaultChoiceBackground,
      };
    });
  });
</script>

<style scoped></style>
entities/field/lib/FieldInfoInterface entities/field/lib/fields entities/field/lib/store
