import { Router } from "vue-router";
import { getItemRoute } from "~/api/items/utils/endpoints";
import { ItemID } from "../types/types";

/**
 * Открывает  новую вкладку с выбранным элементом.
 *
 * @param {string} collectionName наименование коллекции.
 * @param {ItemID} itemId идентификатор элемента коллеции.
 * @param {Router} router инстанс роутера.
 */
export const routeToItem = (
  collectionName: string,
  itemId: ItemID,
  router: Router,
): void => {
  const routeTo = getItemRoute(collectionName, itemId);
  const routePath = router.resolve(routeTo).href;

  window.open(routePath, "_blank");
};

