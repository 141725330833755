<template>
  <div>
    {{ formattedData }}
  </div>
</template>

<script setup lang="ts">
type _VTI_TYPE_QueryLogicalFilterAnd = {
  [QueryFilterLogicalEnum._and]: QueryFilter<T>[];
}
type _VTI_TYPE_ValidationFilter = _VTI_TYPE_QueryLogicalFilterAnd<any>
type _VTI_TYPE_FieldFilter = {
  [K in keyof QuerySingleItem]?:
    | QueryFilterOperators<QuerySingleItem[K]>
    | FieldFilter<QuerySingleItem[K]>;
}
interface _VTI_TYPE_FieldInfoMetaValidation {
  message: string | null;
  rules: _VTI_TYPE_ValidationFilter | null;
}
interface _VTI_TYPE_FieldInfoSchema {
  defaultValue: unknown | null;
  maxLength: unknown | null;
  comment: unknown | null;
  numericPrecision: unknown | null;
  numericScale: unknown | null;
  hasAutoIncrement: boolean;
}
interface _VTI_TYPE_FieldInfoMetaOptions {
  choices?: FieldInfoMetaOptionsChoices[];
  folder?: string;
  title?: string;
  filter?: _VTI_TYPE_FieldFilter;
  template?: string; // Interface display template. Have priority on common display property
  masked?: boolean;
  placeholder?: string;
}
interface _VTI_TYPE_FieldInfoMetadataInterface {
  // type of field
  interface: string;
  // may contains filter expressions, etc...
  options: _VTI_TYPE_FieldInfoMetaOptions;
  // type of display value
  display: string | null;
  displayOptions: {
    template?: string;
    choices?: FieldDisplayOptionsChoice[];
  } | null;
  isReadonly: boolean;
  isHidden: boolean;
  isRequired: boolean;
  // sort position in collection
  sortPosition: number;
  translations: FieldTranslationInterface[];
  note: string | null;
  // is that field is ID
  readonly isPrimaryKey: boolean;
  isUnique: boolean;
  isNullable: boolean;
  schema: _VTI_TYPE_FieldInfoSchema | null;
  validation: _VTI_TYPE_FieldInfoMetaValidation;
  special: any;
  conditions: Condition[] | null;
}
interface _VTI_TYPE_FieldInfoInterface {
  readonly id: number;
  readonly name: string;
  readonly type: string;
  readonly collectionName: string;
  readonly meta: _VTI_TYPE_FieldInfoMetadataInterface;
}
interface FieldDisplayProp {
  collectionName: string;
  item: _VTI_TYPE_ItemInterface;
  fieldKey: string;
  context?: {
    data?: ItemInterface;
  };
  fieldInfo?: _VTI_TYPE_FieldInfoInterface;
}
  import { computed } from '@vue/reactivity'
  import { ref, watchEffect, onBeforeUnmount, watch, unref } from '@vue/runtime-core'
  import { Subscription, filter, interval } from 'rxjs'
  import dayjs, { Dayjs } from 'dayjs'
  import { transformUnicodeToDayjsFormat } from '~/service/dayjs/transforms/fromUnicode'
  
  import { useFieldsStore } from '~/entities/field'
  const props = defineProps<FieldDisplayProp>();
  const fieldsStore = useFieldsStore();
  const fieldInfo = computed(() =>
    fieldsStore.getField(props.collectionName, props.fieldKey),
  );
  const fieldData = computed<Dayjs | false>(() => {
    const data = props.item.getFieldDataByExpression(props.fieldKey);
    if (data instanceof Number && !data) return false;
    if (data instanceof String && !data.length) return false;
    return dayjs(data);
  });
  const isRelative = computed<boolean>(
    () => fieldInfo.value?.meta.displayOptions?.relative ?? false,
  );
  const fieldDisplayFormat = computed<string | false>(
    () => fieldInfo.value?.meta.displayOptions?.format ?? false,
  );
  const format = computed<string>(() => fieldDisplayFormat.value || "DD/MM/YYYY");
  const transformDateToRelative = (date: Dayjs): string => {
    return date.fromNow();
  };
  const transformDateToFormat = (date: Dayjs, format: string): string => {
    const dayjsFormat = transformUnicodeToDayjsFormat(format);
    return date.format(dayjsFormat);
  };
  const formattedData = ref<string | false>(false);
  const updateFormattedData = (): void => {
    const currentData = unref(fieldData);
    if (currentData === false) {
      formattedData.value = false;
      return;
    }
    const newData = isRelative.value
      ? transformDateToRelative(currentData)
      : transformDateToFormat(currentData, format.value);
    formattedData.value = newData;
  };
  watch(
    () => [fieldData.value, isRelative.value],
    () => {
      updateFormattedData();
    },
    {
      immediate: true,
    },
  );
  const relativeUpdate$ = interval(60000).pipe(filter(() => isRelative.value));
  let relativeUpdateSub: Subscription | null = null;
  watchEffect(() => {
    if (isRelative.value && !relativeUpdateSub) {
      relativeUpdateSub = relativeUpdate$.subscribe(() => {
        updateFormattedData();
      });
    }
    if (!isRelative.value && !!relativeUpdateSub) {
      relativeUpdateSub.unsubscribe();
    }
  });
  onBeforeUnmount(() => {
    relativeUpdateSub?.unsubscribe();
  });
</script>

entities/field/lib/fields entities/field/lib/store

