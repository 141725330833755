<template>
  <div>
    <DropdownSingleRelationRenderer
      v-if="relatedCollection !== undefined && relatedCollection !== null"
      :collection="relatedCollection"
      :field="props.field"
      @update:modelValue="onUpdateModelValue"
    />
  </div>
</template>

<script setup lang="ts">
type _VTI_TYPE_QueryLogicalFilterAnd = {
  [QueryFilterLogicalEnum._and]: QueryFilter<T>[];
}
type _VTI_TYPE_ValidationFilter = _VTI_TYPE_QueryLogicalFilterAnd<any>
type _VTI_TYPE_FieldFilter = {
  [K in keyof QuerySingleItem]?:
    | QueryFilterOperators<QuerySingleItem[K]>
    | FieldFilter<QuerySingleItem[K]>;
}
interface _VTI_TYPE_FieldInfoMetaValidation {
  message: string | null;
  rules: _VTI_TYPE_ValidationFilter | null;
}
interface _VTI_TYPE_FieldInfoSchema {
  defaultValue: unknown | null;
  maxLength: unknown | null;
  comment: unknown | null;
  numericPrecision: unknown | null;
  numericScale: unknown | null;
  hasAutoIncrement: boolean;
}
interface _VTI_TYPE_FieldInfoMetaOptions {
  choices?: FieldInfoMetaOptionsChoices[];
  folder?: string;
  title?: string;
  filter?: _VTI_TYPE_FieldFilter;
  template?: string; // Interface display template. Have priority on common display property
  masked?: boolean;
  placeholder?: string;
}
interface _VTI_TYPE_FieldInfoMetadataInterface {
  // type of field
  interface: string;
  // may contains filter expressions, etc...
  options: _VTI_TYPE_FieldInfoMetaOptions;
  // type of display value
  display: string | null;
  displayOptions: {
    template?: string;
    choices?: FieldDisplayOptionsChoice[];
  } | null;
  isReadonly: boolean;
  isHidden: boolean;
  isRequired: boolean;
  // sort position in collection
  sortPosition: number;
  translations: FieldTranslationInterface[];
  note: string | null;
  // is that field is ID
  readonly isPrimaryKey: boolean;
  isUnique: boolean;
  isNullable: boolean;
  schema: _VTI_TYPE_FieldInfoSchema | null;
  validation: _VTI_TYPE_FieldInfoMetaValidation;
  special: any;
  conditions: Condition[] | null;
}
interface _VTI_TYPE_FieldInfoInterface {
  readonly id: number;
  readonly name: string;
  readonly type: string;
  readonly collectionName: string;
  readonly meta: _VTI_TYPE_FieldInfoMetadataInterface;
}
interface _VTI_TYPE_FieldInterface {
  readonly info: _VTI_TYPE_FieldInfoInterface;
  readonly data: any;
  readonly label: string;
  readonly isDirty: boolean;
  setData(data: any): this;
  setDirty(): this;
  setClean(): this;
}
interface FieldInterfaceEmits {
  (
    e: FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA,
    data: FieldDataUpdateEmitPayload,
  ): void;
}
interface FieldFormInterfaceProps {
  collection: _VTI_TYPE_CollectionInterface;
  item: _VTI_TYPE_ItemInterface;
  field: _VTI_TYPE_FieldInterface;
}
  import { computed } from '@vue/reactivity'
  import DropdownSingleRelationRenderer from '~/components/DropdownSingleRelationRenderer/DropdownSingleRelationRenderer.vue'
  import { useRelationM2O } from '~/api/relations/composables/useRelationsM2O'
  import { FieldInterfaceEmitId, defineEmitUpdateItemFieldDataPayload } from '../../emits'
  
  const props = defineProps<FieldFormInterfaceProps>();
  const emit = defineEmits<FieldInterfaceEmits>();
  const { relationInfo, relatedCollection } = useRelationM2O(
    computed(() => props.collection.id),
    computed(() => props.field.info),
  );
  const onUpdateModelValue = (event: string | number | undefined | null) => {
    emit(
      FieldInterfaceEmitId.UPDATE_ITEM_FIELD_DATA,
      defineEmitUpdateItemFieldDataPayload({
        collectionName: props.collection.id,
        fieldName: props.field.info.name,
        updatedData: event,
      }),
    );
  };
</script>

<style scoped></style>
