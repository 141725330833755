import { ref, Ref, computed, watchEffect } from "vue";
import { useCollecitonsStore } from "~/stores/collections";
import { useRelationsStore } from "~/stores/relations";
import CollectionInterface from "~/api/collections/entities/CollectionInterface";
import RelationMany from "../entity/RelationMany";
import { RelationTypes } from "../entity/RelationInterface";
import { useFieldsStore } from "~/entities/field";

export function useRelationO2M(collectionName: Ref<string>, fieldKey: Ref<string>) {
  const relationsStore = useRelationsStore();
  const collectionsStore = useCollecitonsStore();
  const fieldsStore = useFieldsStore();

  const relationInfo = computed(() => {
    const relations = relationsStore.getRelationsByField(
      collectionName.value,
      fieldKey.value,
    );
    if (relations.length !== 1) return undefined;

    const relation = relations[0];

    return new RelationMany(
      RelationTypes.ONE_TO_MANY,
      relation.collectionName,
      relation.fieldName,
      relation.relatedCollection,
      relation.relatedFieldName,
      relation.meta,
      undefined,
      undefined,
      undefined,
      fieldsStore.getField(relation.collectionName, relation.meta!.manyField),
    );
  });

  const relatedCollection = ref<CollectionInterface | undefined>(undefined);

  watchEffect(() => {
    if (relationInfo.value === undefined || !relationInfo.value.collectionName) return;

    relatedCollection.value = collectionsStore.getCollection(
      relationInfo.value.collectionName,
    );
  });

  return {
    relationInfo,
    relatedCollection,
  };
}

