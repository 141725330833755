import gql from "graphql-tag";

export const PROJECT_SETTINGS_QUERY = gql`
  query ProjectSettings {
    settings {
      project_name
      auth_login_attempts
      default_language
      project_url
    }
  }
`;

