import { SlideData } from "photoswipe";
import { readFileAsDataURL } from "../files/readers";

export const readSlideDataFromFile = async (file: File): Promise<SlideData> => {
  const dataURL = await readFileAsDataURL(file);

  return {
    src: dataURL,
    alt: file.name,
    meta: {
      uuid: file.upload?.uuid,
    },
  };
};

