import { computed, ComputedRef, ref, watch } from "vue";
import * as yup from "yup";
import { FieldInterface } from "../lib/types";
import { transformRulesToSchema } from "../lib/parsers";
import { createTranslatedErrors } from "../lib/createTranslatedErrors";

export const useFieldValidation = (field: ComputedRef<FieldInterface>) => {
  const message = computed<string>(() => field.value.info.meta.validation.message ?? "");

  const validationSchema = ref<yup.Schema | undefined>(undefined);

  const isValid = ref<boolean>(false);
  const errors = ref<string[]>([]);

  watch(
    () => field.value.info,
    (fieldInfo) => {
      validationSchema.value = transformRulesToSchema(fieldInfo);
      validate(field.value.data);
    },
    {
      deep: true,
      immediate: true,
    },
  );

  watch(
    () => field.value.data,
    (newData) => {
      validate(newData);
    },
    {
      immediate: true,
      deep: true,
    },
  );

  function validate(data: any): void {
    try {
      if (!validationSchema.value) return;
      validationSchema.value.validateSync(data);

      isValid.value = true;
      errors.value = [];
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        isValid.value = false;
        errors.value = createTranslatedErrors(error.errors);
        return;
      }

      throw error;
    }
  }

  return {
    message,
    validationSchema,
    errors: computed(() => errors.value),
    isValid: computed(() => isValid.value),
  };
};

