import type { FieldFunction, Filter } from "@directus/types";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import isPlainObject from "lodash/isPlainObject";
import set from "lodash/set";
import { magicFunctions } from "~/entities/field";
import { REGEX_BETWEEN_PARENS } from "./constants";

export function injectMagicFunctionResults(
  payload: Record<string, any>,
  filter: Filter,
): Record<string, any> {
  const newInput = cloneDeep(payload);

  processFilterLevel(filter);

  return newInput;

  function processFilterLevel(filter: Filter, parentPath?: string) {
    for (const [key, value] of Object.entries(filter)) {
      const path = parentPath ? parentPath + "." + key : key;

      if (key.startsWith("_") === false && isPlainObject(value)) {
        processFilterLevel(value, path);
      }

      if (key.includes("(") && key.includes(")")) {
        const functionName = key.split("(")[0] as FieldFunction;
        const fieldKey = key.match(REGEX_BETWEEN_PARENS)?.[1];

        if (!fieldKey || !functionName) continue;

        const currentValuePath = parentPath ? parentPath + "." + fieldKey : fieldKey;
        const currentValue = get(newInput, currentValuePath);

        set(newInput, path, magicFunctions[functionName](currentValue));
      }
    }
  }
}

