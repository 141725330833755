import { FieldDisplayConfig, FieldDisplayOptions } from "./types";

/**
 * Using for define field display configuration
 *
 * @param displayConfig
 * @returns
 */
export const defineFieldDisplay = (
  displayConfig: FieldDisplayConfig,
): FieldDisplayConfig => {
  return displayConfig;
};

/**
 * Using as Props for display components
 *
 * @param displayOptions
 * @returns
 */
export const defineFieldDisplayOptions = (
  displayOptions: FieldDisplayOptions,
): FieldDisplayOptions => {
  return displayOptions;
};
