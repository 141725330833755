import { defineAsyncComponent } from "vue";

export default Object.assign(
  {},
  defineAsyncComponent(() => import("./column-list-o2m.vue")),
  {
    Filter: defineAsyncComponent(() => import("./filter.vue")),
  },
);

