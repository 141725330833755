import { ComputedRef, computed, watchEffect, watch, ref } from "@vue/runtime-core";
import isNil from "lodash/isNil";
import { useRelationsStore } from "~/stores/relations";
import { useCollecitonsStore } from "~/stores/collections";
import CollectionInterface from "~/api/collections/entities/CollectionInterface";
import { useLazyCollectionData } from "~/api/collections/composables/useLazyCollectionData";
import { FieldInfoInterface } from "~/entities/field";
import RelationMany from "../entity/RelationMany";
import RelationInterface, {
  RelationManyInterface,
  RelationTypes,
} from "../entity/RelationInterface";

/*
One1              Many|Many: junctionCollection         One2: relatedCollection
┌─────────┐       ┌─────────────────────────────┐       ┌─────────────────────┐
│id       ├───┐   │id: junctionPKField          │   ┌───┤id: relatedPKField   │
│many     │   └──►│one1_id: reverseJunctionField│   │   │                     │
└─────────┘       │one2_id: junctionField       │◄──┘   └─────────────────────┘
                  │sort: sortField              │
                  └─────────────────────────────┘
 */
export const useRelationM2M = (
  collectionName: ComputedRef<string>,
  fieldInfo: ComputedRef<FieldInfoInterface | undefined>,
) => {
  const relationsStore = useRelationsStore();
  const collectionsStore = useCollecitonsStore();

  const { collection: relatedCollection, load: loadCollection } = useLazyCollectionData();

  const relations = computed<RelationInterface[]>(() =>
    !!fieldInfo.value
      ? relationsStore.getRelationsByField(collectionName.value, fieldInfo.value.name)
      : [],
  );

  const junction = computed<RelationInterface | undefined>(() =>
    relations.value.find(
      (relation) =>
        relation.relatedCollection === collectionName.value &&
        relation.meta?.oneField === fieldInfo.value?.name &&
        relation.meta?.junctionField,
    ),
  );

  const junctionCollection = ref<CollectionInterface | undefined>(undefined);

  watch(
    () => junction.value,
    (newJunction) => {
      if (!newJunction) {
        junctionCollection.value = undefined;
        return;
      }

      junctionCollection.value = collectionsStore.getCollection(
        newJunction.collectionName,
      );
    },
    {
      immediate: true,
    },
  );

  const relationInfo = computed<RelationManyInterface | undefined>(() => {
    const junctionData = junction.value;

    if (!junctionData) return undefined;

    const relation = relations.value.find(
      (relation) =>
        relation.collectionName === junctionData.collectionName &&
        relation.fieldName === junctionData.meta?.junctionField,
    );

    if (!relation) return undefined;

    const junctionCollectionData = junctionCollection.value;

    if (junctionCollectionData === undefined) {
      return undefined;
    }

    const junctionField = junctionCollectionData.fieldsInfo.find(
      (fieldInfo) => fieldInfo.name === junctionData.meta?.junctionField,
    );

    const reverseJunctionField = junctionCollectionData.fieldsInfo.find(
      (fieldInfo) => fieldInfo.name === relation.meta?.junctionField,
    );

    return new RelationMany(
      RelationTypes.MANY_TO_MANY,
      relation.collectionName,
      relation.fieldName,
      relation.relatedCollection,
      relation.relatedFieldName,
      relation.meta,
      junctionData,
      junctionCollectionData,
      junctionField,
      reverseJunctionField,
    );
  });

  watchEffect(async () => {
    if (relationInfo.value === undefined) return;

    if (isNil(relationInfo.value.relatedCollection)) return;

    await loadCollection(relationInfo.value.relatedCollection);
  });

  return { relationInfo, relatedCollection };
};

