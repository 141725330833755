import "reflect-metadata";
import { Logger } from "pino";
import { inject, injectable } from "inversify";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";
import {
  IAuthByPhoneDto,
  IAuthByPhoneResponseDto,
  IAuthBySmsCodeDto,
  IAuthBySmsCodeResponseDto,
  AuthByPhoneServiceInterface,
} from "../lib";

import { useNuxtApp } from "#app";
import { toaster } from "~/service/toaster";
import { StorageAuthDriver } from "~/shared/api/directus/auth";
import { AuthenticationData } from "@directus/sdk";
import { useAuthStore } from "~/stores/auth";
import { AuthGatewayInterface } from "../api/types";

@injectable()
export default class AuthByPhoneService implements AuthByPhoneServiceInterface {
  constructor(
    @inject(INJECT_SYMBOLS.AuthGatewayInterface)
    private readonly _authGateway: AuthGatewayInterface,
    @inject(INJECT_SYMBOLS.StorageAuthDriver)
    private readonly _storageAuthDriver: StorageAuthDriver,
    @inject(INJECT_SYMBOLS.Logger)
    private readonly _logger: Logger,
  ) {}

  async authByPhone(data: IAuthByPhoneDto): Promise<IAuthByPhoneResponseDto> {
    const {
      $i18n: { t },
    } = useNuxtApp();

    try {
      const response = await this._authGateway.authByPhone(data);

      if (!response?.success) {
        return response;
      }

      toaster().success(t("code_successfully_sended"));

      return response;
    } catch (err) {
      this._logger.error({ err }, `unable to auth by  phone`);
      throw err;
    }
  }

  async authBySms(data: IAuthBySmsCodeDto): Promise<IAuthBySmsCodeResponseDto> {
    const {
      $i18n: { t },
    } = useNuxtApp();

    try {
      const response = await this._authGateway.authBySms(data);

      if (!response?.success) {
        return response;
      }

      toaster().success(t("authorisation_success"));

      this._storageAuthDriver.storage.set(response as AuthenticationData);

      response.access_token &&
        useAuthStore().$patch({
          accessToken: response.access_token!,
        });

      return response;
    } catch (err) {
      this._logger.error({ err }, `unable to auth by sms_code`);
      throw err;
    }
  }
}

