import MenuContainer from "./ui/MenuContainer.vue";
import Button from "./ui/Button.vue";
import Items from "./ui/Items.vue";
import Item from "./ui/Item.vue";
import Divider from "./ui/Divider.vue";
import Header from "./ui/Header.vue";
import Footer from "./ui/Footer.vue";

const Menu = Object.assign({}, MenuContainer, {
  Button: Button,
  Items: Items,
  Item: Item,
  Divider: Divider,
  Header: Header,
  Footer: Footer,
});

export default Menu;

