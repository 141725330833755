import { usePermissionsStore } from "~/stores/permissions";
import CollectionInterface from "../entities/CollectionInterface";
import { PermissionAction } from "~/api/permissions/entity/PermissionInterface";

export const canNavigateToRelationalItem = (collection: CollectionInterface): boolean => {
  const permissionsStore = usePermissionsStore();
  return (
    permissionsStore.hasCollectionPermission(collection.id, PermissionAction.READ) &&
    !collection.meta.isHidden &&
    !collection.id.startsWith("directus_")
  );
};

