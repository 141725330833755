import "reflect-metadata";
import { defineNuxtRouteMiddleware, navigateTo } from "#app";
import { RouteMetaFields, RoutePathNames } from "~/app/router.options";
import { useDataStudioClient } from "~/service/data-studio/composables/useDataStudioClient";
import { useUserStore, UserGateway } from "~/entities/user";
import { logger } from "~/service/logger/logger";
import {
  isInvalidCredentialsErrorObject,
  StorageAuthDriver,
} from "~/shared/api/directus";
import { InvalidRefreshTokenException } from "~/shared/api/directus";
import { iocContainer } from "~/inversify.config";
import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { client: dataStudioClient } = useDataStudioClient();
  const userStore = useUserStore();
  const storageAuthDriver = iocContainer.get<StorageAuthDriver>(
    INJECT_SYMBOLS.StorageAuthDriver,
  );

  try {
    const currentAuthData = storageAuthDriver.storage.get();
    if (currentAuthData === null) throw new InvalidRefreshTokenException();

    await dataStudioClient.refresh();

    if (!userStore.me) {
      const usersApi = new UserGateway(dataStudioClient, logger());

      userStore.$patch({
        me: await usersApi.getMe(),
      });
    }

    // prevent navigation to login page
    if (to.name === RoutePathNames.LOGIN) {
      return await navigateTo({
        name: RoutePathNames.HOME,
      });
    }
  } catch (err) {
    if (isInvalidCredentialsErrorObject(err)) {
      storageAuthDriver.reset();
    }

    if (to.meta[RouteMetaFields.IS_AUTH_REQUIRED] === false) return;
    if (to.name !== RoutePathNames.LOGIN) {
      return await navigateTo({
        name: RoutePathNames.LOGIN,
      });
    }
  }
});
